<template>
<div class="d-flex align-center justify-center flex-column thanks">
  <img src="achivement.png" alt="Successfully registered" />
  <h1 class="rtitle">Thanks for registering!</h1>
  <router-link to="/">Go to account</router-link>
</div>
</template>


<script>

export default {
  name: 'ThanksForRegistering',
};

</script>

<style lang="scss" scoped>
.thanks {
  background-color: #ffffff;
  box-shadow: 0px 10px 10px -5px #777;
  height: 90vh;
  max-height: 600px;
}

.rtitle {
  color: #474747;
}
</style>
