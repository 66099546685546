<template>
  <a href="/logout" @click.prevent="click">
    <img :src="img" :alt="name" :title="name">
  </a>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    name: {
      type: String,
      required: false,
    },
    img: {
      type: String,
      required: true,
    }
  },
  methods: {
    click() {
      this.$emit('click');
    }
  }
};
</script>

<style scoped>
a {
  display: inline-block;
  width: 36px;
  height: 36px;
  overflow: hidden;
  border: 1px solid #d8dbe0;
  border-radius: 50%;
}

img {
  height: 100%;
  width: 100%;
}
</style>
