<template>
<v-dialog
  :value="dialog"
  @input="(v) => { if (v) onClose();  }"
  fullscreen
  hide-overlay
  transition="dialog-bottom-transition"
>
  <v-card>
    <v-toolbar dark class="white--text indigo darken-1">
      <v-btn icon dark @click="onClose">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Settings</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="onSave">Save</v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <div><pre>
      {{ this.valPatterns }}
    </pre></div>

    <v-list
      three-line
      subheader
    >
      <v-subheader>General</v-subheader>
      <v-list-item>
        <v-list-item-action>
          <AppEvalBtn
            :active="false"
            @click="onPlay(settings.newapp)"
          >mdi-emoticon-happy-outline</AppEvalBtn>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>New application</v-list-item-title>
          <v-list-item-subtitle>There is a new app to evaluate</v-list-item-subtitle>
          <v-select
            v-model="settings.newapp"
            :items="valPatterns"
          />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <AppEvalBtn
            :active="false"
            @click="onPlay(settings.noapps)"
          >mdi-emoticon-happy-outline</AppEvalBtn>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>No more applications</v-list-item-title>
          <v-list-item-subtitle>There are no more applications to evaluate</v-list-item-subtitle>
          <v-select
            v-model="settings.noapps"
            :items="valPatterns"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>


    <v-list
      three-line
      subheader
    >
      <v-subheader>Ratting buttons</v-subheader>
      <v-list-item>
        <v-list-item-action>
          <AppEvalBtn
            :active="false"
            @click="onPlay(settings.markhappy)"
          >mdi-emoticon-happy-outline</AppEvalBtn>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Happy</v-list-item-title>
          <v-list-item-subtitle>I enjoy using this application!</v-list-item-subtitle>
          <v-select
            v-model="settings.markhappy"
            :items="valPatterns"
          />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <AppEvalBtn
            :active="false"
            @click="onPlay(settings.markneutral)"
          >mdi-emoticon-neutral-outline</AppEvalBtn>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Neutral</v-list-item-title>
          <v-list-item-subtitle>I neigher like nor dislike this application.</v-list-item-subtitle>
          <v-select
            v-model="settings.markneutral"
            :items="valPatterns"
          />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <AppEvalBtn
            :active="false"
            @click="onPlay(settings.markunhappy)"
          >mdi-emoticon-sad-outline</AppEvalBtn>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Unhappy</v-list-item-title>
          <v-list-item-subtitle>I had a bad experience using this application.</v-list-item-subtitle>
          <v-select
            v-model="settings.markunhappy"
            :items="valPatterns"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</v-dialog>
</template>

<script>
import { clone } from '../../util/util';
import AppEvalBtn from './AppEvalBtn.vue';

export default {
  name: 'AppsEvalSettings',

  components: {
    AppEvalBtn,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    patterns: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      settings: clone(this.value),
    };
  },

  computed: {
    valPatterns() {
      return this.patterns.map(item => {
        return {
          text: item.title,
          value: item.hash,
        };
      });
    },
  },

  methods: {
    onClose() {
      this.$emit('close');
    },

    onSave() {
      this.$emit('input', clone(this.settings));
      this.$emit('close');
    },

    onPlay(pattern) {
      this.$emit('play', pattern);
    },
  },
};
</script>
