<template>
<div style="max-width:920px">
  <canvas ref="canvas" style="max-width: 100%;"></canvas>
</div>
</template>

<script>

import { Chart } from 'chart.js';
import { signalFuncFactory } from '../../../util/signal-data/factory';

import { emptyDataset } from '../../../util/func';
import { clone } from '../../../util/util';

export default {
  name: 'SignalDisplay',

  props: {
    components: {
      type: Array,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
  },

  watch: {
    components: {
      deep: true,
      handler() {
        this.redraw();
      },
    },
    duration: {
      deep: false,
      handler() {
        this.redraw();
      }
    }
  },

  methods: {
    displaySignals(all) {
      return this.components.map((component) => {
        const sp = signalFuncFactory(component);
        return {
          func: sp._fn.bind(sp),
          data: all || component.visible ? sp.points : [],
          color: component.color,
          time: component.time,
          duration: component.duration,
        };
      });
    },

    chartData() {
      const chartData = {};
      chartData.datasets = this.displaySignals(false).map(({ data, color }) => {
        const e = emptyDataset();
        e.data = clone(data);
        e.backgroundColor = color;
        return e;
      });
      return chartData;
    },

    redraw() {
      const self = this;
      const { datasets } = this.chartData();
      this.chartInstance.data.datasets = datasets;
      this.chartInstance.options.scales.xAxes.forEach(scale => {
        scale.ticks.max = self.duration;
      });
      this.chartInstance.update();
    }
  },

  mounted() {
    const self = this;
    const datasets = self.chartData();
    this.chartInstance = new Chart(self.$refs.canvas, {
      type: 'line',
      data: datasets,
      options: {
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        elements: {
          point:{
            radius: 0
          },
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
              stepSize: 10,
              // text: 'Amplitude [%]'
            }
          }],
          xAxes: [{
            type: 'linear',
            ticks: {
              min: 0,
              max: self.duration,
              stepSize: 0.05,
              // text: 'Time [seconds]'
            }
          }]
        },
      }
    });
  },

  beforeUnmount() {
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }
  },
};
</script>

<style>
canvas {
  height: 300px;
  width: 500px;
}
</style>
