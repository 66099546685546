<template>
  <FuncParamsWrapper v-if="pattern == 'exponential'" :disabled="disabled">
    <template v-slot:func>
      A(t) = A<sub>max</sub>e<sup>-τt + D</sup> + c
    </template>
    <template v-slot:params>
      <NumberInput
        v-model="params.amplitude"
        :min="paramsConfig.amplitude.min"
        :max="paramsConfig.amplitude.max"
        :step="paramsConfig.amplitude.step"
        :defaultValue="paramsConfig.amplitude.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      ><div>A<sub>max</sub>:</div></NumberInput>
      <NumberInput
        v-model="params.tau"
        :min="paramsConfig.tau.min"
        :max="paramsConfig.tau.max"
        :step="paramsConfig.tau.step"
        :defaultValue="paramsConfig.tau.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      >τ:</NumberInput>
      <NumberInput
        v-model="params.delta"
        :min="paramsConfig.delta.min"
        :max="paramsConfig.delta.max"
        :step="paramsConfig.delta.step"
        :defaultValue="paramsConfig.delta.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      >D:</NumberInput>
      <NumberInput
        v-model="params.c"
        :min="paramsConfig.c.min"
        :max="paramsConfig.c.max"
        :step="paramsConfig.c.step"
        :defaultValue="paramsConfig.c.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      >c:</NumberInput>
    </template>
  </FuncParamsWrapper>
  <FuncParamsWrapper v-else-if="pattern == 'linear'" :disabled="disabled">
    <template v-slot:func>
      A(t) = at + b
    </template>
    <template v-slot:params>
      <NumberInput
        v-model="params.a"
        :min="paramsConfig.a.min"
        :max="paramsConfig.a.max"
        :step="paramsConfig.a.step"
        :defaultValue="paramsConfig.a.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      >a:</NumberInput>
      <NumberInput
        v-model="params.b"
        :min="paramsConfig.b.min"
        :max="paramsConfig.b.max"
        :step="paramsConfig.b.step"
        :defaultValue="paramsConfig.b.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      >b:</NumberInput>
    </template>
  </FuncParamsWrapper>
  <FuncParamsWrapper v-else-if="pattern == 'parabolic'" :disabled="disabled">
    <template v-slot:func>
      A(t) = at<sup>2</sup> + bt + c
    </template>
    <template v-slot:params>
      <NumberInput
        v-model="params.a"
        :min="paramsConfig.a.min"
        :max="paramsConfig.a.max"
        :step="paramsConfig.a.step"
        :defaultValue="paramsConfig.a.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      >a:</NumberInput>
      <NumberInput
        v-model="params.b"
        :min="paramsConfig.b.min"
        :max="paramsConfig.b.max"
        :step="paramsConfig.b.step"
        :defaultValue="paramsConfig.b.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      >b:</NumberInput>
      <NumberInput
        v-model="params.c"
        :min="paramsConfig.c.min"
        :max="paramsConfig.c.max"
        :step="paramsConfig.c.step"
        :defaultValue="paramsConfig.c.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      >c:</NumberInput>
    </template>
  </FuncParamsWrapper>
  <FuncParamsWrapper v-else-if="pattern == 'armonicWave'" :disabled="disabled">
    <template v-slot:func>
      A(t) = A<sub>max</sub>e<sup>-τt</sup> sin(2πωt) + c
    </template>
    <template v-slot:params>
      <NumberInput
        v-model="params.amplitude"
        :min="paramsConfig.amplitude.min"
        :max="paramsConfig.amplitude.max"
        :step="paramsConfig.amplitude.step"
        :defaultValue="paramsConfig.amplitude.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      ><div>A<sub>max</sub>:</div></NumberInput>
      <NumberInput
        v-model="params.tau"
        :min="paramsConfig.tau.min"
        :max="paramsConfig.tau.max"
        :step="paramsConfig.tau.step"
        :defaultValue="paramsConfig.tau.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      >τ:</NumberInput>
      <NumberInput
        v-model="params.omega"
        :min="paramsConfig.omega.min"
        :max="paramsConfig.omega.max"
        :step="paramsConfig.omega.step"
        :defaultValue="paramsConfig.omega.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      >ω:</NumberInput>
      <NumberInput
        v-model="params.c"
        :min="paramsConfig.c.min"
        :max="paramsConfig.c.max"
        :step="paramsConfig.c.step"
        :defaultValue="paramsConfig.c.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      >c:</NumberInput>
    </template>
  </FuncParamsWrapper>
  <FuncParamsWrapper v-else-if="pattern == 'ln'" :disabled="disabled">
    <template v-slot:func>
      A(t) = A<sub>max</sub>ln(at) + c
    </template>

    <template v-slot:params>
      <NumberInput
        v-model="params.amplitude"
        :min="paramsConfig.amplitude.min"
        :max="paramsConfig.amplitude.max"
        :step="paramsConfig.amplitude.step"
        :defaultValue="paramsConfig.amplitude.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      ><div>A<sub>max</sub>:</div></NumberInput>
      <NumberInput
        v-model="params.a"
        :min="paramsConfig.a.min"
        :max="paramsConfig.a.max"
        :step="paramsConfig.a.step"
        :defaultValue="paramsConfig.a.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      ><div>a:</div></NumberInput>
      <NumberInput
        v-model="params.c"
        :min="paramsConfig.c.min"
        :max="paramsConfig.c.max"
        :step="paramsConfig.c.step"
        :defaultValue="paramsConfig.c.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      >c:</NumberInput>
    </template>
  </FuncParamsWrapper>
  <FuncParamsWrapper v-else-if="pattern == 'log'" :disabled="disabled">
    <template v-slot:func>
      A(t) = A<sub>max</sub>log<sub>b</sub>(at) + c
    </template>
    <template v-slot:params>
      <NumberInput
        v-model="params.amplitude"
        :min="paramsConfig.amplitude.min"
        :max="paramsConfig.amplitude.max"
        :step="paramsConfig.amplitude.step"
        :defaultValue="paramsConfig.amplitude.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      ><div>A<sub>max</sub>:</div></NumberInput>
      <NumberInput
        v-model="params.a"
        :min="paramsConfig.a.min"
        :max="paramsConfig.a.max"
        :step="paramsConfig.a.step"
        :defaultValue="paramsConfig.a.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      ><div>a:</div></NumberInput>
      <NumberInput
        v-model="params.b"
        :min="paramsConfig.b.min"
        :max="paramsConfig.b.max"
        :step="paramsConfig.b.step"
        :defaultValue="paramsConfig.b.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      ><div>b:</div></NumberInput>
      <NumberInput
        v-model="params.c"
        :min="paramsConfig.c.min"
        :max="paramsConfig.c.max"
        :step="paramsConfig.c.step"
        :defaultValue="paramsConfig.c.defaultValue"
        :disabled="disabled"
        @blur="onUserAction"
      >c:</NumberInput>
    </template>
  </FuncParamsWrapper>
</template>

<script>
import NumberInput from '../base/NumberInput.vue';
import FuncParamsWrapper from './FuncParamsWrapper.vue';

export default {
  name: 'FuncParams',

  components: {
    NumberInput,
    FuncParamsWrapper,
  },

  props: {
    pattern: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
    },

    params: {
      type: Object,
      required: true,
    },

    paramsConfig: {
      type: Object,
      required: true,
    },
  },

  methods: {
    onUserAction() {
      this.$emit('userAction');
    }
  },
}
</script>
