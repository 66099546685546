<template>
  <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false" :disabled="disabled">
    <template v-slot:activator="{ on }">
      <div :style="swatchStyle" v-on="on" class="color-picker" :title="title || null"/>
    </template>
    <v-card>
      <v-card-text class="pa-0">
        <v-color-picker v-model="color" mode="hexa" flat />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'ColorPicker',

  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      requried: false,
    },
    title: {
      type: String,
      requried: false,
    }
  },

  data() {
    return {
      color: this.value,
      menu: false,
    };
	},

  watch: {
    value() {
      this.color = this.value;
    },
    color() {
      this.$emit('input', this.color);
    },
    menu(v) {
      if (v) {
        this.colorWhenOpened = this.color;
      } else {
        if (this.colorWhenOpened != this.color) {
          this.$emit('blur');
        }
        delete this.colorWhenOpened;
      }
    },
  },

  computed: {
    swatchStyle() {
      const { color, menu } = this;
      return {
        backgroundColor: color,
        borderRadius: menu ? '50%' : '4px',
      }
    }
  }
}
</script>

<style scoped>

.color-picker {
  cursor: pointer;
  height: 30px;
  width: 30px;
  transition: border-radius 200ms ease-in-out;
  border: 2px solid #b0b0b0;
}

</style>
