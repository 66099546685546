<template>
  <v-card
    class="mb-2 chart-settings"
    :disabled="loading"
    :loading="loading"
  >
    <v-card-text class="pt-6">
      <v-row>
        <v-col cols="12" class="pb-0">
          <div class="d-flex justify-space-between">
            <v-select
              :items="patternTypes"
              :value="signal.type"
              label="Model"
              title="Change the model"
              @change="onChageType"
            />
            <div class="d-flex align-center ml-5">
              <ColorPicker v-model="signal.color" @blur="userAction" title="Model color" />
              <div class="ml-2 mr-2"></div>
              <v-btn icon @click="onDuplicateCard" title="Duplicate model">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <div class="ml-2 mr-2"></div>
              <VisibilityEye v-model="signal.visible" visibleTitle="Hide model" hiddenTitle="Show model" />
              <div class="ml-2 mr-2"></div>
              <v-btn color="error" icon @click="onRemoveCard" title="Remove model">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
              <v-btn icon @click="onMinimize(!signal.minimized)" :title="signal.minimized ? 'Open' : 'Minimize'">
                <v-icon v-if="signal.minimized">mdi-chevron-down</v-icon>
                <v-icon v-else>mdi-window-minimize</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row :class="{'d-none': signal.minimized}">

        <v-col cols="12" :class="{'pt-0': true, 'pb-0': true, 'd-none': signal.minimized}">
          Offset:&nbsp;
          <NumberSlider
            :value="signal.time"
            :min="config.signalTime.min"
            :max="Math.min(config.signalTime.max, duration)"
            :step="config.signalTime.step"
            title="The offset of the model"
            @input="onChangeOffset"
            @blur="userAction"
          >
            <template v-slot:prepend>
              <span>Offset</span>
            </template>
            <template v-slot:append>
              <NumberInput
                :value="signal.time"
                :min="config.signalTime.min"
                :max="Math.min(config.signalTime.max, duration)"
                :step="config.signalTime.step"
                :defaultValue="config.signalTime.defaultValue"
                title="The offset of the model"
                @input="onChangeOffset"
                @blur="userAction"
              >
                <template v-slot:append>s</template>
              </NumberInput>
            </template>
          </NumberSlider>
        </v-col>

        <v-col
          v-if="drawing"
          cols="12"
          class="pt-0 pb-2 text-center"
        >
          <v-btn
            color="success"
            title="Accept drawn"
            @click="onStopDrawing"
          >
            <span>Ok</span>
          </v-btn>
          &nbsp;&nbsp;
          <v-btn
            color="error"
            title="Cancel drawing"
            @click="onCancelDrawing"
          >
            <span>Cancel</span>
          </v-btn>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="pt-0 pb-2 text-center"
        >
          <v-btn
            color="primary"
            title="Start drawing"
            @click="onStartDrawing"
          >
            <v-icon>mdi-pencil</v-icon>
            <span>Draw</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import NumberSlider from '../base/NumberSlider.vue';
import NumberInput from '../base/NumberInput.vue';
import VisibilityEye from '../base/VisibilityEye.vue';
import ColorPicker from '../base/ColorPicker.vue';

export default {
  name: 'SignalSettingsFreeDrawCard',

  components: {
    NumberSlider,
    NumberInput,
    VisibilityEye,
    ColorPicker,
  },

  props: {
    signal: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    patternTypes: {
      type: Array,
      required: true,
    },
    drawing: {
      type: Boolean,
      required: false,
    },
  },

  methods: {
    onChageType(type) {
      this.$emit('patternTypeChange', type);
    },

    onStartDrawing() {
      this.$emit('startDrawing');
    },

    onStopDrawing() {
      this.$emit('stopDrawing');
    },

    onCancelDrawing() {
      this.$emit('cancelDrawing');
    },

    onRemoveCard() {
      this.$emit('removeCard');
    },

    onDuplicateCard() {
      this.$emit('duplicateCard');
    },

    onMinimize(minimized) {
      this.signal.minimized = minimized;
      this.$emit('input', this.signal);
    },

    onChangeOffset(offset) {
      this.signal.time = offset;
      this.$emit('input', this.signal);
    },

    userAction() {
      this.$emit('userAction');
    },
  },
};
</script>
