<template>
<div>

<p>A list of patterns can be exported in <code>JSON</code> format or a <code>.js</code> file containing functions for
handling exported patterns.</p>

<h3>JSON</h3>
<p>The <code>JSON</code> format is destined to be handled by the developers for custom implementation of vibration
functions or custom devices. The file contains a list of patterns (key is the pattern's title or id specified when it
was exported). Each pattern is an <code>array</code> where each element is an entity described by two parameters:
<code>intensity</code> - relative intensity of vibration, and <code>duration</code> - time (in milliseconds) of the
vibration. Next is presented an example of the content of a <code>JSON</code> file:</p>

<pre>
<code class="d-block">{
  "First pattern": [
    {"intensity": 50, "duration": 100}, {"intensity": 56, "duration": 100}, , {"intensity": 62, "duration": 100},
    {"intensity": 68, "duration": 100}, {"intensity": 74, "duration": 100}, {"intensity": 80, "duration": 100},
    {"intensity": 86, "duration": 100}, {"intensity": 92, "duration": 100}, {"intensity": 98, "duration": 100}
  ],
  "second_pattern_key": [
    {"intensity": 80, "duration": 250}, {"intensity": 40, "duration": 250}, , {"intensity": 80, "duration": 250},
    {"intensity": 40, "duration": 250}, {"intensity": 80, "duration": 250}, {"intensity": 40, "duration": 250},
    {"intensity": 80, "duration": 250}, {"intensity": 40, "duration": 250}, {"intensity": 88, "duration": 250}
  ]
}
</code>
</pre>

<h3>JS</h3>
<p><code>JS</code> file exports a function that can be used to handle exported patterns. This function has three main
applications:
<br>
<span class="sm-g">(in bellow examples, it is supposed that the developer exported patterns in
<code>patterns.js</code> file)</span>

<h4>Get pattern:</h4>
<pre>
<code class="d-block">import vireo from './patterns';

const firstPattern = vireo("First pattern");
const secondPattern = vireo("second_pattern_key");

if (firstAction()) {
  vibrotactileFeedback(firstPattern);
} else {
  vibrotactileFeedback(secondPattern);
}
</code>
</pre>

<h4>Get pattern as an argument for navigator.vibrate function using <code>pwm</code>:</h4>
<pre>
<code class="d-block">import vireo from './patterns';

const firstPattern = vireo.navigatorParam("First pattern");
const secondPattern = vireo.navigatorParam("second_pattern_key");

if (firstAction()) {
  navigator.vibrate(firstPattern);
} else {
  navigator.vibrate(secondPattern);
}
</code>
</pre>

<h4>Call a function that use <code>navigator</code> to perform the vibration:</h4>
<pre>
<code class="d-block">import vireo from './patterns';

if (firstAction()) {
  vireo.vibrate("First pattern");
} else {
  vireo.vibrate("second_pattern_key");
}
</code>
</pre>

</div>
</template>


<script>

export default {
  name: 'ExportedFileUsage',
};

</script>

<style scopped>
pre {
  display: inline;
}
.sm-g {
  color: #777777;
  font-size: 0.8em;
}
</style>