<template>
  <v-icon
    :color="color()"
    @click="onClick"
    :title="title()"
  >{{ icon() }}</v-icon>
</template>

<script>
export default {
  name: 'VisibilityEye',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    visibleTitle: {
      type: String,
      required: false,
    },
    hiddenTitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      c_value: this.value,
    };
  },
  watch: {
    value() {
      this.c_value = this.value;
    },
  },
  methods: {
    color() {
      return this.c_value ? 'blue' : '';
    },
    icon() {
      return this.c_value ? 'mdi-eye' : 'mdi-eye-off';
    },
    title() {
      return this.c_value ? (this.visibleTitle || 'Hide') : (this.hiddenTitle || 'Show');
    },
    onClick() {
      this.c_value = !this.c_value;
      this.$emit('input', this.c_value);
    }
  },

}
</script>
