export function readFile(file, method, cb) {
  let reader;
  if ( !method ) method = 'data';
  let prom = new Promise(function (resolve, reject) {
    try {
      reader = new FileReader();

      // If it takes to long to load the file, the file could be too big!
      let abortTo = setTimeout(function () {
        reader.abort();
      }, 10e3);

      reader.onload = function (evt) {
        clearTimeout(abortTo);
        let target = evt.target;
        target.total  = evt.total;
        target.loaded = evt.loaded;
        target.file   = file;
        target.method = method;
        let result = target.result;
        cb && cb(null, result, file);
        resolve(target);
      };

      reader.onerror = function (evt) {
         clearTimeout(abortTo);
         let error = evt.target.error;
         let msg = error.message;
         switch(error.name) {
          case 'NotFoundError':
            msg = 'File Not Found!';
          break;
          case 'NotReadableError':
            msg = 'File is not readable';
          break;
          case 'AbortError':
            msg = 'File read cancelled';
          break;
          default:
            msg = 'An error occurred reading this file.';
        }
        error.msg = msg;
        cb && cb(error, null, file);
        reject(error);
      };

      // reader.onloadstart = function (evt) {  };
      // reader.onprogress  = function (evt) {  };
      // reader.onabort     = function (evt) {  };

      // Read in the image file as a data URL.
      reader[{
        data  : 'readAsDataURL',
        text  : 'readAsText',
        binary: 'readAsBinaryString', // unsupported in IE
        buffer: 'readAsArrayBuffer',
      }[method]](file);
    }
    catch(err) { reject(err); }
  });
  prom.reader = reader;
  prom.file = file;

  return prom;
}

const AUDIO_TYPES = new Set([
  'audio/mpeg', 'audio/wav'
]);

function extractContent(file) {
  // data:audio/wav;base64,UklGRpD
  return file.result.substr(13 + file.file.type.length);
}

export function validateAudioTrack(file) {
  const data = '';
  if (!file) {
    return [data, ''];
  }
  if (!file || !file.file || !file.file.type) {
    return [data, 'Unknown type']
  }
  if (typeof file.result !== 'string') {
    return [data, 'Empty content'];
  }
  if (!AUDIO_TYPES.has(file.file.type)) {
    return [data, 'Unaccpeted audio track format'];
  }
  if (file.file.size > 524288) {
    return [data, 'File is too large'];
  }
  return [extractContent(file), ''];
}
