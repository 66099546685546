<template>
  <LoadingPage
    v-if="user === null"
  />
  <AuthView
    v-else-if="user.id === 0 && !userAsGuest"
    @login="login"
    @loginasguest="loginAsGuest"
  />
  <AppsEvaluation
    v-else-if="$route.path.toLowerCase().replace(/^\/+|\/+$/, '') == 'apps-eval'"
  />
  <DashboardView
    v-else
    :user="user"
  />
</template>

<script>
import LoadingPage from '../components/LoadingPage';
import AuthView from './AuthView';
import DashboardView from './DashboardView';
import AppsEvaluation from '../components/AppsEvaluation/AppsEvaluation';

import api from '../util/api';

export default {
  name: 'IndexView',

  components: {
    LoadingPage,
    AuthView,
    DashboardView,
    AppsEvaluation,
  },

  data() {
    return {
      user: null,
      userAsGuest: false,
    }
  },

  methods: {
    login(user) {
      this.user = user;
    },

    loginAsGuest() {
      this.userAsGuest = true;
    },
  },

  mounted() {
    let tuser;
    api
    .getUser()
    .then(({ user }) => {
      tuser = user;
      if (user.id === 0) {
        return api.getGuestUser();
      }
      return false;
    })
    .then((guest) => {
      this.userAsGuest = guest;
      this.user = tuser;
    })
    .catch(err => {
      alert(`Error: ${err.message}`);
      console.error(err)
    })
    ;
  }
}
</script>
