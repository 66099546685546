<template>
  <v-alert
    v-model="visible"
    dismissible
    dense
    outlined
    type="error"
    border="left"
  >
    <b>{{ value.response && value.response.data && value.response.data.msg || value.message }}</b>
  </v-alert>
</template>

<script>
export default {
  name: 'ErrorAlert',

  props: {
    value: {
      required: true
    },
  },

  data() {
    return {
      visible: this.valueVisibility(),
    };
  },

  watch: {
    value() {
      this.visible = this.valueVisibility();
    },

    visible(to) {
      if (!to) {
        this.$emit('input', false);
      }
    },
  },

  methods: {
    valueVisibility(value) {
      const visible = this.value != false;
      if (visible) {
        console.error(this.value);
      }
      return visible;
    },

    hideError() {
      this.visible = false;
    }
  }
}
</script>
