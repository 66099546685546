import {drag} from 'd3-drag';
import {select} from 'd3-selection';

let pointsQueue = [];

function canAddPointInQueue(p) {
  const len = pointsQueue.length;
  if (len < 2) {
    return true;
  }
  if (len == 2) {
    return true;
  }

  const p1 = pointsQueue[len - 1];
  const p2 = pointsQueue[len - 2];
  return (p1.x - p.x) * (p1.x - p2.x) < 0;
}

function addInQueue(point, limits) {
  pointsQueue.push({
    x: point.x,
    y: Math.max(limits[0], Math.min(limits[1], point.y)),
  });
}

function getDatasetElement(chartInstance, datasetIndex, elementIndex) {
  const e = chartInstance.getDatasetMeta(datasetIndex);
  return e.data[elementIndex];
}

function chartCoordinateY(y, chartInstance, datasetIndex, elementIndex) {
  const element = getDatasetElement(chartInstance, datasetIndex, elementIndex);
  const scaleName = '_yScale';
  const chartScale = chartInstance.scales[ element[scaleName].id ];
  return chartScale.getValueForPixel(y);
}

function chartCoordinateX(x, chartInstance, datasetIndex, elementIndex) {
  const element = getDatasetElement(chartInstance, datasetIndex, elementIndex);
  const scaleName = element._xScale.id;
  const chartScale = chartInstance.scales[scaleName];
  return chartScale.getValueForPixel(x);
}

function visibleElementsAt(x, chartInstance) {
  const metas = chartInstance._getSortedVisibleDatasetMetas();
  const datasetsHere = [];
  for (let i = 0; i < metas.length; i++) {
    const { data } = metas[i];
    const len = data.length
    if (len > 1) {
      const firstx = data[0]._view.x;
      const lastx = data[len - 1]._view.x;
      if (firstx < x && x < lastx) {
        datasetsHere.push(i);
      }
    }
  }
  return datasetsHere;
}

function chartCoordinates(chartInstance, point, datasetIndex, elementIndex) {
  try {
    const x = chartCoordinateX(point.x, chartInstance, datasetIndex, elementIndex);
    const y = chartCoordinateY(point.y, chartInstance, datasetIndex, elementIndex);
    return {
      x, y,
      visibleElementsAt: visibleElementsAt.bind(null, point.x, chartInstance)
    };
  } catch {
    return null;
  }
}

function drawSignalStart(chartInstance, limits) {
  return (event) => {
    const position = chartCoordinates(chartInstance, event, 0, 0);
    if (position) {
      addInQueue(position, limits);
    }
  }
}

function drawSignalDrag(chartInstance, limits, callback) {
  return (event) => {
    const position = chartCoordinates(chartInstance, event, 0, 0);
    if (position && canAddPointInQueue(position)) {
      addInQueue(position, limits);
    }
    if (callback) {
      callback(chartInstance, pointsQueue);
    }
  }
}

function drawSignalEnd(chartInstance, limits, callback) {
  return (event) => {
    const position = chartCoordinates(chartInstance, event, 0, 0);
    if (position && canAddPointInQueue(position)) {
      addInQueue(position, limits);
    }

    if (callback) {
      if (pointsQueue.length >= 2 && pointsQueue[0].x > pointsQueue[1].x) {
        pointsQueue.reverse();
      }
      callback(chartInstance, pointsQueue);
    }
    pointsQueue = [];
  }
}

const ChartJSDrawSignal = {
  id: 'drawSignal',
  afterInit(chartInstance) {
    if (chartInstance.config.type == 'line' && chartInstance.options.drawSignal) {
      const options = chartInstance.options.drawSignal;
      select(chartInstance.chart.canvas).call(
        drag().container(chartInstance.chart.canvas)
          .on('start', drawSignalStart(chartInstance, options.limits))
          .on('drag', drawSignalDrag(chartInstance, options.limits, options.onDrag))
          .on('end', drawSignalEnd(chartInstance, options.limits, options.onStop))
      );
    }
  }
};

export default ChartJSDrawSignal;
