<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      @click:outside="$emit('cancel')"
    >
      <v-card>
        <v-card-title class="mb-4 text-h5 grey lighten-2">Edit pattern component</v-card-title>
        <div class="display-container">
          <FreeDrawDisplay
            v-if="drawingSignal"
            :duration="duration"
            :color="signal.color"
            :points="displaySignalFreeDraw()"
            :freeDrawRequest="freeDrawRequest"
            @freeDrawCompleted="onFreeDrawCompleted"
          />
          <SignalsDisplay
            v-else
            :duration="duration"
            :signals="displaySignals()"
            :stepsView="null"
            @changeSignalsTime="onChangeSignalsTime"
          />
        </div>

        <SignalSettingsFreeDrawCard
          v-if="signal.type == 'freeDraw'"
          :signal="signal"
          :config="config"
          :duration="duration"
          :disabled="loading"
          :loading="loading"
          :patternTypes="patternTypes"
          :drawing="drawingSignal"
          :inCard="false"
          @patternTypeChange="onPatternTypeChanged"
          @input="(s) => { signal = s; }"
          @startDrawing="onStartDrawing"
          @stopDrawing="onStopDrawing"
          @cancelDrawing="onCancelDrawing"
        />
        <SignalSettingsCard
          v-else
          v-model="signal"
          :config="config"
          :duration="duration"
          :disabled="loading"
          :loading="loading"
          :inCard="false"
          :patternTypes="patternTypes"
          @patternTypeChange="onPatternTypeChanged"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            title="Accept changes"
            @click="save"
          >
            Save
          </v-btn>

          <v-btn
            color="warning"
            text
            title="Discard changes"
            @click="$emit('cancel')"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SignalSettingsFreeDrawCard from './SignalSettingsFreeDrawCard.vue';
import SignalsDisplay from './SignalsDisplay.vue';
import FreeDrawDisplay from './FreeDrawDisplay.vue';
import SignalSettingsCard from './SignalSettingsCard.vue';

import { clone } from '../../util/util';
import { signalFuncFactory } from '../../util/signal-data/factory';

import { patternDefaultParams } from '../../util/signal-patterns';

export default  {
  name: 'SignalSettingsDialog',

  components: {
    SignalSettingsFreeDrawCard,
    SignalSettingsCard,
    SignalsDisplay,
    FreeDrawDisplay,
  },

  props: {
    value: {
      required: true,
      type: Object,
    },
    duration: {
      required: true,
      type: Number,
    },
    config: {
      required: true,
      type: Object,
    },
    loading: {
      required: false,
      type: Boolean,
    },
    patternTypes: {
      required: true,
      type: Array,
    },
    patternParamsByType: {
      required: true,
      type: Function,
    },
  },

  data() {
    this.signalCache = {};
    return {
      dialog: true,
      signal: clone(this.value),
      freeDrawRequest: 0,
      drawingSignal: false,
    };
  },

  methods: {
    save() {
      this.$emit('input', this.signal);
      this.dialog = false;
    },

    // @FIXME: this is a "simpified copy" of the same fn from MultipeSignalApp
    onPatternTypeChanged(type) {
      // this.signal = this.patternParamsByType(type);
      const cache = this.signalCache;
      cache[this.signal.type] = clone(this.signal);

      if (cache[type]) {
        this.signal = cache[type];
        delete cache[type];
      } else {
        if (type == 'freeDraw') {
          const pf_time = this.signal.time;
          this.signal.params = signalFuncFactory(this.signal).points.map(p => {
            return {
              x: p.x - pf_time,
              y: p.y,
            };
          });
        } else {
          this.signal.params = patternDefaultParams(type);
        }
        this.signal.type = type;
      }
    },

    // @FIXME: displaySignals is almost the same as in MultipleSignalApp
    displaySignals() {
      const component = this.signal;
      const sp = signalFuncFactory(component);

      return [{
        func: sp._fn.bind(sp),
        data: sp.points,
        color: component.color,
        time: component.time,
        duration: component.duration,
      }];
    },

    // @FIXME: onStartDrawing, onStopDrawing, onCancelDrawing,
    // onFreeDrawCompeted, displaySignalFreeDraw, onChangeSignalsTime
    // are from MultipleSignalApp, we don't respect DRY
    onStartDrawing() {
      this.drawingSignal = true;
    },

    onStopDrawing() {
      this.freeDrawRequest++;
    },

    onCancelDrawing() {
      this.drawingSignal = false;
    },

    onFreeDrawCompleted(points) {
      const c_signal = this.signal;
      if (points.length) {
        c_signal.time = points[0].x;
        c_signal.duration = points[points.length - 1].x - points[0].x;
        c_signal.params = points.map(p => {
          return {
            x: p.x - c_signal.time,
            y: p.y,
          };
        });
      } else {
        c_signal.time = 0;
        c_signal.params = [];
      }

      this.drawingSignal = false;
    },

    displaySignalFreeDraw() {
      const { time, params } = this.signal;
      return params.map(p => {
        return {
          x: p.x + time,
          y: p.y,
        };
      });
    },

    onChangeSignalsTime(signalsTime) {
      if (signalsTime.length) {
        this.signal.time = signalsTime[0];
      }
    },
  },

};
</script>
