<template>

  <v-card
    class="mb-2 chart-settings ss-params"
    :disabled="loading"
    :loading="loading"
  >
    <v-card-text class="pt-6">
      <v-row>
        <v-col cols="12" class="pb-0">
          <div class="d-flex justify-space-between">
            <v-select
              :items="patternTypes"
              :value="c_signal.type"
              label="Model"
              title="Change the model"
              @change="onChageType"
            />
            <div class="d-flex align-center ml-5">
              <ColorPicker v-model="c_signal.color" @blur="userAction" title="Model color" />
              <div class="ml-2 mr-2"></div>
              <v-btn v-if="inCard" icon @click="onDuplicateCard" title="Duplicate model">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <div class="ml-2 mr-2"></div>
              <VisibilityEye :value="c_signal.visible" @input="changeVisibility" visibleTitle="Hide model" hiddenTitle="Show model" />
              <div v-if="inCard" class="ml-2 mr-2"></div>
              <v-btn v-if="inCard" color="error" icon @click="onRemoveCard" title="Remove model">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
              <v-btn v-if="inCard" icon @click="onMinimize" :title="c_signal.minimized ? 'Open' : 'Minimize'">
                <v-icon v-if="c_signal.minimized">mdi-chevron-down</v-icon>
                <v-icon v-else>mdi-window-minimize</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>

        <v-col cols="12" :class="{'pt-0': true, 'pb-0': true, 'd-none': c_signal.minimized}">
          <FuncParams
            :pattern="c_signal.type"
            :params="c_signal.params"
            :paramsConfig="patternConfig(c_signal.type)"
            @userAction="userAction"
          />
        </v-col>
        <v-col cols="12" :class="{'pt-0': true, 'pb-0': true, 'd-none': c_signal.minimized}">
          <NumberSlider
            v-model="c_signal.time"
            :min="config.signalTime.min"
            :max="Math.min(config.signalTime.max, duration)"
            :step="config.signalTime.step"
            title="The offset of the model"
            @blur="userAction"
          >
            <template v-slot:prepend>
              <span>Offset</span>
            </template>
            <template v-slot:append>
              <NumberInput
                v-model="c_signal.time"
                :min="config.signalTime.min"
                :max="Math.min(config.signalTime.max, duration)"
                :step="config.signalTime.step"
                :defaultValue="config.signalTime.defaultValue"
                title="The offset of the model"
                @blur="userAction"
              >
                <template v-slot:append>s</template>
              </NumberInput>
            </template>
          </NumberSlider>
        </v-col>

        <v-col cols="12" :class="{'pt-0': true, 'pb-0': true, 'd-none': c_signal.minimized}">
          <NumberSlider
            v-model="c_signal.duration"
            :min="config.signalDuration.min"
            :max="Math.max(value.duration, Math.min(config.signalDuration.max, duration))"
            :step="config.signalDuration.step"
            @blur="userAction"
          >
            <template v-slot:prepend>
              <span>Duration</span>
            </template>
            <template v-slot:append>
              <NumberInput
                v-model="c_signal.duration"
                :min="config.signalDuration.min"
                :max="Math.max(value.duration, Math.min(config.signalDuration.max, duration))"
                :step="config.signalDuration.step"
                :defaultValue="config.signalDuration.defaultValue"
                title="The duration of the model"
                @blur="userAction"
              >
                <template v-slot:append>s</template>
              </NumberInput>
            </template>
          </NumberSlider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import SignalsDisplay from './SignalsDisplay.vue';
import FuncParams from './FuncParams.vue';
import NumberSlider from '../base/NumberSlider.vue';
import NumberInput from '../base/NumberInput.vue';
import VisibilityEye from '../base/VisibilityEye.vue';
import ColorPicker from '../base/ColorPicker.vue';

import { clone } from '../../util/util';
import { patternParamsConfig } from '../../util/signal-patterns';

export default {
  name: 'SignalSettingsCard',

  components: {
    SignalsDisplay,
    FuncParams,
    NumberSlider,
    NumberInput,
    VisibilityEye,
    ColorPicker,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    patternTypes: {
      type: Array,
      required: true,
    },
    inCard: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: Boolean,
    disabled: Boolean,
  },

  data() {
    return {
      c_signal: clone(this.value),
      needChange: true,
    };
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.c_signal = clone(this.value);
        this.needChange = false;
      },
    },

    c_signal: {
      deep: true,
      handler() {
        if (this.needChange) {
          this.$emit('input', this.c_signal);
        } else {
          this.needChange = true;
        }
      }
    }
  },

  methods: {
    patternConfig(pattern) {
      return patternParamsConfig(pattern);
    },

    onChageType(type) {
      this.$emit('patternTypeChange', type);
    },

    onRemoveCard() {
      this.$emit('removeCard');
    },

    onDuplicateCard() {
      this.$emit('duplicateCard');
    },

    onMinimize() {
      this.c_signal.minimized = !this.c_signal.minimized;
      this.$emit('input', this.c_signal);
    },

    changeVisibility(v) {
      this.c_signal.visible = v;
      this.$emit('input', this.c_signal);
      this.userAction();
    },

    userAction(/* ...fields */) {
      this.$emit('userAction');
      // const signalChunk = {};
      // for (let i = 0; i < fields.length; i++) {
      //   const field = fields[i];
      //   signalChunk[field] = this.c_signal[field];
      // }
      // this.$emit('userAction', 'editComponentFields', clone(signalChunk));
    }
  },
}
</script>

<style>

.display-container {
  position: relative;
  height: 400px;
  max-height: calc(100vh - 540px);
  min-height: 100px;
}

.ss-params,
.ss-params .v-input {
  font-size: 18px;
}

</style>
