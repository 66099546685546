<template>
  <header>
    <EmptyButton @click="$emit('onMenuButtonClick')">
      <HamburgerIcon />
    </EmptyButton>
    <router-link to="/" class="logo">
      <img src="../../assets/logo.png" :title="title" :alt="title" />
    </router-link>
    <a href="/logout" @click.prevent="logout" class="text-decoration-none" title="Logout">
      <v-icon>mdi-logout</v-icon>
    </a>
  </header>
</template>

<script>
import EmptyButton from '../base/EmptyButton';
import HamburgerIcon from '../icons/HamburgerIcon';
import Avatar from './Avatar';
import api from '../../util/api';

export default {
  name: 'Header',
  components: {
    EmptyButton,
    Avatar,
    HamburgerIcon,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    logout() {
      api.logout()
      .then(() => {
        // @TODO: use router to go to main page
        document.location = '/';
      })
      .catch(err => {
        this.$emit('error', err);
      });
    }
  },
}
</script>


<style lang="scss" scoped>
header {
  display: flex;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #d8dbe0;
  height: 56px;
  padding: 0 1.5rem;
  align-items: center;
  z-index: 115;
}

.logo {
  display: inline-flex;
  margin: 0 auto;
  line-height: 56px;
  color: #3c4b64;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
}
</style>
