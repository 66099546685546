<template>
<v-dialog :value="value" fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card>
    <v-toolbar dark color="#3c4b64">
      <v-btn icon dark @click="onClose">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Generating patterns</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text disabled @click="onClose">Save</v-btn>
      </v-toolbar-items>

      <template v-slot:extension>
        <v-tabs align-with-title v-model="tab">
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab href="#random">Random</v-tab>
          <v-tab href="#byparam">By parameters</v-tab>
          <v-tab href="#vibviz">From VibVix</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item value="random">
        <SignalGenerator
          :signal="signal"
          :configurable="false"
          @signalAdd="signalAdd"
        />
      </v-tab-item>
      <v-tab-item value="byparam">
        <SignalGenerator
          :signal="signal"
          :configurable="true"
          @signalAdd="signalAdd"
        />
      </v-tab-item>
      <v-tab-item value="vibviz">
        <SignalVibviz
          :signal="signal"
          @signalAdd="signalAdd"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</v-dialog>
</template>

<script>
import SignalGenerator from './SignalGenerator.vue';
import SignalVibviz from './generator/SignalVibviz.vue';

export default {
  name: 'SignalGeneratorBoard',

  components: {
    SignalGenerator,
    SignalVibviz,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    signal: {
      type: Object,
      required: true,
    },
  },

  watch: {
    generatingSignals() {
      this.$emit('input', this.generatingSignals);
    },
  },

  data() {
    return {
      tab: '#params',
      generatingSignals: true,
    };
  },

  methods: {
    onClose() {
      this.$emit('close');
    },

    signalAdd(signal) {
      this.$emit('signalAdd', signal);
    },
  },
};
</script>
