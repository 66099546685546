<template>
<div class="d-flex justify-center">
  <div v-if="configurable" style="width: 400px;" class="pa-4">
    <GenConfig
      v-for="(model, i) in paramLimits"
      v-model="paramLimits[i]"
      :modelType="signal.components[i].type"
      :key="i"
      :minimized="minimized[i]"
      :patternParamsConfig="patternParamsConfig(signal.components[i].type)"
      :color="signal.components[i].color"
      @swichMinimize="switchMinimize(i)"
      @switchColor="(color) => switchColor(i, color)"
    />
  </div>
  <div class="flex-grow-1 pa-6" v-if="genSignals.length">
    <div class="pa-4 pt-8">
      <v-btn @click="genSignals=[]" class="mr-2">Reset</v-btn>
      <v-btn @click="onGenerate" class="ml-2">Regenerate all</v-btn>
    </div>
    <div class="row">
      <div v-for="(gsignalsComponents, i) in genSignals" :key="i" class="col-md-4 d-flex">
        <div class="d-flex flex-column">
          <v-btn
            icon
            color="green"
            :disabled="savedSignals.includes(i)"
            @click="onSaveSignal(i)"
          ><v-icon>mdi-content-save-outline</v-icon></v-btn>
          <v-btn
            icon
            color="blue"
            @click="onRegenerateSignal(i)"
          ><v-icon>mdi-reload</v-icon></v-btn>
        </div>
        <SignalDisplay
          class="flex-grow-1"
          :components="gsignalsComponents"
          :duration="signal.duration"
        />
      </div>
    </div>
  </div>
  <div class="mt-8 d-flex flex-grow-1 justify-center" v-else>
    <v-btn color="primary" @click="onGenerate">Generate</v-btn>
  </div>
</div>
</template>

<script>
import api from '../../util/api';

import GenConfig from './generator/GenConfig.vue';
import SignalDisplay from './generator/SignalDisplay.vue';
import { patternParamsConfig } from '../../util/signal-patterns';

import { clone, random } from '../../util/util';

export default {
  name: 'SignalGeneratorBoard',

  components: {
    GenConfig,
    SignalDisplay,
  },

  props: {
    configurable: {
      type: Boolean,
      required: false,
      default: true,
    },
    signal: {
      // type: Object,
      required: true,
    },
  },

  data() {
    const {paramLimits, minimized} = this.refereshSignal();
    return {
      paramLimits: paramLimits,
      minimized: minimized,
      gsignalsCount: 9,
      genSignals: [],
      savedSignals: [],
    };
  },

  watch: {
    signal: {
      deep: true,
      handler() {
        const {paramLimits, minimized} = this.refereshSignal();
        this.paramLimits = paramLimits;
        this.minimized = minimized;
        this.genSignals = [];
        this.savedSignals = [];
      },
    },
  },

  methods: {
    refereshSignal() {
      const paramLimits = [];
      const minimized = [];

      const _minimized = this.minimized || [];
      for (let i = 0; i < this.signal.components.length; i++) {
        const signalComponent = this.signal.components[i];
        const item = {};
        if (signalComponent.type == 'freeDraw') {
          item.delta = 10;
        } else {
          const cfgParams = this.patternParamsConfig(signalComponent.type);
          for (let key in cfgParams) {
            const param = cfgParams[key];
            if (param.min === undefined || param.max === undefined) {
              continue;
            }
            item[key] = {
              min: signalComponent.params[key] - 30,
              max: signalComponent.params[key] + 30,
              // min: param.min,
              // max: param.max,
            };
          }
        }
        paramLimits.push(item);
        minimized[i] = _minimized[i] || false;
      }

      return {paramLimits, minimized};
    },

    patternParamsConfig(type) {
      return patternParamsConfig(type);
    },

    switchMinimize(index) {
      this.minimized = this.minimized.map((val, i) => {
        if (index == i) {
          return !val;
        }
        return val;
      });
    },

    onGenerate() {
      /*
      paramsLimits = [
        {a: {min, max}, b: {min, max}},
        {amplitude: {min, max}, omega: {min, max}},
      ];

      genSignals = [
        {
          duration:
          color:
          visible:
          ....
          params: [
            {a, b},
            {amplityde, omega}
          ],
        },
        {
          duration:
          color:
          visible:
          ....
          params: [
            {a, b},
            {amplityde, omega}
          ],
        },
      ]
      */
      const { gsignalsCount, signal } = this;

      const genSignals = [];
      for (let i = 0; i < gsignalsCount; i++) {
        const components = clone(signal.components);
        this.generateNewParamsForSignal(components);
        genSignals.push(components);
      }

      this.genSignals = genSignals;
    },

    onRegenerateSignal(index) {
      this.genSignals = this.genSignals.map((s, i) => {
        // @TODO: didn't think about performance at all
        // should be better to modify only the `index` element instad of
        // recreating the whole array and cloning `index` signal
        if (index == i) {
          s = clone(this.signal.components);
          this.generateNewParamsForSignal(s);
        }
        return s;
      });

      this.savedSignals = this.savedSignals.filter(v => v !== index);
    },

    onSaveSignal(index) {
      if (!this.genSignals[index]) {
        // @TODO: react in a more nice manner
        alert("No signal");
        return;
      }
      const signal = clone(this.signal);
      signal.components = this.genSignals[index];
      this.loading = true;
      const now = new Date();
      signal.title = signal.title.replace(/\s*\d{1,2}:\d{2}(:\d{1,2})?\s*/, '');
      signal.title += ` ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
      signal.hash = '';
      api
      .saveSignal(signal)
      .then(({ signal }) => {
        this.savedSignals = [ ...this.savedSignals, index ];
        this.$emit('signalAdd', signal);
      })
      .catch((err) => {
        alert(err.message || 'Error');
        console.log(err);
      })
      ;
    },

    generateNewParamsForSignal(signalComponents) {
      const paramLimits = this.paramLimits;
      for (let i = 0; i < paramLimits.length; i++) {
        if (signalComponents[i].type == 'freeDraw') {
          const params = signalComponents[i].params;
          signalComponents[i].params = params.map((p, j) => {
            const { delta } = paramLimits[i];
            let y = j < 1 ? random(p.y - delta, p.y + delta) : random(p.y, p.y + delta * (p.y - params[j - 1].y < 0 ? -1 : 1));
            if (y < 0) {
              y = 0;
            } else if (y > 100) {
              y = 100;
            }
            return {
              x: p.x,
              y: y,
            };
          });
        } else {
          const params = {};
          for (let key in paramLimits[i]) {
            const {min, max} = paramLimits[i][key];
            params[key] = random(min, max)
          }
          signalComponents[i].params = params;
        }
      }
    },

    switchColor(index, color) {
      // @TODO: switching color doesn't work
      // it seems that there is an infinite loop
      // the colorpickers are disabled
      this.signal.components[index].color = color;
      for (let i = 0; i < this.genSignals.length; i++) {
        this.genSignals[i].color = color;
      }
    }
  },
};
</script>


<style lang="scss">
.gen-pat-inputs .v-text-field {
  padding: 0;
}
</style>
