<template>
  <div>
    <h1 style="text-align: center;">Loading...</h1>
    <div style="text-align: center;color: #777;">Please wait!</div>
  </div>
</template>


<script>
export default {
  name: 'LoadingPage',
}
</script>


