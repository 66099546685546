<template>
  <div class="dashboard">
    <Loader v-if="pageLoading" />
    <Sidebar
      :state="menu.state"
      @onBgClick="onMenuBgClick"
    >
      <div class="d-flex align-center justify-center font-weight-black text-center logo">
        <Avatar
          :name="user.name"
          img="avatar.png"
        />
        <span class="d-inline-block pa-4">{{ user.name }}</span>
      </div>
      <slot name="sidebar"></slot>
    </Sidebar>
    <div :class="{content: true, sbm: menu.state.lg}">
      <Header
        :title="config.title"
        :user="user"
        @onMenuButtonClick="onMenuButtonClick"
        @error="showError"
      />
      <div class="main">
        <slot name="main"></slot>
      </div>
    </div>
  </div>
</template>

<script>

import Loader from '../Loader.vue';
import Avatar from './Avatar.vue';
import Sidebar from './Sidebar.vue';
import Header from './Header.vue';

import { getSidebarMenuState } from '../../util/util';

export default {
  name: 'Dashboard',

  components: {
    Loader,
    Avatar,
    Sidebar,
    Header,
  },

  props: {
    config: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      pageLoading: false,
      menu: {
        state: getSidebarMenuState(),
      }
    };
  },

  methods: {
    onMenuBgClick() {
      this.menu.state.sm = false;
    },

    onMenuButtonClick() {
      if (window.innerWidth < 768) {
        this.menu.state.sm = true;
      } else {
        this.menu.state.lg = !this.menu.state.lg;
      }
    },

    showError(err) {
      this.$emit('error', err);
    },
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  position: relative;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: #ebedef;
}

.content {
  height: 100%;
  transition: margin-left 0.1s;
}

.main {
  box-sizing: border-box;
  padding: 1rem;
}

.logo a {
  color: #ffffff;
  text-decoration: none;
}

@media (min-width: 768px) {
  .content.sbm {
    margin-left: 300px;
  }
}
</style>
