<template>
  <div class="num-container">
    <v-text-field
      type="number"
      v-model="c_value"
      :step="cStep()"
      :disabled="disabled"
      :messages="message"
      @input="onChange"
      @blur="onBlur"
    >
      <template v-slot:prepend>
        <slot></slot>&nbsp;
      </template>
      <template v-slot:append>
        <slot name="append"></slot>
      </template>
    </v-text-field>
  </div>
</template>


<script>
const DEFAULT_STEP = 0.1;
const DEFAULT_VALUE = 0;
const INPUT_DELAY = 64;

export default {
  name: 'NumberInput',
  props: {
    value: {
      type: Number,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    step: {
      type: Number,
      required: false,
    },
    min: {
      type: Number,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      c_value: this.value, // shows what user is typing
      n_value: this.value, // Number(c_value)
    };
  },
  watch: {
    value(updatedValue) {
      this.c_value = updatedValue;
    }
  },
  methods: {
    cStep() {
      return this.step || DEFAULT_STEP;
    },
    cdefaultValue() {
      return this.defaultValue || DEFAULT_VALUE;
    },
    applyLimitsToValue(value) {
      return Math.min(
        this.max, Math.max(
          this.min, value
        )
      );
    },
    whitchinLimitsValue(value) {
      return this.min <= value && value <= this.max;
    },
    onBlur() {
      if (this.inputTimer) {
        clearTimeout(this.inputTimer);
        this.inputTimer = false;
      }

      if (!isNaN(this.c_value)) {
        this.n_value = this.applyLimitsToValue(
          Number(this.c_value) || this.cdefaultValue()
        );
      }
      this.c_value = this.n_value;
      this.$emit('input', this.n_value);
      this.$emit('blur', this.n_value);
    },
    onChange() {
      if (this.c_value && !isNaN(this.c_value) && this.whitchinLimitsValue(this.c_value)) {
        this.n_value = Number(this.c_value);
        if (!this.inputTimer) {
          this.inputTimer = setTimeout(() => {
            this.$emit('input', this.n_value);
            this.inputTimer = false;
          }, INPUT_DELAY);
        }
      }
    }
  },
  beforeUnmount() {
    if (this.inputTimer) {
      clearTimeout(this.inputTimer);
    }
  },
};
</script>

<style lang="scss" scoped>
.num-container {
  display: inline-block;
}

.input:disabled {
  background-color: #ccc;
  color: #ccc;
}
</style>
