import { BaseFunction } from './BaseFunction.js';
import { moveChartPoints, fnChartData, pointsYLimit } from '../func.js';

export class Dotted extends BaseFunction {

  _calcPoints() {
    const duration = this._time[1] - this._time[0];
    const step = Math.min(this._params.stepLength, duration / 500);
    return pointsYLimit(
      moveChartPoints(
        fnChartData(
          this._fn.bind(this),
          [0, duration], step
        ), this._time[0], 0
      ), ...this._limits
    );
  }

  _set_stepLength(value) {
    if (typeof value !== 'number') {
      throw new Error('Step length should be an number');
    }
    if (value === Infinity) {
      throw new Error('Step length should be a finit number');
    }
    if (value <= 0) {
      throw new Error('Step should be a pozitive number');
    }
    this._params.stepLength = value;
  }
}
