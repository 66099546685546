<template>
  <div>
    <v-btn
      dark
      icon
      color="grey"
      title="Export patterns"
      @click="openModal"
    ><v-icon>mdi-file-export-outline</v-icon></v-btn>

    <ExportPatternsForm
      v-if="modalOpened"
      :signals="signals"
      @close="closeModal"
      @error="showError"
    />
  </div>
</template>

<script>
import ExportPatternsForm from './ExportPatternsForm';
import api from '../../../util/api';

export default {
  name: 'ExportPatterns',

  components: {
    ExportPatternsForm,
  },

  props: {
    signals: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      modalOpened: false,
    };
  },

  methods: {
    canImport() {
      return this.code.trim().length > 0;
    },

    openModal() {
      this.modalOpened = true;
    },

    closeModal() {
      this.modalOpened = false;
    },

    checkEnter(event) {
      if (event.code == 'Enter') {
        this.onImport();
      }
    },

    onImport() {
      if (this.canImport()) {
        api.importPatterns(this.code)
        .then(signals => {
          this.$emit('onimport', signals);
          this.modalOpened = false;
          this.code = '';
        })
        .catch(err => {
          this.showError(err);
        });
      }
    },

    onClose() {
      this.modalOpened = false;
    },

    showError(err) {
      this.$emit('error', err);
    },
  },
};

</script>
