<template>
<v-app class="bgradient">
  <div class="bg-container">
    <div class="bg-bars"></div>
  </div>

  <v-main class="d-flex justify-center align-center main">
    <ThanksForRegistering
      v-if="justRegistered"
    />
    <AccountRecovered
      v-else-if="justRecovered"
    />
    <div v-else class="auth-form-container">
      <div class="auth-form-logo">
        <img src="../assets/logo-nb.png" />
      </div>

      <div class="auth-form">
        <h2 class="b-title">{{ formTitle() }}</h2>
        <ErrorAlert v-model="error" />
        <RegisterForm
          v-if="$route.params.hash === 'register'"
          :loading="loading"
          @register="register"
          @error="showError"
        />
        <RecoverForm
          v-else-if="$route.params.hash === 'recover'"
          :loading="loading"
          @recover="registerRecover"
          @error="showError"
        />
        <LoginForm
          v-else
          :loading="loading"
          @login="login"
          @loginasguest="loginAsGuest"
          @error="showError"
        />
      </div>
    </div>
  </v-main>

</v-app>
</template>

<script>
import ErrorAlert from '../components/ErrorAlert';
import LoginForm from '../components/auth/LoginForm';
import RegisterForm from '../components/auth/RegisterForm';
import RecoverForm from '../components/auth/RecoverForm';
import ThanksForRegistering from '../components/auth/ThanksForRegistering';
import AccountRecovered from '../components/auth/AccountRecovered';

import api from '../util/api';

export default {
  name: 'AuthView',

  components: {
    ErrorAlert,
    RegisterForm,
    LoginForm,
    ThanksForRegistering,
    RecoverForm,
    AccountRecovered,
  },

  data() {
    return {
      error: false,
      loading: false,
      justRegistered: false,
      justRecovered: false,
    }
  },

  watch: {
    '$route'() {
      this.error = false;
      this.justRegistered = false;
      this.justRecovered = false;
    }
  },

  methods: {
    showError(err) {
      this.error = err;
    },

    formTitle() {
      switch (this.$route.params.hash) {
        case 'register': return 'Register';
        case 'recover': return 'Account recover';
      }
      return 'Login';
    },

    goTo(hash) {
      if (this.$route.params.hash != hash) {
        this.$router.push(hash);
      }
    },

    login(email, password) {
      this.loading = true;
      api
      .login(email, password)
      .then(({ data }) => {
        this.$emit('login', data.user);
      })
      .catch(err => {
        this.showError(err);
        this.loading = false;
      })
      ;
    },

    loginAsGuest() {
      api
      .loginAsGuest()
      .then(() => {
        this.$emit('loginasguest');
      })
      .catch((err) => {
        this.loading = false;
        this.showError(err);
      });
    },

    register({name, email, password}) {
      this.loading = true;
      api
      .register(name, email, password)
      .then(({ data }) => {
        this.loading = false;
        this.justRegistered = true;
      })
      .catch(err => {
        this.loading = false;
        this.showError(err);
      })
      ;
    },

    registerRecover(email) {
      this.loading = true;
      api
      .registerRecover(email)
      .then(({ data }) => {
        this.loading = false;
        this.justRecovered = true;
      })
      .catch(err => {
        this.loading = false;
        this.showError(err);
      })
      ;
    }
  },


  mounted() {
    api
    .getUser()
    .then(({ user }) => {
      this.user = user;
    })
    .catch(err => {
      this.showError(err);
    })
    ;
  }
}
</script>

<style lang="scss" scoped>
.bg-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.bg-bars {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40vh;
  background-image: url("../assets/bg-bars.svg");
  background-position: center;
}

/* ------------------------------------------------------------------------ */

.auth-form-container {
  padding: 2rem;
  width: 96vw;
  max-width: 420px;
  margin: 0 auto;
  background-color: #f2f2f2;
  background-position: top;
  background-size: 100%;
  transition: all 0.3s;

  @media (min-height: 640px) {
    transform: translateY(-70px);
  }

  @media (min-height: 740px) {
    transform: translateY(-105px);
  }

  @media (min-height: 840px) {
    transform: translateY(-140px);
  }

  @media (min-height: 940px) {
    transform: translateY(-175px);
  }

  @media (min-height: 1040px) {
    transform: translateY(-210px);
  }
  @media (min-height: 1200px) {
    transform: translateY(-250px);
  }
}

.auth-form-logo {
  margin: -2rem;
  padding: 2rem 2rem 3rem 2rem;
  background-color: #dcf0dd;

  img {
    width: 100%;
  }
}

.bgradient {
  position: relative;
  background-color: #f0f0f0;
  background-image: linear-gradient(to bottom right, #4a738f 0%, #51dca5 100%);
  background-repeat: no-repeat;
}

.auth-form {
  width: 100%;
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0px 10px 10px -5px #777;
}

.b-title {
  color: #3f3859;
  text-align: center;
}

</style>
