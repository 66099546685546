import { clone } from './util';

const PATTERNS = {
  linear: {
    title: 'Linear',
    params: {
      a: {
        min: -100000,
        max: 100000,
        step: 0.1,
        defaultValue: 80,
      },
      b: {
        min: -100000,
        max: 100000,
        step: 1,
        defaultValue: 30,
      }
    },
    func(t, params) {
      return params.a * t + params.b;
    }
  },
  parabolic: {
    title: 'Quadratic',
    params: {
      a: {
        min: -100000,
        max: 100000,
        step: 0.1,
        defaultValue: 80,
      },
      b: {
        min: -100000,
        max: 100000,
        step: 1,
        defaultValue: 30,
      },
      c: {
        min: -100000,
        max: 100000,
        step: 1,
        defaultValue: 30,
      }
    },
    func(t, params) {
      return params.a * t * t + params.b * t + params.c;
    }
  },
  exponential: {
    title: 'Exponential',
    params: {
      amplitude: {
        min: 0,
        max: 50000,
        step: 0.1,
        defaultValue: 100,
      },
      tau: {
        min: -100,
        max: 100,
        step: 1,
        defaultValue: 10,
      },
      delta: {
        min: -100,
        max: 100,
        step: 0.5,
        defaultValue: 0,
      },
      c: {
        min: -100000,
        max: 100000,
        step: 1,
        defaultValue: 0
      },
    },
    func(t, params) {
      return params.amplitude * Math.exp(-params.tau * t + params.delta) + params.c;
    }
  },
  armonicWave: {
    title: 'Harmonic',
    params: {
      amplitude: {
        min: 0,
        max: 50000,
        step: 0.1,
        defaultValue: 60,
      },
      tau: {
        min: -100000,
        max: 100000,
        step: 1,
        defaultValue: 15,
      },
      omega: {
        min: -100000,
        max: 100000,
        step: 1,
        defaultValue: 15,
      },
      c: {
        min: -100000,
        max: 100000,
        step: 1,
        defaultValue: 50,
      }
    },
    func(t, params) {
      return params.amplitude * Math.exp(-params.tau * t) * Math.sin(2 * Math.PI * params.omega * t) + params.c;
    }
  },
  ln: {
    title: 'Logarithmic',
    params: {
      amplitude: {
        min: 0,
        max: 50000,
        step: 0.1,
        defaultValue: 15,
      },
      a: {
        min: 0,
        max: 100000,
        step: 1,
        defaultValue: 100,
      },
      c: {
        min: -100000,
        max: 100000,
        step: 1,
        defaultValue: 0,
      }
    },
    func(t, params) {
      return params.amplitude * Math.log(params.a * t) + params.c;
    }
  },
  /* log: {
    title: 'Logarithmic',
    params: {
      amplitude: {
        min: 0,
        max: 50000,
        step: 0.1,
        defaultValue: 15,
      },
      a: {
        min: 0,
        max: 100000,
        step: 1,
        defaultValue: 100,
      },
      b: {
        min: 0.001,
        max: 100,
        step: 1,
        defaultValue: 2,
      },
      c: {
        min: -100000,
        max: 100000,
        step: 1,
        defaultValue: 0,
      }
    },
    func(t, params) {
      return params.amplitude * Math.log(params.a * t) / Math.log(params.b) + params.c;
    }
  }, */
  freeDraw: {
    title: 'Free draw',
    params: [],
  }
};

function _patternCfg(pattern) {
  const cfg = PATTERNS[pattern];
  if (!cfg) {
    throw new Error(`Unknown patern index (${pattern})`);
  }
  const c = JSON.parse(JSON.stringify(cfg));
  c.func = cfg.func;
  return c;
}

export function patternsAsOptions() {
  const patterns = Object.keys(PATTERNS).map(key => {
    return {
      text: PATTERNS[key].title,
      value: key,
    };
  });
  return patterns;
}

export function patternDefaultParams(pattern) {
  if (pattern === 'freeDraw') {
    return [];
  }

  const cfg = _patternCfg(pattern).params;
  const values = {};
  Object.keys(cfg).forEach(key => {
    values[key] = cfg[key].defaultValue;
  });
  return values;
}

export function patternFuncCall(pattern, t, params) {
  return _patternCfg(pattern).func(t, params)
}

export function patternParamsConfig(pattern) {
  if (pattern === 'freeDraw') {
    return [];
  }

  return { ..._patternCfg(pattern).params };
}

export function cloneSignals(signals) {
  const _clone = clone(signals);
  _clone.forEach((c, i) => {
    c.func = signals[i].func;
  });
  return _clone;
}
