<template>
  <div class="func-params">
    <div>
      <span :class="functionCodeClass()">
        <slot name="func"></slot>
      </span>
    </div>
    <div>
      <slot name="params"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FuncParamsWrapper',

  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
  },

methods: {
    functionCodeClass() {
      return {
        "function-code-disabled": this.disabled,
        "function-code": !this.disabled
      };
    },
  }
}
</script>

<style lang="scss">
.func-params {
  display: flex;
  flex-direction: column;

  &,
  .v-input {
    font-size: 18px;
  }
}

.func-params > div:first-child {
  padding-bottom: 1.25rem;
}

.func-params > div:last-child > * {
  $padding: 1rem;

  padding-left: $padding;
  padding-right: $padding;

  &:first-child {
    margin-left: -$padding;
  }

  &:last-child {
    margin-right: -$padding;
  }
}

.function-code-disabled,
.function-code {
  display: inline-block;
  margin-right: 2rem;
  color: rgba(0, 0, 0, 0.87);
}

.function-code-disabled {
  color: rgba(0, 0, 0, 0.37);
}

</style>

