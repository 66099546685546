// Sidebar menu constants
export const VIREO_SIDEBAR_MENU_STATE = 'vibro_sidebar_menu_state';

// Wavesurfer constants
export const SURF_REG_ID = '_surf_reg_id_';
export const AUDIO_MAX_LEN = 5; // seconds
export const AUDIO_MIN_LEN = 0.025; // seconds

export const COLOR_WAVE = '#7b7b7b';
export const COLOR_PROGRESS = '#4a964d';
export const COLOR_SELECTED_REGION = '#95959555';