<template>
<div>
  <v-card class="mb-4" :disabled="loading" :loading="loading">
    <v-card-title class="mb-5 backgrounded-card-title">Multimodal feedback</v-card-title>
    <v-card-text class="d-flex align-baseline signal-toolbar">
      <div class="flex-grow-1">
        <v-text-field
          label="Pattern name"
          title="Pattern name"
          v-model="signal.title"
          @blur="registerUserAction"
        ></v-text-field>
      </div>
      <div class="d-flex">
        <v-btn icon @click="userActionsIndex += 1" :disabled="userActions.actions.length-1<=userActions.index"><v-icon>mdi-undo</v-icon></v-btn>
        <v-btn icon @click="userActionsIndex -= 1" :disabled="userActions.index<1"><v-icon>mdi-redo</v-icon></v-btn>
      </div>
      <div>
        <v-btn color="success" @click="signalSave" title="Save">
          <v-icon>mdi-content-save-outline</v-icon>
          <span class="d-none d-md-inline">Save</span>
        </v-btn>
      </div>
      <div v-if="signal.hash">
        <v-btn color="primary" @click="signalSaveAs" title="Save as a new pattern">
          <v-icon>mdi-content-save-edit-outline</v-icon>
          <span class="d-none d-md-inline">Save as</span>
        </v-btn>
      </div>
      <div>
        <v-btn class="white--text" color="teal" @click="onExport" :disabled="!signal.components.length" title="Export current pattern as JSON">
          <v-icon>mdi-file-export-outline</v-icon>
          <span class="d-none d-md-inline">Export</span>
        </v-btn>
      </div>
      <div v-if="signal.hash">
        <v-btn class="white--text" color="indigo darken-1" @click="generatingSignals=true" title="Generate patterns from model">
          <v-icon>mdi-wrench-outline</v-icon>
          <span class="d-none d-md-inline">Generate</span>
        </v-btn>
      </div>
      <div v-if="signal.hash && signal.components && signal.components.length">
        <v-btn color="error" @click="signalDelete" title="Delete this pattern">
          <v-icon>mdi-trash-can-outline</v-icon>
          <span class="d-none d-md-inline">Delete</span>
        </v-btn>
      </div>
    </v-card-text>


    <!-- <v-card-text class="pt-0 chart-settings">
      <PatternTracks
        v-model="signal.offsets"
        :vibroLength="signal.duration"
        :audioLength="signal.audioTrack.region.end - signal.audioTrack.region.start"
        @scroll="scrollTo"
      />
      <v-row
        no-gutters
        class=""
      >
        <v-col cols="12" class="d-flex justify-end">
          <v-switch v-model="waveMode" class="align-baseline">
            <template v-slot:prepend>
              <span class="text-no-wrap">Wave mode:</span>
            </template>
          </v-switch>
        </v-col>
      </v-row>
    </v-card-text>  -->
  </v-card>


  <v-card class="mb-4" :disabled="loading" :loading="loading" ref="audio">
    <v-card-title class="mb-5 backgrounded-card-title">Audio feedback</v-card-title>
    <v-card-text class="pt-0 chart-settings">
      <AudioTrack
        :value="signal.audioTrack"
        @input="updateAudioTrack"
        @error="(err) => $emit('error', err)"
      />
      <NumberSlider
        :value="signal.offsets.audio"
        :min="config.audioTrack.min"
        :max="config.audioTrack.max"
        :step="config.audioTrack.step"
        title="Audio offset"
        @blur="() => { /*registerUserAction*/ }"
        @input="(v) => { signal.offsets.audio = v; }"
      >
        <template v-slot:prepend>
          <span class="text-no-wrap">Audio offset</span>
        </template>
        <template v-slot:append>
          <NumberInput
            :value="signal.offsets.audio"
            :min="config.audioTrack.min"
            :max="config.audioTrack.max"
            :step="config.audioTrack.step"
            :defaultValue="config.audioTrack.defaultValue"
            title="Audio offset"
            @blur="() => { /*registerUserAction*/ }"
            @input="(v) => { signal.offsets.audio = v; }"
          >
            <template v-slot:append>s</template>
          </NumberInput>
        </template>
      </NumberSlider>
    </v-card-text>
  </v-card>


  <v-card class="mb-4" :disabled="loading" :loading="loading" ref="vibro">
    <v-card-title class="mb-5 backgrounded-card-title">Vibrotactile feedback</v-card-title>
    <v-card-text class="pb-0 pt-0">
      <v-row
        no-gutters
        class="chart-settings"
      >
        <v-col cols="12" class="d-flex align-center">
          <NumberSlider
            :value="signal.duration"
            :min="config.signalDuration.min"
            :max="config.signalDuration.max"
            :step="config.signalDuration.step"
            title="Total pattern duration"
            @blur="registerUserAction"
            @input="updateSignalDuration"
          >
            <template v-slot:prepend>
              <span class="text-no-wrap">Total duration</span>
            </template>
            <template v-slot:append>
              <NumberInput
                :value="signal.duration"
                :min="config.signalDuration.min"
                :max="config.signalDuration.max"
                :step="config.signalDuration.step"
                :defaultValue="config.signalDuration.defaultValue"
                title="Total pattern duration"
                @blur="registerUserAction"
                @input="updateSignalDuration"
              >
                <template v-slot:append>s</template>
              </NumberInput>
            </template>
          </NumberSlider>
        </v-col>
        <!-- <v-col cols="12" class="d-flex justify-end">
          <v-switch v-model="waveMode" class="align-baseline">
            <template v-slot:prepend>
              <span class="text-no-wrap">Wave mode:</span>
            </template>
          </v-switch>
        </v-col> -->
      </v-row>
    </v-card-text>

    <v-card-text class="pt-0">
      <v-row
        no-gutters
        class="chart-settings"
      >
        <!-- <v-col cols="12">
          <AudioTrack
            v-model="signal.audioTrack"
            @error="(err) => $emit('error', err)"
          />
        </v-col> -->

        <v-col cols="12">
          <div class="chart-cntx">
            <div class="axe-x-label">Intensity [%]</div>
            <div class="axe-y-label">Time [s]</div>
            <SignalsDisplay
              v-if="drawingSignal === null"
              :duration="signal.duration"
              :signals="displaySignals(false)"
              :stepsView="signal.stepsView.show ? signal.stepsView.params : null"
              :title="signal.title"
              :c_export="c_exportImage"
              @changeSignalsTime="onChangeSignalsTime"
              @contextmenu="openContextmenu"
            />
            <FreeDrawDisplay
              v-else
              :duration="signal.duration"
              :color="signal.components[drawingSignal].color"
              :points="displaySignalFreeDraw(drawingSignal)"
              :freeDrawRequest="freeDrawRequest"
              @freeDrawCompleted="onFreeDrawCompleted"
            />
            <ContextMenu
              v-if="drawingSignal === null && xcontextMenu.position"
              :items="xcontextMenu.items"
              :position="xcontextMenu.position && this.xcontextMenu.position.absolute"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text class="pt-0">
      <div class="d-flex justify-space-between align-center">
        <div>
          <AddSubsignalButton
            :patterns="formulasAsText"
            @onaddsubsignal="onAddSubsignal"
          />
        </div>

        <div class="d-flex justify-space-between align-center" style="min-width: 300px">
          <v-switch v-model="signal.stepsView.show" class="align-baseline" title="Enable approxiation by step">
            <template v-slot:prepend>
              <span class="text-no-wrap">Approximate by steps of:</span>
            </template>
          </v-switch>

          <div class="d-inline-block pr-4" style="width: 80px">
            <NumberInput
              v-model="signal.stepsView.params.step"
              :min="config.stepsView.min"
              :max="config.stepsView.max"
              :step="config.stepsView.step"
              :defaultValue="config.stepsView.defaultValue"
              :disabled="!signal.stepsView.show"
              title="Step duration"
            >
              <template v-slot:append>s</template>
            </NumberInput>
          </div>
          <ColorPicker
            v-model="signal.stepsView.params.color"
            :disabled="!signal.stepsView.show"
            title="Approximation color"
          />
        </div>
      </div>
    </v-card-text>
  </v-card>

  <div
    v-for="(c_signal, i) in signal.components"
    :key="i"
  >
    <SignalSettingsFreeDrawCard
      v-if="c_signal.type == 'freeDraw'"
      :signal="c_signal"
      :config="config"
      :duration="signal.duration"
      :disabled="loading"
      :loading="loading"
      :patternTypes="formulasAsText"
      :drawing="i === drawingSignal"
      @patternTypeChange="(type) => { onPatternTypeChange(i, type); }"
      @input="(s) => { onSignalChanged(i, s); }"
      @startDrawing="onStartDrawing(i)"
      @stopDrawing="onStopDrawing"
      @cancelDrawing="onCancelDrawing"
      @removeCard="onRemoveSubsignal(i)"
      @duplicateCard="onDuplicateCard(i)"
      @userAction="(a, ...args) => registerUserAction(a, i, ...args)"
    />
    <SignalSettingsCard
      v-else
      :value="signal.components[i]"
      :config="config"
      :duration="signal.duration"
      :disabled="loading"
      :loading="loading"
      :patternTypes="formulasAsText"
      @input="(s) => { onSignalChanged(i, s); }"
      @userAction="(a, ...args) => registerUserAction(a, i, ...args)"
      @patternTypeChange="(type) => { onPatternTypeChange(i, type); }"
      @removeCard="onRemoveSubsignal(i)"
      @duplicateCard="onDuplicateCard(i)"
    />
  </div>

  <SignalSettingsDialog
    v-if="xcontextMenu.activeSignal !== false && xcontextMenu.dialog"
    :value="signal.components[xcontextMenu.activeSignal]"
    :duration="signal.duration"
    :loading="false"
    :patternTypes="formulasAsText"
    :config="config"
    :patternParamsByType="getPatternParams.bind(this, xcontextMenu.activeSignal)"
    @input="saveSignalSettingsDialog"
    @cancel="xcontextMenu.dialog=false"
    @startDrawing="startDrawingFromSettingsDialog"
  />
  <SignalGeneratorBoard
    v-model="generatingSignals"
    :signal="signal"
    @close="generatingSignals=false"
    @signalAdd="signalAdd"
  />

</div>

</template>

<script>
import NumberSlider from '../base/NumberSlider.vue';
import SignalsDisplay from './SignalsDisplay.vue';
import FreeDrawDisplay from './FreeDrawDisplay.vue';
import SignalSettingsCard from './SignalSettingsCard.vue';
import SignalSettingsFreeDrawCard from './SignalSettingsFreeDrawCard.vue';
import ChartPlayer from './ChartPlayer.vue';
import AudioTrack from './AudioTrack.vue';
import PatternTracks from './pattern-track/PatternTracks.vue';
import NumberInput from '../base/NumberInput.vue';
import ColorPicker from '../base/ColorPicker.vue';
import AddSubsignalButton from './AddSubsignalButton.vue';
import ContextMenu from './ContextMenu.vue';
import SignalSettingsDialog from './SignalSettingsDialog.vue';
import SignalGeneratorBoard from './SignalGeneratorBoard.vue';

import {
  getRandomColor,
  clone,
  checkAudioFields,
} from '../../util/util';

import {
  patternDefaultParams,
  patternsAsOptions,
} from '../../util/signal-patterns';

import { signalFuncFactory } from '../../util/signal-data/factory';

import { signalOutput } from '../../util/func';

import {
  downloadFile,
  signalFilename,
} from '../../util/files';

const HIGHLIGHT_CLASS = 'block-hightlight';

export default {
  name: 'MultipleSignalApp',

  components: {
    NumberSlider,
    NumberInput,
    ChartPlayer,
    AudioTrack,
    PatternTracks,
    SignalsDisplay,
    FreeDrawDisplay,
    SignalSettingsCard,
    SignalSettingsFreeDrawCard,
    ColorPicker,
    AddSubsignalButton,
    ContextMenu,
    SignalSettingsDialog,
    SignalGeneratorBoard,
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
    },

    config: {
      type: Object,
      required: true,
    },

    signalData: {
      type: Object,
      required: true,
    },

    // userActionsIndex: {
    //   type: Number,
    //   required: false,
    //   default: 0,
    // },
  },

  computed: {
    formulasAsText() {
      return patternsAsOptions();
    },

    clickedOnAmodel() {
      if (this.xcontextMenu.position) {
        const signalIndex = this.modelIndexInFocus();
        return signalIndex === false;
      }
      return true;
    },

    clickedOnAmodel() {
      if (this.xcontextMenu.position) {
        const signalIndex = this.modelIndexInFocus();
        return signalIndex === false;
      }
      return true;
    },
  },

  data() {
    const self = this;
    self.createPointFunc(self.signalData);
    self.signalComponentsCache = new Array(self.signalData.components.length).fill(null);

    const signal = clone(self.signalData);
    // @TODO: audioTrack field should exist on signal already
    checkAudioFields(signal);

    return {
      generatingSignals: false,
      drawingSignal: null,
      freeDrawRequest: 0,
      signal: signal,
      c_exportImage: 0,
      xcontextMenu: {
        items: [
          {
            text: "Settings",
            icon: 'cog-outline',
            disabledFn() {
              return self.clickedOnAmodel;
            },
            disabled: true,
            handler() {
              self.xcontextMenu.position = false;
              self.xcontextMenu.dialog = true;
            }
          }, {
            text: "Duplicate",
            icon: "content-copy",
            disabledFn() {
              return self.clickedOnAmodel;
            },
            disabled: true,
            handler() {
              const signalIndex = self.modelIndexInFocus();
              if (signalIndex !== false) {
                self.onDuplicateCard(signalIndex);
              }
              self.xcontextMenu.position = false;
            }
          }, {
            text: "Export pattern",
            icon: 'application-export',
            disabledFn() {
              return self.signal.components.length === 0;
            },
            disabled: true,
            handler() {
              self.onExport();
              self.xcontextMenu.position = false;
            }
          }, {
            text: "Export image",
            icon: 'image',
            handler() {
              self.onExportImage();
              self.xcontextMenu.position = false;
            }
          }, {
            text: "Delete component",
            icon: 'delete',
            handler() {
              if (confirm(`Are you sure you want to delete this component?`)) {
                const signalIndex = self.modelIndexInFocus();
                if (signalIndex !== false) {
                  self.onRemoveSubsignal(signalIndex, 'chart');
                }
              }
              self.xcontextMenu.position = false;
            }
          },
        ],
        position: false,
        activeSignal: false,
        dialog: false,
      },
      userActions: {
        actions: [ JSON.stringify(self.signalData) ],
        index: 0,
      },
      userActionsIndex: 0,
    }
  },

  watch: {
    signalData(signal) {
      this.createPointFunc(signal);
      this.signal = clone(signal);

      // @TODO: audioTrack field should exist on signal already
      checkAudioFields(this.signal);

      this.drawingSignal = null;
      this.userActions.actions = [];
      this.userActions.index = 0;
      this.$emit('onUserAction', 0);
    },

    userActionsIndex(n) {
      if (this.userActions.actions[n]) {
        const signal = JSON.parse(this.userActions.actions[n]);
        this.createPointFunc(signal);
        this.signal = signal;
        this.drawingSignal = null;

        this.userActions.index = n;
      }
    },
  },

  methods: {
    signalAdd(signal) {
      this.$emit('addSignal', signal);
    },

    modelIndexInFocus() {
      const self = this;

      // @TODO: finding signalindex is copy-paste from open settings item
      //  to create a function which will do this job and call it in all necesary functions
      let signalIndex  = false;
      if (self.xcontextMenu.position) {
        const position = self.xcontextMenu.position.relative;
        const datasets = position.visibleElementsAt();
        for (let i = 0; i < datasets.length; i++) {
          const di = datasets[i];
          const y = self.pointFunc[di]._fn(position.x - self.signal.components[di].time);
          if (position.y <= y) {
            signalIndex = di;
            break;
          }
        }
      }

      return signalIndex;
    },

    checkContextMenuItems() {
      this.xcontextMenu.items.forEach(item => {
        if (item.disabledFn) {
          item.disabled = item.disabledFn();
        }
      });
    },

    displaySignals(all) {
      return this.pointFunc.map((sp, i) => {
        const component = this.signal.components[i];
        return {
          func: sp._fn.bind(sp),
          data: all || component.visible ? sp.points : [],
          color: component.color,
          time: component.time,
          duration: component.duration,
        };
      });
    },

    createPointFunc(signal) {
      this.pointFunc = signal.components.map(s => {
        return signalFuncFactory(s);
      });
    },

    displaySignalFreeDraw(index) {
      const { time, params } = this.signal.components[index];
      return params.map(p => {
        return {
          x: p.x + time,
          y: p.y,
        };
      });
    },

    keepSignalCompoentParams(index) {
      const cache = this.signalComponentsCache;
      if (!cache[index]) {
        cache[index] = {};
      }

      const c_signal = this.signal.components[index];
      cache[index][c_signal.type] = clone(c_signal.params);
    },

    getPatternParams(index, type) {
      const c_signal = { ...this.signal.components[index] };
      const cache = this.signalComponentsCache[index];
      c_signal.type = type;
      if (type == 'freeDraw') {
        const pf_time = this.pointFunc[index]._time[0];
        if (cache[type]) {
          c_signal.params = cache[type];
          delete cache[type];
        } else {
          c_signal.params = this.pointFunc[index].points.map(p => {
            return {
              x: p.x - pf_time,
              y: p.y,
            };
          });
        }
      } else {
        c_signal.params = patternDefaultParams(type);
        if (cache[type]) {
          c_signal.params = cache[type];
          delete cache[type];
        }
      }
      return c_signal;
    },

    onDuplicateCard(index) {
      if (this.signal.components[index]) {
        const extended = this.signal.components.map(c => clone(c));
        const duplicate = clone(extended[index]);
        this.pointFunc.splice(index, 0, signalFuncFactory(duplicate));
        extended.splice(index, 0, duplicate);
        this.signal.components = extended;
        this.registerUserAction();
      }
    },

    onPatternTypeChange(index, type) {
      this.keepSignalCompoentParams(index);
      const c_signal = this.getPatternParams(index, type);
      this.onSignalChanged(index, c_signal);
      this.registerUserAction();
    },

    onSignalChanged(index, c_signal) {
      /* if (this.signal.components[index].type == c_signal.type) {
        const signalData = this.pointFunc[index];
        signalData.params = c_signal.params;
        signalData.time = [c_signal.time, c_signal.time + c_signal.duration];
      } else {
        this.pointFunc[index] = signalFuncFactory(c_signal);
      } */
      // @FIXME: use the if above instead of recreating pointFunc everytime;
      this.pointFunc[index] = signalFuncFactory(c_signal);
      this.$set(this.signal.components, index, c_signal);
    },

    onStartDrawing(index) {
      this.drawingSignal = index;
    },

    startDrawingFromSettingsDialog() {
      this.xcontextMenu.dialog = false;
      this.drawingSignal = this.xcontextMenu.activeSignal;
    },

    onStopDrawing() {
      this.freeDrawRequest++;
    },

    onCancelDrawing() {
      this.drawingSignal = null;
    },

    onFreeDrawCompleted(points) {
      if (this.drawingSignal !== null) {
        const c_signal = this.signal.components[this.drawingSignal];
        if (points.length) {
          c_signal.time = points[0].x;
          c_signal.duration = points[points.length - 1].x - points[0].x;
          c_signal.params = points.map(p => {
            return {
              x: p.x - c_signal.time,
              y: p.y,
            };
          });
        } else {
          c_signal.time = 0;
          c_signal.params = [];
        }

        const ds = this.pointFunc[this.drawingSignal];
        ds.time = [c_signal.time, c_signal.time + c_signal.duration];
        ds.params = {
          points: c_signal.params
        };

        this.drawingSignal = null;

        this.registerUserAction();
      }
    },

    onChangeSignalsTime(signalsTime) {
      for (let i = 0; i < signalsTime.length; i++) {
        const c_signal = this.signal.components[i];
        c_signal.time = signalsTime[i];
        this.pointFunc[i].time = [c_signal.time, c_signal.time + c_signal.duration];
      }
      this.registerUserAction();
    },

    signalSave() {
      this.$emit('onSave', this.signal);
    },

    signalSaveAs() {
      const signal = clone(this.signal);
      delete signal.hash;
      this.$emit('onSave', signal);
    },

    signalDelete() {
      this.$emit('onDelete', this.signal.hash);
    },

    onAddSubsignal(type) {
      const duration = this.signal.duration / 2;
      const newSignal = {
        type,
        duration,
        time: 0,
        visible: true,
        minimized: false,
        color: getRandomColor(80),
        params: patternDefaultParams(type)
      }
      ;
      this.signalComponentsCache.unshift(null);
      this.signal.components.unshift(newSignal);
      this.pointFunc.unshift(signalFuncFactory(newSignal));
      if (type == 'freeDraw') {
        this.drawingSignal = 0;
      }
      this.registerUserAction();
    },

    onRemoveSubsignal(index) {
      if (this.drawingSignal == index) {
        this.drawingSignal = null;
      } else if (this.drawingSignal > index) {
        this.drawingSignal--;
      }
      this.signal.components = this.signal.components.filter((_, i) => index !== i);
      this.signalComponentsCache = this.signalComponentsCache.filter((_, i) => index !== i);
      this.pointFunc = this.pointFunc.filter((s, i) => index !== i);
      this.registerUserAction();
    },

    onExport() {
      const {duration, stepsView} = this.signal;
      const output = signalOutput(this.displaySignals(true), duration, stepsView.params);
      downloadFile(signalFilename(this.signal.title), JSON.stringify(output));
    },

    onExportImage() {
      this.c_exportImage++;
    },

    openContextmenu(chart, absolute, relative) {
      this.xcontextMenu.position = {
        absolute: {
          left: absolute.x,
          top: absolute.y,
        },
        relative: relative,
      };
      this.checkContextMenuItems();
    },

    closeContextmenu() {
      this.xcontextMenu.position = null;
    },

    saveSignalSettingsDialog(signal) {
      this.onSignalChanged(this.xcontextMenu.activeSignal, signal);
      this.xcontextMenu.dialog = false;
      this.xcontextMenu.activeSignal = false;
      this.registerUserAction();
    },

    updateSignalDuration(duration) {
      this.signal.duration = duration;
      this.adjustFeedbackOffset();
    },

    updateAudioTrack(newTrack) {
      this.signal.audioTrack = newTrack;
      this.adjustFeedbackOffset();
    },

    adjustFeedbackOffset() {
      const offsets = {
        audio: this.signal.offsets.audio,
        vibro: this.signal.offsets.vibro,
      };
      if (this.signal.audioTrack.content) {
        // Check offsets
        const audioMaxDuration = this.signal.audioTrack.region.end - this.signal.audioTrack.region.start;
        if (offsets.vibro > audioMaxDuration) {
          offsets.vibro = audioMaxDuration;
        }
        if (offsets.audio > this.signal.duration) {
          offsets.audio = this.signal.duration;
        }
      } else {
        offsets.vibro = 0;
        offsets.audio = 0;
      }
      // TODO: offset is changed in all cases, although there are some cases
      // when it's not necesary to modify it
      this.signal.offsets = offsets;
    },

    scrollTo(blockName) {
      const $block = this.$refs[blockName] && this.$refs[blockName].$el || false;
      if (!$block) {
        return;
      }
      const blockTop = $block.offsetTop;
      if (blockTop + $block.offsetHeight + 100 > window.innerHeight) {
        window.scrollTo(0, $block.offsetTop - 60);
        setTimeout(() => {
          this.blockHightlight($block);
        }, 500);
      } else {
        this.blockHightlight($block);
      }
    },

    blockHightlight($block) {
      $block.classList.add(HIGHLIGHT_CLASS);
      setTimeout(() => {
        $block.classList.remove(HIGHLIGHT_CLASS);
      }, 1000);
    },

    // type:
    //  editComponentFields - index, fields: Object
    //  addComponent - index
    //  removeComponent - index, component: Object
    //  changeSignalFields - fields: Object
    registerUserAction(action, ...data) {
      if (this.userActions.index) {
        this.userActions.actions.splice(0, this.userActions.index);
      }
      // this.userActions.index = 0;
      // this.userActions.actions.unshift({action, data});
      // this.$emit('onUserAction', this.userActions.actions.length);
      // @TODO: register only diffs
      this.userActions.index = 0;
      this.userActions.actions.unshift(JSON.stringify(this.signal));
      this.$emit('onUserAction', this.userActions.actions.length);
    },

    keyboardUndo(event) {
      if (event.ctrlKey) {
        if (event.shiftKey && event.code == 'KeyZ' || event.code == 'KeyY') {
          if (this.userActions.index > 0) {
            this.userActionsIndex--;
          }
        } else if (event.code == 'KeyZ') {
          if (this.userActions.index < this.userActions.actions.length - 1) {
            this.userActionsIndex++;
          }
        }
      }
    }
  },

  mounted() {
    document.addEventListener('click', this.closeContextmenu);
    document.addEventListener('keypress', this.keyboardUndo);
  },

  beforeUnmount() {
    document.removeEventListener('click', this.closeContextmenu);
    document.removeEventListener('keypress', this.keyboardUndo);
  },
};

</script>

<style lang="scss">
@use "sass:math";

$ff_monospace: courier, monospace;

.backgrounded-card-title {
  /* @TODO: here we've used sidebar color + transparency
  if someday, scss will use variables everywhere, you can change it too. */
  background-color: #3c4b6442;
}

.signal-toolbar {
  display: flex;
  align-items: baseline;

  > div {
    $space: 0.25rem;
    padding: 0 $space;

    &:first-child {
      margin-left: -$space;
    }

    &:last-child {
      margin-right: -$space;
    }
  }
}

.chart-settings {
  font-family: $ff_monospace;

  .v-text-field {
    padding-top: 0;
    margin-top: 0;
  }

  input[type="number"] {
    width: 80px;
    text-align: center;
  }

  .formula-config {
    flex-grow: 1;

    label {
      display: inline-block;
      margin: 0 0.5rem;
    }
  }
}

.chart-cntx {
  $padding: 20px;
  $labelHeight: $padding;
  $labelWidth: 120px;

  position: relative;
  max-width: 100%;
  min-height: 400px;
  max-height: 400px;
  height: 60vh;
  margin:  0 auto 20px;
  background: #fff;
  padding: math.div($padding, 2) 0 math.div($padding, 2) $padding;

  .axe-x-label,
  .axe-y-label {
    font-family: $ff_monospace;
    font-size: 14px;
    font-weight: bold;
  }

  .axe-x-label {
    display: inline-block;
    position: absolute;
    text-align: center;
    vertical-align: middle;
    height: $padding;
    line-height: $padding;
    width: $labelWidth;
    top: calc(50% - #{math.div($labelHeight, 2)});

    /* 15px - we'll shring a little bit from chart; we have a little on left side on the chart*/
    left: -1 * math.div($labelWidth - $padding - 15px, 2);

    transform: rotate(-90deg);
  }

  .axe-y-label {
    position: absolute;
    width: $labelWidth;
    height: $labelHeight;
    text-align: center;
    vertical-align: middle;
    bottom: -30px;
    left: calc(50% - #{math.div($labelWidth, 2)});
  }
}

.player-container {
  position: absolute;
  top: 18px;
  right: 12px;
  bottom: 35px;
  left: 35px;
  background-color: #ffffff55;
}

.control {
  text-align: center;
  padding: 1rem;
}

.csignal-container {
  margin: -0.375rem -0.75rem;
  padding: 0.375rem 0.75rem;

  input {
    display: none;
  }
}

.text-upper input {
  text-transform: uppercase;
}

.block-hightlight {
  animation-name: blinkblock !important;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
}

@keyframes blinkblock {
  0% {
    background: #ffffff;
  }
  50% {
    background: #ff88b1;
  }
  100% {
    background: #ffffff;
  }
}
</style>
