<template>
  <Player
    :duration="duration"
    :isPlaying="isPlaying"
    :signals="signals()"
    @end="onEnd"
  />
</template>

<script>
import Player from './Player.vue';
import { functionSteps } from '../../util/func';

export default {
  name: 'ChartPlayer',
  components: {
    Player,
  },
  props: {
    duration: {
      type: Number,
      required: true,
    },
    isPlaying: {
      type: Boolean,
      required: true,
    },
    func: {
      type: Function,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
  methods: {
    signals() {
      const signals = [];
      const data = functionSteps(this.func, [0, this.duration], this.step);
      for (let i = 0; i < data.length; i += 2) {
        signals.push({
          i: data[i].y,
          t: data[i + 1].x - data[i].x
        });
      }
      return signals;
    },
    onEnd(...args) {
      this.$emit('end', ...args);
    }
  }
}
</script>
