export class BaseFunction {

  constructor(params, time, limits) {
    this.initProps();

    this.params = params;
    this.time = time;
    this.limits = limits;

    this._points = null;
  }

  set limits(l) {
    if (!(l instanceof Array) || l.length !== 2) {
      throw new Error('Limits should be an array with length=2');
    }
    if (l[0] >= l[1]) {
      throw new Error('Min limit should be lower that Max limit');
    }
    this._limits = l;
    this._points = null;
  }

  set time(t) {
    if (!(t instanceof Array) || t.length !== 2) {
      throw new Error('Time interval should be an array with length=2');
    }
    if (t[0] >= t[1]) {
      throw new Error('Time.from should be lower that Time.to');
    }
    this._time = t;
    this._points = null;
  }

  set params(p) {
    if (typeof p !== 'object') {
      throw new Error('Params should be an object');
    }
    Object.keys(this._params).forEach(key => {
      if (p[key] === undefined && this._params[key] === undefined) {
        this._params[key] = 0;
      } else if (p[key] !== undefined) {
        let checked = false;
        let e;
        // let ch = `_setParam_${key}`;
        // try {
        //   this[ch](p[key]);
        //   checked = true;
        // } catch (err) {
        //   if (!/is not a function$/.test(err.message)) {
        //     checked = true;
        //     e = err;
        //   }
        // }
        // if (e) {
        //   throw e;
        // }
        if (!checked) {
          if (key !== 'points' && typeof p[key] !== 'number') {
            throw new Error(`Param '${key}' should be an number`);
          } else {
            this._params[key] = p[key];
          }
        }
      }
    });
  }

  get points() {
    if (!this._points) {
      this._points = this._calcPoints();
    }
    return this._points;
  }

  _calcPoints() {
    return [];
  }

  initProps() {
    this._params = {};
  }
}
