<template>
  <div class="player">
    <div
      :class="{indicator: true, play: is_playing}"
      :style="{animationDuration: `${duration}s`}"
    ></div>
  </div>
</template>


<script>

import SiganlsPlayer from '../../util/signals-player';
const player = new SiganlsPlayer();

export default {
  name: 'Player',
  props: {
    duration: {
      type: Number,
      required: true,
    },
    signals: {
      type: Array,
      requried: true
    },
    isPlaying: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      is_playing: false
    };
  },
  watch: {
    isPlaying(nval) {
      this.is_playing = nval;
    },
    is_playing(nval, oval) {
      if (nval) {
        player.play(this.signals, () => {
        });
        setTimeout(() => {
          this.is_playing = false;
          this.$emit('end');
        }, this.duration * 1e3)
      }
    }
  },
  mounted() {
    this.is_playing = this.isPlaying || true;
  }
};

</script>


<style lang="scss" scoped>
.player {
  height: 100%;
  width: 100%;
}

.indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 2px;
  background-color: #ff9800;

  &.play {
    animation: play 1s linear;
  }
}

@keyframes play {
  0% {
    left: 0;
  }

  100% {
    left: 100%;
  }
}
</style>
