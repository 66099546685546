<template>
<div>
  <v-text-field
    v-model="email"
    label="Email"
    hide-details="auto"
    :loading="loading"
    @keypress="checkEnter"
  ></v-text-field>
  <v-text-field
    v-model="password"
    label="Passwrod"
    type="password"
    hide-details="auto"
    :loading="loading"
    @keypress="checkEnter"
  ></v-text-field>
  <div class="bbox">
    <v-btn
      block
      color="success"
      :disabled="loading || !email || !password"
      @click="login"
    >Login</v-btn>
  </div>

  <div class="d-flex flex-column bbox">
    <a href="#loginasguest" class="small" @click="loginAsGuest">Login as a guest</a>
    <!-- <router-link to="/register" class="small">Register an account</router-link>
    <router-link to="/recover" class="small">Forgot password?</router-link>-->
  </div>

</div>
</template>


<script>

export default {
  name: 'LoginForm',

  props: {
    loading: Boolean,
  },

  data() {
    return {
      email: '',
      password: '',
    };
  },

  methods: {
    login() {
      if (this.email && this.password) {
        this.$emit('login', this.email, this.password);
      }
    },

    loginAsGuest() {
      this.$emit('loginasguest');
    },

    checkEnter(event) {
      if (event.code == 'Enter') {
        this.login();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bbox {
  padding-top: 1.5rem;
}

.bbox a {
  text-decoration: none;
}
</style>
