<template>
<div class="pa-4">
  <v-row>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        v-model="filters.emotionTag"
        :items="emotionTags"
        :disabled="loading"
        filled
        label="Emotion tag"
      ></v-select>
    </v-col>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        v-model="filters.usageExampleTag"
        :items="usageExampleTags"
        :disabled="loading"
        filled
        label="Usage example"
      ></v-select>
    </v-col>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        v-model="filters.metaphorTag"
        :items="metaphorTags"
        :disabled="loading"
        filled
        label="Metaphor"
      ></v-select>
    </v-col>
    <v-col class="d-flex pt-6" cols="12" sm="3">
      <v-btn
        color="primary"
        :disabled="!hasFilters || loading"
        @click="applyFilters"
      >Find</v-btn>
    </v-col>
  </v-row>

  <div class="row">
    <div v-for="(signal, i) in signals" :key="i" class="col-md-4 d-flex">
      <div class="d-flex flex-column">
        <v-btn
          icon
          color="green"
          :disabled="loading"
          @click="onSaveSignal(i)"
        ><v-icon>mdi-content-save-outline</v-icon></v-btn>
      </div>
      <SignalDisplay
        class="flex-grow-1"
        :components="[ signal ]"
        :duration="signal.duration"
      />
    </div>
  </div>
</div>
</template>


<script>
import SignalDisplay from './SignalDisplay';
import vibviz from '../../../vibviz.json';

import { clone } from '../../../util/util';
import api from '../../../util/api';

window.AudioContext = window.AudioContext || window.webkitAudioContext;
const audioContext = new AudioContext();

function getVibVibResourcePoints(name) {
  let blockSize = 500;
  return fetch(`/viblib/${name}.wav`)
  .then(response => response.arrayBuffer())
  .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
  .then(audioBuffer => {
    const rawData = audioBuffer.getChannelData(0);
    const blocks = Math.round(rawData.length / blockSize);
    blockSize = Math.floor(rawData.length / blocks);

    let t = 0;
    let dt = audioBuffer.duration / blocks;

    const res = new Array(blocks);
    let max = 0;
    for (let i = 0; i < blocks; i++) {
      let sum = 0;
      let j = 0;
      for (j = 0; j < blockSize; j++) {
        sum += rawData[i * blockSize + j];
      }
      const y = Math.abs(100 * sum / blockSize);
      if (y > max) {
        max = y;
      }
      res[i] = {
        x: t,
        y: y,
      };
      t += dt;
    }
    res.forEach(item => {
      item.y = Math.round(10000 * (item.y / max)) / 100;
    });
    return {
      color: '#34C533AE',
      duration: Math.ceil(audioBuffer.duration),
      minimized: false,
      params: res,
      time: 0,
      type: 'freeDraw',
      visible: true,
    }
  })
  .catch(err => {
    throw new Error(`${name} - ${err.message}`)
  });
}

export default {
  name: 'SignalVibiz',

  components: {
    SignalDisplay,
  },

  props: {
    signal: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      vibviz: vibviz,
      filters: {
        emotionTags: '',
        usageExampleTag: '',
        metaphorTag: '',
      },
      signals: [],
    };
  },

  computed: {
    emotionTags() {
      return this.getUnitTag(this.vibviz, 'emotionTags');
    },
    usageExampleTags() {
      return this.getUnitTag(this.vibviz, 'usageExampleTags');
    },
    metaphorTags() {
      return this.getUnitTag(this.vibviz, 'metaphorTags');
    },

    hasFilters() {
      const { filters } = this;
      return filters.emotionTag || filters.usageExampleTag || filters.metaphorTag;
    },
  },

  methods: {
    getUnitTag(list, tag) {
      const set = new Set();
      list.forEach(item => {
        if (!item[tag]) {
          return true;
        }
        for (let i = 0; i < item[tag].length; i++) {
          set.add(item[tag][i]);
        }
      });
      const arr = Array.from(set);
      arr.sort();
      return arr;
    },

    applyFilters() {
      this.signals = [];
      if (!this.hasFilters) {
        return;
      }
      let res = this.vibviz;
      if (this.filters.emotionTag) {
        res = res.filter(item => item.emotionTags && item.emotionTags.includes(this.filters.emotionTag));
      }
      if (this.filters.usageExampleTag) {
        res = res.filter(item => item.usageExampleTags && item.usageExampleTags.includes(this.filters.usageExampleTag));
      }
      if (this.filters.metaphorTag) {
        res = res.filter(item => item.metaphorTags && item.metaphorTags.includes(this.filters.metaphorTag));
      }

      if (res.length) {
        Promise.all(res.map(item => getVibVibResourcePoints(item.resource))).then((signals) => {
          this.signals = signals;
        }).catch((err) => {
          alert('Error');
          console.log(err);
        });
      } else {
        console.log("No search results");
      }
    },

    onSaveSignal(index) {
      // @TODO: this method is a copy from SignalGenerator
      // to move it in SignalGeneratorBoard and use through $emit
      if (!this.signals[index]) {
        // @TODO: react in a more nice manner
        alert("No signal");
        return;
      }
      const signal = clone(this.signal);
      signal.components = [ clone(this.signals[index]) ];
      this.loading = true;
      const now = new Date();
      signal.title = signal.title.replace(/\s*\d{1,2}:\d{2}(:\d{1,2})?\s*/, '');
      signal.title += ` ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
      signal.hash = '';
      api
      .saveSignal(signal)
      .then(({ signal }) => {
        this.$emit('signalAdd', signal);
        this.loading = false;
      })
      .catch((err) => {
        alert(err.message || 'Error');
        console.log(err);
        this.loading = false;
      })
      ;
    },
  },
};

</script>
