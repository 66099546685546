import axios from 'axios';

import {
  loginAsGuest,
  getGuestUser,
  guestLogout,
  guestDeleteSignal,
  guestGetSignal,
  guestGetSignalsShort,
  guestSaveSignal,
} from './api-guest';

async function saveSignal(signal) {
  const guest = await getGuestUser();
  if (guest) {
    return await guestSaveSignal(signal);
  }
  let response;
  if (signal.hash) {
    response = await axios.put(`/api/signal/${signal.hash}`, { signal });
  } else {
    response = await axios.post(`/api/signal`, { signal });
  }
  return response && response.data;
}

async function deleteSignal(hash) {
  const guest = await getGuestUser();
  if (guest) {
    return await guestDeleteSignal(hash);
  }
  const response = await axios.delete(`/api/signal/${hash}`);
  return response && response.data;
}

async function getSignalsShort() {
  const guest = await getGuestUser();
  if (guest) {
    return await guestGetSignalsShort();
  }
  const response = await axios.get(`/api/signal/short`);
  return response && response.data;
}

async function getSignal(hash) {
  const guest = await getGuestUser();
  if (hash !== 'new' && guest) {
    return await guestGetSignal(hash);
  }
  const response = await axios.get(`/api/signal/${hash}`);
  return response && response.data;
}


// user/auth actions

async function register(name, email, password) {
  const response = await axios.post(`/api/auth/signup`, {
    name, email, password,
  });
  return response && response.data;
}

async function getUser() {
  const response = await axios.get(`/api/auth/user`);
  return response && response.data;
}

async function login(email, password) {
  const response = await axios
  .post('/api/auth/signin', {
    email, password
  });
  return response && response.data;
}

async function logout() {
  const guest = await getGuestUser();
  if (guest) {
    return await guestLogout();
  }
  const response = await axios.post(`/api/auth/logout`);
  return response && response.data;
}

async function registerRecover(email) {
  const response = await axios.post(`/api/auth/register-recovery`, { email });
  return response && response.data;
}

async function recover(hash, password) {
  const response = await axios.post(`/api/auth/recovery/${hash}`, { password });
  return response && response.data;
}


// # collections actions
async function getCollections() {
  const guest = await getGuestUser();
  if (guest) {
    return [];
    // throw new Error(`Guest cannot perform acctions related to collections`);
  }
  const response = await axios.get(`/api/collection/list`);
  return response && response.data;
}

async function addCollection(title) {
  const guest = await getGuestUser();
  if (guest) {
    throw new Error(`Guest cannot perform acctions related to collections`);
  }
  const response = await axios.post(`/api/collection`, { title });
  return response && response.data;
}

async function updateCollection(code, collection) {
  const guest = await getGuestUser();
  if (guest) {
    throw new Error(`Guest cannot perform acctions related to collections`);
  }
  if (!collection.code || collection.code == code) {
    delete collection.code;
  }
  const response = await axios.put(`/api/collection/${code}`, collection);
  return response && response.data;
}

async function deleteCollection(code) {
  const guest = await getGuestUser();
  if (guest) {
    throw new Error(`Guest cannot perform acctions related to collections`);
  }
  const response = await axios.delete(`/api/collection/${code}`);
  return response && response.data;
}

async function getCollectionSignals(code) {
  const guest = await getGuestUser();
  if (guest) {
    throw new Error(`Guest cannot perform acctions related to collections`);
  }
  const response = await axios.get(`/api/collection/${code}/signals`);
  return response && response.data;
}

async function importPatterns(code) {
  const guest = await getGuestUser();
  if (guest) {
    // @TODO: think over the posibility of importing signals by a guest
    // from a real user;
    throw new Error(`Guest cannot perform acctions related to collections`);
  }
  const response = await axios.post(`/api/collection/${code}/import`);
  return response && response.data;
}

async function gerFreshAccessCode() {
  const guest = await getGuestUser();
  if (guest) {
    throw new Error(`Guest cannot perform acctions related to collections`);
  }
  const response = await axios.post(`/api/collection/freshcode`);
  return response && response.data;
}

async function getSignalsForExport(signals) {
  const guest = await getGuestUser();
  if (guest) {
    throw new Error(`Guest cannot perform acctions related to collections`);
  }
  const response = await axios.post(`api/signal/exportsignals`, { signals });
  return response && response.data;
}

export default {
  saveSignal,
  deleteSignal,
  getSignalsShort,
  getSignal,

  register,
  getUser,
  login,
  logout,
  registerRecover,
  recover,

  addCollection,
  getCollections,
  updateCollection,
  deleteCollection,

  getCollectionSignals,
  importPatterns,

  gerFreshAccessCode,
  getSignalsForExport,

  getGuestUser,
  loginAsGuest,
};
