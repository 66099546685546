function getDatasetElement(chartInstance, datasetIndex, elementIndex) {
  const e = chartInstance.getDatasetMeta(datasetIndex);
  return e.data[elementIndex];
}

function chartCoordinateX(x, chartInstance, datasetIndex, elementIndex) {
  const element = getDatasetElement(chartInstance, datasetIndex, elementIndex);
  const scaleName = element._xScale.id;
  const chartScale = chartInstance.scales[scaleName];
  return chartScale.getValueForPixel(x);
}

function chartCoordinateY(y, chartInstance, datasetIndex, elementIndex) {
  const element = getDatasetElement(chartInstance, datasetIndex, elementIndex);
  const scaleName = '_yScale';
  const chartScale = chartInstance.scales[ element[scaleName].id ];
  return chartScale.getValueForPixel(y);
}

function visibleElementsAt(x, chartInstance) {
  const metas = chartInstance._getSortedVisibleDatasetMetas();
  const datasetsHere = [];
  for (let i = 0; i < metas.length; i++) {
    const { data } = metas[i];
    const len = data.length
    if (len > 1) {
      const firstx = data[0]._view.x;
      const lastx = data[len - 1]._view.x;
      if (firstx < x && x < lastx) {
        datasetsHere.push(i);
      }
    }
  }
  return datasetsHere;
}

export function chartCoordinates(chartInstance, point, datasetIndex, elementIndex) {
  try {
    const x = chartCoordinateX(point.x, chartInstance, datasetIndex, elementIndex);
    const y = chartCoordinateY(point.y, chartInstance, datasetIndex, elementIndex);
    return {
      x, y,
      visibleElementsAt: visibleElementsAt.bind(null, point.x, chartInstance)
    };
  } catch (err) {
    console.error(err.message);
    return null;
  }
}

export function isFunction(func) {
  return typeof func == 'function';
}
