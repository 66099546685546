<template>
<div class="apps-eval-main indigo accent-1">
  <v-app-bar
    elevation="4"
    class="white--text indigo darken-1"
  >
    <v-toolbar-title>Evaluate apps</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn v-if="settings" icon class="white--text" @click="settingsDialog=true">
      <v-icon>mdi-cog-outline</v-icon>
    </v-btn>
  </v-app-bar>
  <div class="apps-eval-board pa-4" v-if="apps">
    <AppCard
      v-for="(app, i) in apps"
      :key="i"
      :app="app"
      @rate="(mark) => onRateApp(i, mark)"
    />
  </div>
  <div class="apps-eval-board pa-4 text-center" v-else>
    <v-progress-circular
      size="64"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>

  <AppsEvalSettings
    v-if="settings"
    v-model="settings"
    :dialog="settingsDialog"
    :patterns="patterns"
    @close="settingsDialog=false"
    @play="onVibrate"
  />
</div>
</template>


<script>
import AppsEvalSettings from './AppsEvalSettings';
import AppCard from './AppCard';

export default {
  name: 'AppsEvaluation',

  components: {
    AppCard,
    AppsEvalSettings,
  },

  data() {
    return {
      settingsDialog: false,
      settings: false,
      apps: false,
      patterns: []
    }
  },

  methods: {
    onRateApp(index, mark) {
      this.apps[index].mark = mark;
      this.onVibrate(this.vibrationPatternByMark(mark));
      if (this.ws && this.ws.readyState == 1) {
        this.ws.send(JSON.stringify({action: 'rateApp', payload: {appId: index, mark}}));
      }
    },

    vibrationPatternByMark(mark) {
      const hash = this.patternByMark(mark);
      if (!hash) {
        return;
      }

      for (let i = 0; i < this.patterns.length; i++) {
        const item = this.patterns[i];
        if (item.hash == hash) {
          return item.vibration;
        }
      }
      return false;
    },

    patternByMark(mark) {
      if (mark === 0) {
        return this.settings.markneutral;
      }
      if (mark < 0) {
        return this.settings.markunhappy;
      }
      if (mark > 0) {
        return this.settings.markhappy;
      }
      return false;
    },

    onVibrate(pattern) {
      if (pattern) {
        console.log(`Play pattern ${JSON.stringify(pattern)}`);
      }
    },
  },

  created() {
    this.ws = new WebSocket(`ws://${document.location.host}/api/ws/apps-eval`);
    this.ws.onopen = () => {
      // @TODO: handle this
    }
    this.ws.onerror = () => {
      // @TODO: handle this
    };
    this.ws.onclose = () => {
    };
    this.ws.onmessage = (msg) => {
      try {
        const data = JSON.parse(msg.data);
        if (data.action) {
          if (!data.payload.length) {
            return
          }
          const payload = data.payload[0];

          switch (data.action) {
            case 'delete':
              this.patterns = this.patterns.filter(item => {
                return item.hash != payload.hash;
              });
              break;
            case 'update':
              this.patterns = this.patterns.map(item => {
                if (item.hash == payload.hash) {
                  return payload;
                }
                return item;
              });
            case 'add':
              this.patterns = [ ...this.patterns, payload ];
              break;
          }
        } else {
          // Init
          ['settings', 'patterns'].forEach(field => {
            if (data[field]) {
              this[field] = data[field];
            }
          });

          const {apps, marks} = data;
          for (let i = 0; i < apps.length; i++) {
            if (marks[i] !== undefined) {
              apps[i].mark = marks[i];
            }
          }
          this.apps = apps;
        }
      } catch (e) {
        console.log(e, "ERROROROROR");
        console.log(e);
        // @TODO: handle the error
      }
    };

  },

  beforeUnmount() {
    if (this.ws) {
      this.ws.close();
    }
  },
};
</script>

<style lang="scss" scoped>
.apps-eval-main {
  background-color: #292929;
  height: 100%;
}

.apps-eval-board {
  // background-color: #999;
  height: 100%;

  @media (min-width: 992px) {
    margin: 1rem auto;
    width: 768px;
    height: calc(100% - 64px - 2rem);
  }
}

</style>
