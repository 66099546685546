<template>
  <div class="context-menu" @click="preventBubling"  :style="{top: `${position.top}px`, left: `${position.left}px`}">
    <div :class="{item: true, disabled: item.disabled}" v-for="(item, key) in items" :key="key" @click="handleClick(item)">
      <span class="icon" v-if="item.icon">
        <v-icon>{{ `mdi-${item.icon}` }}</v-icon>
      </span>
      <span class="icon" v-else>&nbsp;</span>
      <span class="text">{{ item.text }}</span>
    </div>
  </div>
</template>


<script>

export default {
  name: 'ContextMenu',
  props: {
    position: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    preventBubling(event) {
      event.stopPropagation();
    },
    handleClick(item) {
      if (!item.disabled && typeof item.handler == 'function') {
        item.handler(item);
      }
    }
  }
};
</script>


<style lang="scss" scoped>
$border_radius: 8px;

.context-menu {
  position: absolute;
  height: 5 * 40px + 2 * $border_radius;
  width: 200px;
  border: 1px solid #c9c9cd;
  box-shadow: 1px 1px 6px 0px;
  background-color: #f7f7fa;
  border-radius: $border_radius;
  padding: $border_radius;
  z-index: 99999;
}

.item {
  display: flex;
  color: #342025;
  padding: 5px;
  border: $border_radius;
  cursor: pointer;

  &:hover {
    background-color: #ffffff;
    color: #2f242b;
  }
}

.disabled {
  &,
  &:hover {
    background-color: transparent;
    color: #9796a1;
    cursor: not-allowed;
  }
}

.icon {
  width: 30px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.text {
  flex-grow: 1;
}

span {
  line-height: 30px;
  height: 30px;
}

</style>
