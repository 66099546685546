<template>
  <v-slider
    :min="min"
    :max="max"
    :step="step"
    :disabled="disabled"
    v-model="c_value"
    @input="onChange"
    @end="$emit('blur')"
  >
    <template v-slot:thumb-label="props">
      <span style="font-size: 10px">{{ Math.round(100 * props.value) / 100 }}s</span>
    </template>
    <template v-slot:prepend>
      <slot name="prepend"></slot>
    </template>
    <template v-slot:append>
      <slot name="append"></slot>
    </template>
  </v-slider>
</template>

<script>
const INPUT_DELAY = 64;

export default {
  name: 'NumberSlider',

  props: {
    value: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean
    },
  },

  data() {
    return {
      c_value: this.value,
    }
  },

  watch: {
    value(updatedValue) {
      this.c_value = updatedValue;
    }
  },

  methods: {
    onChange() {
      if (!this.inputTimer) {
        this.inputTimer = setTimeout(() => {
          this.$emit('input', this.c_value);
          this.inputTimer = false;
        }, INPUT_DELAY);
      }
    }
  },

  beforeUnmount() {
    if (this.inputTimer) {
      clearTimeout(this.inputTimer);
    }
  },
}
</script>
