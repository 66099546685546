<template>
  <div style="height: 100%; width: 100%;">
    <canvas ref="canvas"></canvas>
    <a href="" ref="exporting"></a>
  </div>
</template>

<script>
import { Chart } from 'chart.js';
import ChartJsDragSignal from './plugins/chartjs-dragsignal';
import ChartJsRightClick from './plugins/chartjs-right-click';
import CustomBackground from './plugins/chartjs-custom-background';

import {
  emptyDataset,
  cSignalSteps,
  cSignalStepsBars,
} from '../../util/func';

import { clone } from '../../util/util.js';

import { cloneSignals } from '../../util/signal-patterns';

let draggingSignalIndex = null;
let draggingSignals = null;

Chart.pluginService.register(ChartJsDragSignal);
Chart.pluginService.register(ChartJsRightClick);
Chart.pluginService.register(CustomBackground('#ffffff'));

Chart.defaults.global.defaultFontSize = 14;
Chart.defaults.global.defaultFontFamily = 'courier, monospace';

export default {
  name: 'SignalDisplay',
  props: {
    duration: {
      required: true,
      type: Number,
    },
    signals: {
      type: Array,
      required: true,
    },
    stepsView: true,
    title: String,
    c_export: Number,
  },

  data() {
    return {
      exporting: false
    };
  },

  watch: {
    renderProp() {
      this.refreshDisplay();
    },
    duration() {
      this.refreshDisplay();
    },
    signals: {
      deep: true,
      handler() {
        this.refreshDisplay();
      }
    },
    c_export() {
      this.exporting = true;
    },
    exporting(n, o) {
      if (n) {
        var link = this.$refs.exporting;
        link.setAttribute('download', `${this.title || Date.now()}.png`);
        link.setAttribute('href', this.$refs.canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
        link.click();
        this.exporting = false;
      }
    }
  },

  methods: {
    chartData(signals) {
      if (!signals) {
        signals = this.signals;
      }
      const chartData = {};
      if (this.stepsView) {
        const stepDataset = cSignalSteps(
          signals.filter(s => s.data.length > 0), 0, this.duration, this.stepsView
        );
        stepDataset.borderColor = stepDataset.backgroundColor == '#666666FF'  ? '#000000' : '#666666';
        cSignalStepsBars(stepDataset);
        chartData.datasets = [ stepDataset ];
      } else {
        chartData.datasets = signals.map(({ data, color }) => {
          const e = emptyDataset();
          e.data = clone(data);
          e.backgroundColor = color;
          return e;
        });
      }

      return chartData;
    },

    refreshDisplay() {
      const self = this;
      const { datasets } = this.chartData();
      this.chartInstance.data.datasets = datasets;
      this.chartInstance.options.scales.xAxes.forEach(scale => {
        scale.ticks.max = self.duration;
      });
      this.chartInstance.update();
    },
  },

  mounted() {
    const self = this;

    self.chartInstance = new Chart(self.$refs.canvas, {
      type: 'line',
      data: self.chartData(),
      options: {
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        dragSignal: {
          onStart(position) {
            if (!self.stepsView) {
              const datasets = position.visibleElementsAt();
              for (let i = 0; i < datasets.length; i++) {
                if (self.signals[i].data.length) {
                  const di = datasets[i];
                  const { func, time } = self.signals[di];
                  const y = func(position.x - time);
                  if (position.y <= y) {
                    draggingSignalIndex = di;
                    draggingSignals = cloneSignals(self.signals);
                    break;
                  }
                }
              }
            }
          },
          onDrag({ deltax }, chartInstance) {
            if (draggingSignalIndex !== null && draggingSignals !== null) {
              const offset = draggingSignals[draggingSignalIndex].time;
              const t = Math.min(self.duration, Math.max(0, offset - deltax));
              draggingSignals[draggingSignalIndex].data.forEach(point => {
                point.x = (point.x - offset) + t;
              });
              draggingSignals[draggingSignalIndex].time = t;
              chartInstance.data.datasets = self.chartData(draggingSignals).datasets;
              chartInstance.update(0);
            }
          },
          onStop(/* position */) {
            if (draggingSignals) {
              const tm = draggingSignals.map(s => s.time);
              self.$emit('changeSignalsTime', tm);
            }
            draggingSignalIndex = null;
            draggingSignals = null;
          },
        },
        rightClick: {
          click(chartInstance, a_position, r_position) {
            self.$emit('contextmenu', chartInstance, a_position, r_position);
          },
        },
        elements: {
          point:{
            radius: 0
          },
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
              stepSize: 10,
              // text: 'Amplitude [%]'
            }
          }],
          xAxes: [{
            type: 'linear',
            ticks: {
              min: 0,
              max: self.duration,
              stepSize: 0.05,
              // text: 'Time [seconds]'
            }
          }]
        },
      }
    });
  },

  beforeUnmount() {
    this.chartInstance.destroy();
  }
};
</script>
