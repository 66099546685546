<template>
  <div class="loader">
    <span>Loading...</span>
  </div>
</template>

<script>

export default {
  name: 'Loader',
};

</script>


<style lang="scss" scoped>
.loader {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccc66;
}

span {
  font-weight: bold;
  font-size: 1.5em;
}
</style>
