<template>
<div class="signal-box">
  <v-list dense flat>
    <v-subheader>PATTERNS</v-subheader>
    <div class="signal-box-body">
      <v-list-item-group
        no-action
        color="primary"
        v-for="(signal, key) in signals" :key="key"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-star-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="signal.title"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-switch v-model="signals[key].enabled"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </v-list-item-group>
    </div>
  </v-list>
</div>
</template>

<script>
export default {
  name: 'CollectionSignals',

  props: {
    value: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      signals: this.value.map(s => {
        return { ...s };
      })
    };
  },

  watch: {
    signals: {
      deep: true,
      handler() {
        this.$emit('input', this.signals);
      }
    }
  },
};
</script>

<style>
.signal-box {
  min-height: 300px;
}

.signal-box-body {
  max-height: calc(100vh - 500px);
  overflow-y: auto;
}
</style>
