<template>
  <div>
    <v-btn
      dark
      icon
      title="Import by access code"
      color="grey"
      @click="openModal"
    ><v-icon>mdi-file-import-outline</v-icon></v-btn>

    <v-dialog
      :value="modalOpened"
      persistent
      max-width="360"
    >
      <v-card>
        <v-card-title class="white--text t-background darken-4">Import patterns</v-card-title>
        <v-container>
          <div class="text-upper">
            <v-text-field
              v-model="code"
              label="Enter collection code"
              hide-details="auto"
              :loading="loading"
              @keypress="checkEnter"
            ></v-text-field>
          </div>
          <div class="bbox mt-4 d-flex">
            <v-btn
              color="success"
              :disabled="!canImport() || loading"
              class="flex-grow-1 mr-1"
              title="Import"
              @click="onImport"
            >Import</v-btn>
            <v-btn
              color="error"
              :disabled="loading"
              class="flex-grow-1 ml-1"
              title="Cancel"
              @click="onClose"
            >Cancel</v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import api from '../../util/api';

export default {
  name: 'ImportPatterns',

  data() {
    return {
      loading: false,
      code: '',
      modalOpened: false,
    };
  },

  methods: {
    canImport() {
      return this.code.trim().length > 0;
    },

    openModal() {
      this.modalOpened = true;
    },

    checkEnter(event) {
      if (event.code == 'Enter') {
        this.onImport();
      }
    },

    onImport() {
      if (this.canImport()) {
        api.importPatterns(this.code)
        .then(signals => {
          this.$emit('onimport', signals);
          this.modalOpened = false;
          this.code = '';
        })
        .catch(err => {
          this.$emit('error', err);
        });
      }
    },

    onClose() {
      this.modalOpened = false;
    }
  }
};

</script>
