export function readFile(file, method, cb) {
  var reader;
  if ( !method ) method = 'data';
  var prom = new Promise(function (resolve, reject) {
    try {
      reader = new FileReader();

      // If it takes to long to load the file, the file could be too big!
      var abortTo = setTimeout(function () {
        reader.abort();
      }, 10e3);
      reader.onload = function (evt) {
        clearTimeout(abortTo);
        var target = evt.target;
        target.total  = evt.total;
        target.loaded = evt.loaded;
        target.file   = file;
        target.method = method;
        var result = target.result;
        cb && cb(null, result, file);
        resolve(target);
      };

      reader.onerror = function (evt) {
         clearTimeout(abortTo);
         var error = evt.target.error;
         var msg = error.message;
         switch(error.name) {
          case 'NotFoundError':
            msg = 'File Not Found!';
          break;
          case 'NotReadableError':
            msg = 'File is not readable';
          break;
          case 'AbortError':
            msg = 'File read cancelled';
          break;
          default:
            msg = 'An error occurred reading this file.';
        }
        error.msg = msg;
        cb && cb(error, null, file);
        reject(error);
      };

      // reader.onloadstart = function (evt) {  };
      // reader.onprogress  = function (evt) {  };
      // reader.onabort     = function (evt) {  };

      // Read in the image file as a data URL.
      reader[{
        data  : 'readAsDataURL'
        , text  : 'readAsText'
        , binary: 'readAsBinaryString' // unsupported in IE
        , buffer: 'readAsArrayBuffer'
      }[method]](file);
    } catch(err) {
      reject(err);
    }
  });
  prom.reader = reader;
  prom.file = file;

  return prom;
}

export function isImage(file) {
  return file && file.file && IMG_TYPES.indexOf(file.file.type) !== -1;
}

export function signalFilename(title) {
  if (title) {
    title = title
    .replace(/\s+/, '-')
    .replace(/[^a-z0-9-_.]/i, '')
    .replace(/^-+|-$/, '')
    ;
  }
  if (!title) {
    title = Date.now().toString();
  }
  return `${title}.json`
}

export function downloadFile(filename, content) {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(
    new Blob([ content ])
  );
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

// export const exportedJSFileContent = `function pwm({intensity:t,duration:o},n){const e=[];var i=Math.round(t*n/100);if(0<i)for(;i<=o;)e.push(i,0),o-=i;else e.push(0,o);return e}function Vireo(){const o=%patterns%;function e(t){t=i(t);if(t){const n=[];var o=t.vibro.pattern;for(let t=0;t<o.length;t++)n.push(...pwm(o[t],40));const e={vibro:{pattern:n,offset:1e3*t.vibro.offset}};return t.audio&&(e.audio=t.audio,e.audio.offset*=1e3),e}}function i(t){return o[t]}return i.vibrate=function(t){var{audio:t,vibro:o}=e(t);if(t){const n=new Audio(t.content);setTimeout(()=>{n.play()},t.offset)}setTimeout(()=>{navigator.vibrate(o.pattern)},o.offset)},i.navigatorParam=e,i}export default Vireo();`;


export const exportedJSFileContent = `
function pwm({intensity, duration}, vmax) {
  const ret = [];
  const t = Math.round(intensity * vmax / 100);
  if (t > 0) {
    while (t <= duration) {
      ret.push(t, 0);
      duration -= t;
    }
    // @FIXME: how should we handle duration % t which remain
    //  after above loop time???
  } else {
    ret.push(0, duration);
  }
  return ret;
}

function patternVibro(pattern) {
  const intervals = [];
  const vpatern = pattern.vibration;
  for (let i = 0; i < vpatern.length; i++) {
    intervals.push(
      ...pwm(vpatern[i], 40)
    );
  }
  const ret = {
    vibro: intervals,
  };
  if (pattern.offsetVibro !== undefined) {
    ret.offsetVibro = 1000 * pattern.vibro.offset;
  }
  return ret;
}

function patternAudio(pattern) {
  const ret = {};
  if (pattern.audio) {
    ret.audio = pattern.audio;
    if (pattern.offsetAudio) {
      ret.offsetAudio = pattern.offsetAudio;
    }
  }
  return ret;
}

function Vireo() {
  const patterns = %patterns%;
  const audioPlayer = new Audio();

  function data(name) {
    const pattern = vireo(name);
    if (!pattern) {
      return {};
    }
    return {
      ...patternVibro(pattern), ...patternAudio(pattern),
    };
  };

  function play(name) {
    const pattern = data(name);
    if (pattern.audio) {
      audioPlayer.src =pattern.audio;
      setTimeout(() => {
        audioPlayer.play();
      }, pattern.offsetAudio || 0);
    }
    setTimeout(() => {
      navigator.vibrate(pattern.vibro);
    }, pattern.offsetVibro);
  }


  function vireo(name) {
    return patterns[name];
  }
  vireo.play = play;

  return vireo;
}

export default Vireo();
`;