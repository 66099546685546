<template>
  <v-btn
    fab
    :class="{'ma-2': true, 'white--text': active}" :color="active ? 'indigo darken-1' : 'grey lighten-2'"
    @click="$emit('click')"
  >
    <v-icon v-if="hasIcon()">{{ $slots.default[0].text }}</v-icon>
    <slot v-else></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'AppEvalBtn',

  props: {
    active: {
      type: Boolean,
      required: false,
    }
  },

  data() {
    return {};
  },

  methods: {
    hasIcon() {
      return this.$slots.default[0].text.trim().substr(0, 4) == 'mdi-'
    }
  }
}
</script>
