<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior:  smooth;
}

</style>