import { VIREO_SIDEBAR_MENU_STATE } from './config';

export function clone(data) {
  return JSON.parse(JSON.stringify(data));
}

export function random(from, to) {
  const diff = to - from;
  if (!diff) {
    return from;
  }
  return Math.ceil(diff * Math.random()) + from;
}

export function getRandomColor(opacity) {
  const color = [random(0, 256), random(0, 256), random(0, 256)];
  if (opacity !== undefined) {
    color.push(opacity);
  };

  for (let i = 0; i < color.length; i++) {
    let c = color[i];
    c = c.toString(16);
    if (c.length < 2) {
      c = `0${c}`;
    }
    color[i] = c;
  }

  return `#${color.join('')}`;
}

export function isSm() {
  return document.innerWidth < 768;
}

export function getSidebarMenuState() {
  let state = localStorage ? localStorage.getItem(VIREO_SIDEBAR_MENU_STATE) : null;
  if (state) {
    try {
      state = JSON.parse(state);
    } catch {
      state = null;
    }
  }
  if (!state) {
    state = {
      sm: false,
      lg: true,
    };
  } else {
    state = {
      sm: !!state.sm,
      lg: !!state.lg,
    }
  }
  return state;
}

export function saveSidebarMenuState(sm, lg) {
  if (localStorage) {
    localStorage.setItem(VIREO_SIDEBAR_MENU_STATE, JSON.stringify({
      sm: !!sm,
      lg: !!lg
    }));
  }
}

export function emptyAudioTrack() {
  return {
    content: '',
    duration: 0,
    region: {
      start: 0,
      end: 0,
    },
  };
}

// @TODO: this function is temp. It should be removed when all signals in db
// have fields audioTrack and offsets to avoid errors in the UI
// An modification of all signals in DB should be done, when
// this branch (audio) will be merged into master branch
export function checkAudioFields(signal) {
  if (!signal) {
    return;
  }
  if (!signal.audioTrack) {
    signal.audioTrack = emptyAudioTrack();
  }
  if (!signal.offsets) {
    signal.offsets = {
      vibro: 0,
      audio: 0,
    };
  }
}