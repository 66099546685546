<template>
  <div
    :class="{sidebar: true, 'open-sm': c_openSm, 'close-sm': !c_openSm, 'open-lg': c_openLg, 'close-lg': !c_openLg, nanim: nanim}"
    @click="$emit('onBgClick')">
    <div class="sidebar-body" @click="stopPropagation">
      <slot></slot>
    </div>
  </div>
</template>

<script>

import {
  isSm,
  saveSidebarMenuState,
} from '../../util/util';

export default {
  name: 'Sidebar',
  props: {
    state: {
      type: Object,
      requried: false,
    }
  },
  data() {
    const state = this.state || {};
    return {
      c_openSm: state.sm === undefined ? false : state.sm,
      c_openLg: state.lg === undefined ? true : state.lg,
      nanim: !(isSm() && state.sm),
    }
  },
  watch: {
    state: {
      deep: true,
      handler({sm, lg}) {
        this.c_openSm = sm;
        this.c_openLg = lg;
        this.nanim = false;
        saveSidebarMenuState(sm, lg);
      },
    },
  },
  methods: {
    stopPropagation(event) {
      event.stopPropagation();
    },
    onDocumentResize() {
      this.nanim = true;
      if (this.c_openSm) {
        this.$emit('onBgClick');
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.onDocumentResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onDocumentResize);
  }
};

</script>


<style lang="scss" scoped>
$bgAnimationTime: 0.1s;
$bodyAnimationTime: 0.25s;

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 11;
}

.sidebar-body {
  width: 300px;
  height: 100%;
  background-color: #3c4b64;
  border: 1px solid #3c4b64;
  color: #ffffff;
}

.close-sm {
  visibility: hidden;
  animation: animation-close-bg $bgAnimationTime linear 0s 1 normal forwards;

  .sidebar-body {
    animation: mv-out-body $bgAnimationTime linear 0s 1 normal forwards;
  }
}

.open-sm {
  animation: animation-open-bg $bgAnimationTime linear 0s 1 normal forwards;

  .sidebar-body {
    animation: mv-in-body $bodyAnimationTime linear 0s 1 normal forwards;
  }
}

@keyframes animation-open-bg {
  0% {
    visibility: visible;
    background-color: #00001500;
  }
  100% {
    visibility: visible;
    background-color: #00001580;
  }
}
@keyframes  mv-in-body {
  0% {
    transform: translateX(-300px);
  }
  100% {
    transform: translateX(0px);
  }
}


@keyframes animation-close-bg {
  0% {
    visibility: visible;
    background-color: #00001580;
  }
  100% {
    visibility: hidden;
    background-color: #00001500;
  }
}
@keyframes  mv-out-body {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-300px);
  }
}


@media (min-width: 768px) {
  .close-sm,
  .open-sm {
    visibility: visible;

    &,
    .sidebar-body {
      animation: none;
    }
  }

  .sidebar {
    width: 300px;
    right: auto;
    transition: transform $bgAnimationTime;
  }

  .open-lg {
    transform: translateX(0);
  }

  .close-lg {
    transform: translateX(-300px);
  }
}

.nanim {
  &,
  .sidebar-body {
    animation: none !important;
  }
}
</style>
