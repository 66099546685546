function CustomBackground(color) {
  return {
    id: 'customBackground',
    beforeDraw: (chartInstance) => {
      const ctx = chartInstance.canvas.getContext('2d');
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, chartInstance.width, chartInstance.height);
      ctx.restore();
    }
  };
}

export default CustomBackground;
