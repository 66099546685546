const COLOR_PRIMARY_CHART = '#afe9e455';
const CHART_VALUE_POWER = 1e7;

export function emptyDataset(label, color, lineTension) {
  color = color || COLOR_PRIMARY_CHART;
  return {
    label,
    lineTension: lineTension || 0,
    backgroundColor: color,
    borderColor: color,
    borderWidth: 1,
    data: [],
  };
}

export function fnChartData(fn, limits, step) {
  const data = [];

  if (step <= 0) {
    throw new Error('Step should be positive');
  }

  let x = jsnumcorrection(limits[0]);
  data.push({
    x, y: 0
  });
  while (x <= limits[1]) {
    data.push({
      x,
      y: jsnumcorrection( fn(x) ),
    });
    x = jsnumcorrection(x + step);
  }
  if (Math.abs(x - limits[1]) > 0.000001) {
    x = jsnumcorrection( Math.min(x, limits[1]) );
    data.push({
      x,
      y: jsnumcorrection( fn(x) ),
    });
  }
  data.push({
    x, y: 0
  });

  return data;
}

export function functionSteps(fn, limits, step) {
  const data = [];
  let [x0, x1] = limits;
  step /= 2;
  while (x0 < x1) {
    if (x1 - x0 < 4 * step) {
      step = (x1 - x0) / 2
    }
    const y = fn(x0 + step);
    data.push({
      x: x0,
      y
    });
    x0 += 2 * step;
    data.push({
      x: x0,
      y
    });
  }
  return data;
}

export function pointsYLimit(points, minLimit, maxLimit) {
  points.forEach(point => {
    if (minLimit !== undefined && point.y < minLimit) {
      point.y = minLimit;
    }
    if (maxLimit !== undefined && point.y > maxLimit) {
      point.y = maxLimit;
    }
  });
  return points;
}

function jsnumcorrection(x) {
  return Math.round(x * CHART_VALUE_POWER) / CHART_VALUE_POWER;
}

export function moveChartPoints(values, deltax, deltay) {
  return values.map(({x, y}) => {
    return {
      x: jsnumcorrection(x + deltax),
      y: jsnumcorrection(y + deltay),
    };
  });
}

function stepSignalValue(x, signals, indexes) {
  const values = [ 0 ];
  for (let i = 0; i < indexes.length; i++) {
    const signal = signals[ indexes[i] ];
    values.push(signal.func(x - signal.time));
  }
  return Math.min(
    100, Math.max(
      0, Math.max(...values)
    )
  );
}

function splitInterval(main, sec) {
  if (sec[1] <= main[0] || main[1] <= sec[0]) {
    return [];
  }

  const ret = [];

  let l;
  if (main[0] < sec[0]) {
    ret.push({
      int: [main[0], sec[0]],
      fn: false,
    });
    l = sec[0];
  } else {
    l = main[0];
  }

  ret.push({
    int: [l, Math.min(main[1], sec[1])],
    fn: true,
  });

  if (sec[1] < main[1]) {
    ret.push({
      int: [sec[1], main[1]],
      fn: false,
    });
  }

  return ret;
}

function splitSignalToStepIntervals(signals, from, to) {
  const intervals = [{
    int: [from, to],
    fn: [],
  }];
  for (let i = 0; i < signals.length; i++) {
    const signal = signals[i];
    const s_interval = [signal.time, signal.time + signal.duration];
    for (let j = 0; j < intervals.length; j++) {
      const c_intervals = splitInterval(intervals[j].int, s_interval);
      if (c_intervals.length) {
        for (let k = 0; k < c_intervals.length; k++) {
          const fn = [ ...intervals[j].fn ];
          if (c_intervals[k].fn) {
            fn.push(i);
          }
          c_intervals[k].fn = fn;
        }
        intervals.splice(j, 1, ...c_intervals);
        j += c_intervals.length - 1;
      }
    }
  };

  return intervals;
}

function compactData(data) {
  if (!data.length) {
    return [];
  }

  const ret = [ data[0] ];
  let j = 0;
  let last = null;
  for (let i = 1; i < data.length; i++) {
    if (jsnumcorrection(data[i].y - ret[j].y)) {
      if (last) {
        ret.push(last);
        j++;
      }
      ret.push(data[i]);
      j++;
    }
    last = data[i];
  }
  if (!jsnumcorrection(last.y - ret[j].y)) {
    ret.push(last);
  }

  return ret;
}

export function cSignalSteps(signals, from, to, {step, color}) {
  const set = emptyDataset('', color || '#6633bb33');
  const data = [];
  const intervals = splitSignalToStepIntervals(signals, from, to);
  step = step || 1;
  for (let i = 0; i < intervals.length; i++) {
    let indexes = intervals[i].fn;
    let [from, to] = intervals[i].int.map(jsnumcorrection);
    while (from < to) {
      const x0 = jsnumcorrection(from);
      const x1 = jsnumcorrection(from + 2 * step < to ? from + step : to);
      const y  = jsnumcorrection(stepSignalValue((x0 + x1) / 2, signals, indexes));
      data.push({
        x: x0, y
      }, {
        x: x1, y
      });
      from = x1;
    }
  }
  set.data = compactData(data);
  return set;
}

export function cSignalStepsBars(dataset) {
  const bars = []
  const data = dataset.data;
  for (let i = 0; i < data.length; i += 2) {
    const y = data[i].y;
    bars.push(
      {x: data[i].x, y: 0},
      {x: data[i].x, y: y},
      {x: data[i + 1].x, y: y},
      {x: data[i + 1].x, y: 0},
    );
  }
  dataset.data = bars;
}

export function signalOutput(signals, duration, stepsView) {
  const points = cSignalSteps(signals, 0, duration, stepsView).data;
  const output = [];
  for (let i = 0; i < points.length; i += 2) {
    output.push({
      intensity: Math.round(points[i].y),
      duration: 1000 * jsnumcorrection(points[i + 1].x - points[i].x),
    });
  }

  let l = output.length - 1;
  let i = l;
  for (; i >= 0; i--) {
    if (output[i].intensity !== 0) {
      break
    }
  }

  if (i !== l) {
    output.splice(i + 1);
  }

  return output;
}
