<template>
<div>
  <!-- <p>
    Audio: <code>{{ this.audioLength }}</code><br />
    Vibro: <code>{{ this.vibroLength }}</code><br />
    <code>{{ length() }}</code>
  </p> -->
  <Track
    v-if="audioTrack > 0"
    icon="mdi-volume-medium"
    color="#009688"
    :value="offsets.audio"
    :trackLength="audioLength"
    :totalLength="length()"
    @input="(val) => { emitInput('audio', val); }"
    @scroll="() => { $emit('scroll', 'audio'); }"
  >
    <template v-slot:indicator>
      <div class="track-offset">
        <span>{{ Math.round(1000 * offsets.audio) }}ms</span>
        <span>{{ Math.round(1000 * audioLength) }}ms</span>
        <span>{{ Math.round(1000 * (offsets.audio + audioLength)) }}ms</span>
      </div>
    </template>
    <template v-slot:after>
      <div style="position:  absolute; width:  100%; height:  22px; top: -22px;">
        <Timeline
          :duration="1000 * length()"
          position="bottom"
        />
      </div>
    </template>
  </Track>

  <Track
    v-if="vibroTrack > 0"
    icon="mdi-volume-vibrate"
    color="#5c74aa"
    :value="offsets.vibro"
    :trackLength="vibroLength"
    :totalLength="length()"
    @input="(val) => { emitInput('vibro', val); }"
    @scroll="() => { $emit('scroll', 'vibro'); }"
  >
    <template v-slot:indicator>
      <div class="track-offset">
        <span>{{ Math.round(1000 * offsets.vibro) }}ms</span>
        <span>{{ Math.round(1000 * vibroLength) }}ms</span>
        <span>{{ Math.round(1000 * (offsets.audio + vibroLength)) }}ms</span>
      </div>
    </template>
    <template v-slot:after>
      <div style="position:  absolute; width:  100%; height:  22px; bottom: -22px;">
        <Timeline
          :duration="1000 * length()"
          position="top"
        />
      </div>
    </template>
  </Track>
</div>
</template>

<script>
export default {
  name: 'PatternTracks',

  props: {
    value: {
      type: Object,
      requried: true,
    },
    audioLength: {
      type: Number,
      required: true,
    },
    vibroLength: {
      type: Number,
      required: true,
    }
  },

	computed: {
		audioTrack() {
			return this.audioLength / (this.audioLength + this.vibroLength);
		},
		vibroTrack() {
			return this.vibroLength / (this.audioLength + this.vibroLength);
		},
	},

  data() {
    return {
      offsets: {
        vibro: this.value.vibro / this.length(),
        audio: this.value.audio / this.length(),
      },
    };
  },

  watch: {
    value: {
      deep: true,
      handler(value) {
        this.offsets = {
          vibro: value.vibro / this.length(),
          audio: value.audio / this.length(),
        };
      }
    },
  },

  computed: {
    audioTrack() {
      return this.audioLength / this.length();
    },
    vibroTrack() {
      return this.vibroLength / this.length();
    },
  },

  methods: {
    length() {
      return this.audioLength + this.vibroLength;
    },

    emitInput(type, value) {
      const length = this.length();
      const data = {
        vibro: this.offsets.vibro * length,
        audio: this.offsets.audio * length,
      };
      data[type] = value * length;
      this.$emit('input', data);
    },
  },
};
</script>

<style lang="scss">
.pattern-track {
  display: flex;
  align-items: center;
  height: 56px;
  margin-bottom: 0.5rem;
}

.pattern-track-icon:first-child {
  padding-right: 0.5rem;
}

.pattern-track-icon:last-child {
  padding-left: 0.5rem;
}

.pattern-track-line {
  position: relative;
  flex-grow: 1;
  height: 100%;
  background-color: #f0f0f0;
  border-bottom: 1px solid #949494;
}

.pattern-track-region {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  width: 221px;
  background-color: #63b;
  cursor: pointer;
  user-select: none;
}

.track-offset {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
  color: #ffffff;
  font-weight: bold;
  font-size: 12px;
}
</style>
