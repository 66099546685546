import { drag } from 'd3-drag';
import { select } from 'd3-selection';
import {
  chartCoordinates,
  isFunction,
} from './functions';

let eventsQueue = [];

function eventInQueue(e) {
  eventsQueue.unshift(e);
  if (eventInQueue.length > 2) {
    eventsQueue.pop();
  }
}

function eventsQueueVerticalDir() {
  if (eventsQueue.length < 2) {
    return 0;
  }
  const [lastEvent, prevEvent] = eventsQueue;
  return prevEvent.y - lastEvent.y;
}

function eventsQueueHorizontalDir() {
  if (eventsQueue.length < 2) {
    return 0;
  }
  const [lastEvent, prevEvent] = eventsQueue;
  return prevEvent.x - lastEvent.x;
}

function dragSignalStart(chartInstance, callback) {
  return (event) => {
    const position = chartCoordinates(chartInstance, event, 0, 0);
    if (position) {
      eventInQueue(position);
      if (isFunction(callback)) {
        callback(position);
      }
    }
  }
}

function dragSignalDrag(chartInstance, callback) {
  return (event) => {
    const position = chartCoordinates(chartInstance, event, 0, 0);
    if (position) {
      eventInQueue(position);
      position.deltax = eventsQueueHorizontalDir();
      position.deltay = eventsQueueVerticalDir();

      if (isFunction(callback)) {
        callback(position, chartInstance);
      }
    }
  }
}

function dragSignalEnd(chartInstance, callback) {
  return (event) => {
    const position = chartCoordinates(chartInstance, event, 0, 0);
    if (isFunction(callback)) {
      callback(position || {
        x: undefined,
        y: undefined
      });
    }
    eventsQueue = [];
  }
}

const ChartJSdragSignal = {
  id: 'dragSignal',
  afterInit(chartInstance) {
    if (chartInstance.config.type == 'line' && chartInstance.options.dragSignal) {
      const options = chartInstance.options.dragSignal;
      select(chartInstance.chart.canvas).call(
        drag().container(chartInstance.chart.canvas)
          .on('start', dragSignalStart(chartInstance, options.onStart))
          .on('drag', dragSignalDrag(chartInstance, options.onDrag))
          .on('end', dragSignalEnd(chartInstance, options.onStop))
      );
    }
  }
};

export default ChartJSdragSignal;
