<template>
  <v-snackbar
    v-model="visible"
    color="red"
    :timeout="2000"
    @click="hideError"
  >
    <b>{{ value.response && value.response.data && value.response.data.msg || value.message }}</b>
    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        icon
        v-bind="attrs"
        @click="hideError"
      ><v-icon>mdi-close</v-icon></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'ErrorNotification',

  props: {
    value: {
      required: true
    },
  },

  data() {
    return {
      visible: this.valueVisibility(),
    };
  },

  watch: {
    value() {
      this.visible = this.valueVisibility();
    },

    visible(to) {
      if (!to) {
        this.$emit('input', false);
      }
    },
  },

  methods: {
    valueVisibility() {
      const visibility = this.value != false;
      if (visibility) {
        console.error();
      }
      return visibility;
    },

    hideError() {
      this.visible = false;
    },
  },
}
</script>
