<template>
  <v-dialog
    :value="true"
    persistent
    max-width="290"
  >
    <v-card>
      <v-card-title class="white--text t-background darken-4">New collection</v-card-title>
      <v-container>
        <ErrorAlert v-model="error" />

        <v-text-field
          v-model="title"
          label="Title"
          hide-details="auto"
          :loading="loading"
          @keypress="checkEnter"
        ></v-text-field>

        <v-card-actions dark>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            :disabled="loading || !title.trim()"
            @click="addCollection"
            class="white--text"
          >Add</v-btn>
          <v-btn
            color="red"
            @click="cancelCollection"
            class="white--text"
          >Cancel</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorAlert from '../ErrorAlert.vue';
import api from '../../util/api';

export default {
  name: 'AddCollectionForm',

  components: {
    ErrorAlert
  },

  data() {
    return {
      title: '',
      error: false,
      loading: false,
    };
  },

  methods: {
    checkEnter(event) {
      if (event.code == 'Enter') {
        this.addCollection();
      }
    },

    addCollection() {
      if (this.title.trim()) {
        this.loading = true;
        this.error = false;
        api.addCollection(this.title)
        .then(({ collection }) => {
          this.$emit('add', {title: collection.title, code: collection.code});
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.error = err;
        });
      }
    },

    cancelCollection() {
      this.$emit('cancel');
    }
  }
};
</script>

<style>
/* @TODO: .t-background should be a global class */
.t-background {
  background-color: #3c4b64;
}
</style>
