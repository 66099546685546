import { chartCoordinates } from './functions';

const ChartJsRightClick = {
  id: 'rightClick',
  afterInit(chartInstance) {
    if (chartInstance.options.rightClick && chartInstance.options.rightClick.click && !chartInstance.canvas.__rightClickInited__) {
      chartInstance.canvas.__rightClickInited__ = true;
      chartInstance.chart.canvas.addEventListener('contextmenu', function(event) {
        event.preventDefault();
        const l_position = chartCoordinates(chartInstance, {
          x: event.offsetX,
          y: event.offsetY,
        }, 0, 0);
        chartInstance.options.rightClick.click(chartInstance, {
          x: event.offsetX,
          y: event.offsetY,
        }, l_position);
      });
    }
  }
};

export default ChartJsRightClick;
