<template>
<div>
  <ul class="tmp-menu">
    <li
      v-for="(collection, i) in collections"
      :key="i"
      :class="{'pa-2': true }"
      @click="collectionStartEdit(i)"
      class="collection-item"
    >
      <div class="d-flex align-center">
        <span :class="{'flex-grow-1': true, 'untitled': !collection.title.trim() }">{{ collection.title || 'Undefined' }}</span>
        <v-btn
          icon
          @click.stop="collectionDelete(i)"
          class="delete-btn"
          title="Delete this collection"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </div>
    </li>
  </ul>
  <div class="d-flex align-center justify-center pa-2">
    <v-btn
      dark
      class="flex-grow-1"
      color="success"
      @click="collectionStartAdd"
    >Add collection</v-btn>
  </div>

  <AddCollectionForm
    v-if="add !== false"
    @add="collectionAdd"
    @cancel="collectionAddCancel"
  />
  <EditCollectionForm
    v-else-if="edit !== false"
    :value="collections[edit]"
    :signals="signals"
    @input="collectionEdit"
    @cancel="collectionEditCancel"
  />
</div>
</template>

<script>
import AddCollectionForm from './AddCollectionForm';
import EditCollectionForm from './EditCollectionForm';
import api from '../../util/api';

export default {
  name: 'CollectionsList',

  components: {
    AddCollectionForm,
    EditCollectionForm,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    signals: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      collections: this.value.map(c => { return { ...c }; }),
      add: false,
      edit: false,
    };
  },

  methods: {
    collectionStartAdd() {
      this.add = true;
    },

    collectionAddCancel() {
      this.add = false;
    },

    collectionAdd(collection) {
      this.collections.push(collection);
      this.$emit('input', this.collections);
      this.add = false;
    },

    collectionStartEdit(index) {
      this.edit = index;
    },

    collectionEditCancel() {
      this.edit = false;
    },

    collectionEdit(collection) {
      const index = this.edit;
      this.collections = this.collections.map((conn, i) => {
        return i == index ? collection : conn
      });
      this.edit = false;
    },

    collectionDelete(index) {
      const collection = this.collections[index];
      const yes = confirm(`Are you sure you want to de lete collection "${collection.title}"`);
      if (yes) {
        // @TODO: add loader over collections list;
        api.deleteCollection(collection.code)
        .then(() => {
          this.collections = this.collections.filter(con => con.code !== collection.code);
          this.$emit('input', this.collections);
        })
        .catch(err => {
          this.$emit('error', err);
        })
        ;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.tmp-menu {
  margin: 0;
  padding: 0;

  li {
    cursor: pointer;
    transition: background-color 0.25s;

    &:hover {
      background-color: #46546c;
    }
  }
}

.collection-item {
  .delete-btn {
    opacity: 0;
    transition: opacity 0.3s;
  }
}

.collection-item:hover {
  .delete-btn {
    opacity: 1;
  }
}
.untitled {
  color: #a5a5a5;
}
</style>
