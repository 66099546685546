<template>
<div>
  <v-text-field
    v-model="name"
    label="Name"
    hide-details="auto"
    :loading="loading"
    @keypress="checkEnter"
  ></v-text-field>
  <v-text-field
    v-model="email"
    label="Email"
    hide-details="auto"
    :loading="loading"
    @keypress="checkEnter"
  ></v-text-field>
  <v-text-field
    v-model="password"
    label="Passwrod"
    type="password"
    hide-details="auto"
    :loading="loading"
    @keypress="checkEnter"
  ></v-text-field>
  <v-text-field
    v-model="password2"
    label="Repeat password"
    type="password"
    hide-details="auto"
    :loading="loading"
    @keypress="checkEnter"
  ></v-text-field>
  <div class="bbox">
    <v-btn
      block
      color="success"
      :disabled="isDisabled()"
      @click="register"
    >Register</v-btn>
  </div>
  <!-- <div class="d-flex flex-column bbox">
    <router-link to="/" class="small">Already have an account?</router-link>
  </div> -->
</div>
</template>


<script>

export default {
  name: 'RegisterForm',

  props: {
    loading: Boolean,
  },

  data() {
    return {
      name: '',
      email: '',
      password: '',
      password2: '',
    };
  },

  methods: {
    isDisabled() {
      return !this.name || !this.email || !this.password;
    },

    register() {
      if (!this.isDisabled()) {
        if (this.password == this.password2) {
          this.$emit('register', {
            name: this.name,
            email: this.email,
            password: this.password,
          });
        } else {
          this.$emit('error', new Error('Fields `Password` and `Repeated password` should be the same'));
        }
      }
    },

    checkEnter(event) {
      if (event.code == 'Enter') {
        this.login();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bbox {
  padding-top: 1.5rem;
}

.bbox a {
  text-decoration: none;
}
</style>
