import { Linear } from './Linear';
import { Dotted } from './Dotted';
import { Points } from './Points';
import { patternFuncCall } from '../signal-patterns';

export function signalFuncFactory(signal) {
  let time = [signal.time, signal.time + signal.duration];
  let limits = [0, 100];
  switch (signal.type) {
    case 'linear':
      return new Linear(signal.params, time, limits);
    case 'freeDraw':
      return new Points({points: signal.params}, time, limits);
  }

  const c = new Dotted({}, time, limits);
  c._params = Object.assign({
    stepLength: 0.01,
  }, signal.params);
  c._fn = function(t) {
    return patternFuncCall(signal.type, t, this._params);
  }

  return c;
}
