<template>
  <div style="height: 100%; width: 100%;">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import { Chart } from 'chart.js';
import { emptyDataset } from '../../util/func';
import ChartJSDrawSignal from './plugins/chartjs-freedraw';
import CustomBackground from './plugins/chartjs-custom-background';

Chart.pluginService.register(ChartJSDrawSignal);
Chart.pluginService.register(CustomBackground('#ffffff'));

Chart.defaults.global.defaultFontSize = 14;
Chart.defaults.global.defaultFontFamily = 'courier, monospace';

export default {
  name: 'FreeDrawDisplay',
  props: {
    duration: {
      required: true,
      type: Number,
    },
    color: {
      type: String,
      required: true,
    },
    points: {
      type: Array,
      required: true,
    },
    freeDrawRequest: {
      type: Number,
      required: false,
    },
  },
  data() {
    const coordDataset = emptyDataset('', this.color);
    const pointsDataset = emptyDataset('', this.color);
    coordDataset.data = [{x: 0, y: 0}];
    pointsDataset.data = this.points;
    return {
      datasets: [ coordDataset, pointsDataset ],
    };
  },
  watch: {
    renderProp() {
      this.refreshDisplay();
    },
    duration() {
      this.refreshDisplay();
    },
    color(color) {
      this.datasets.forEach(d => {
        d.backgroundColor = color;
      });
      this.refreshDisplay();
    },
    freeDrawRequest() {
      this.$emit('freeDrawCompleted', this.datasets[1].data);
    },
    points(points) {
      this.datasets[0].data = [{x: 0, y: 0}];
      this.datasets[1].data = points;
      this.refreshDisplay();
    },
  },
  methods: {
    refreshDisplay() {
      const { datasets, duration } = this;
      this.chartInstance.data.datasets = datasets;
      this.chartInstance.options.scales.xAxes.forEach(scale => {
        scale.ticks.max = duration;
      });
      this.chartInstance.update();
    },
  },
  mounted() {
    const self = this;
    self.chartInstance = new Chart(self.$refs.canvas, {
      type: 'line',
      data: {datasets: self.datasets},
      options: {
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        drawSignal: {
          limits: [0, 100],
          onDrag(chartInstance, points) {
            chartInstance.data.datasets[1].data = points;
            chartInstance.update(0);
          },
          onStop(chartInstance, points) {
            chartInstance.data.datasets[1].data = points;
            self.datasets[1].data = points;
            chartInstance.update(0);
          },
        },
        elements: {
          point:{
            radius: 0
          },
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
              stepSize: 10,
              // text: 'Amplitude [%]'
            }
          }],
          xAxes: [{
            type: 'linear',
            ticks: {
              min: 0,
              max: self.duration,
              stepSize: 0.05,
              // text: 'Time [seconds]'
            }
          }]
        },
      }
    });
  },
  beforeUnmount() {
    this.chartInstance.destroy();
  },
};
</script>
