export default class SiganlsPlayer {
  constructor() {
    this.context = null;
    this.o = null;
    this.g = null;
  }

  getContext() {
    if (!this.context) {
      this.context = new AudioContext();
    }
    return this.context;
  }

  play(signals, callback) {
    const context = this.getContext();
    this.stop();
    this.o = context.createOscillator()
    this.g = context.createGain()
    this.o.connect(this.g)
    this.o.type = 'sine'
    this.g.connect(context.destination)
    this.o.frequency.value = 0;
    this.o.start(0)
    this._playFrom(signals, 0, callback);
  }

  _playFrom(signals, index, callback) {
    if (0 <= index && index < signals.length) {
      this.o.frequency.value = 2 *  signals[index].i;
      setTimeout(this._playFrom.bind(this, signals, index + 1, callback), 1e3 * signals[index].t);
    } else {
      this.o.stop();
      if (typeof callback == 'function') {
        callback(signals);
      }
    }
  }

  stop() {
    if (this.o) {
      this.o.stop();
    }
  }
}
