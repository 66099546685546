import { BaseFunction } from './BaseFunction.js';
import { clone } from '../util';

export class Points extends BaseFunction {
  initProps() {
    this._params = {
      points: []
    };
  }

  get points() {
    if (!this._points) {
      const time = this._time[0];
      this._points = clone(this._params.points).map(p => {
        p.x += time;
        return p;
      });
      if (this._points.length > 1) {
        this._points.push({x: this._points[this._points.length - 1].x, y: 0});
        this._points.unshift({x: this._points[0].x, y: 0});
      }
    }
    return this._points;
  }

  _fn(t) {
    let p1, p2;
    let points = this._params.points;
    for (let i = points.length - 1; i >= 0; i--) {
      if (points[i].x == t) {
        return points[i].y;
      }
      if (points[i].x < t) {
        p1 = points[i];
        break;
      }
      if (t < points[i].x) {
        p2 = points[i];
      }
    }
    if (!p1 || !p2) {
      return 0;
    }
    return ((p2.y - p1.y) * t + p2.x * p1.y - p1.x * p2.y) / (p2.x - p1.x);
  }
}
