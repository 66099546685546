import { BaseFunction } from './BaseFunction.js';

export class Linear extends BaseFunction {
  initProps() {
    super.initProps();
    this._params = {
      a: 1,
      b: 0,
    };
  }

  constructor(params, time, limits) {
    super(params, time, limits);
  }

  _calcPoints() {
    const { _time: time, _limits: limits, _params: params } = this;
    const duration = time[1] - time[0];
    const points = [{
      x: 0,
      y: this._fn(0),
    }, {
      x: duration,
      y: this._fn(duration),
    }];
    if (params.a === 0) {
      if (points[0].y < limits[0]) {
        points.forEach(p => {
          p.y = limits[0];
        });
      } else if (points[0].y > limits[1]) {
        points.forEach(p => {
          p.y = limits[1];
        });
      }
    } else if (params.a > 0) {
      if (points[1].y < limits[0]) {
        points.forEach(p => {
          p.y = limits[0];
        });
      } else if (points[0].y > limits[1]) {
        points.forEach(p => {
          p.y = limits[1];
        });
      } else {
        let x_min = null;
        let x_max = null;
        if (points[0].y < limits[0]) {
          x_min = limits[0] -params.b / params.a;
          points[0].y = limits[0];
        }
        if (points[1].y > limits[1]) {
          x_max = (limits[1] - params.b) / params.a;
          points[1].y = limits[1];
        }
        if (x_min) {
          points.splice(1, 0, {
            x: x_min,
            y: limits[0],
          });
        }
        if (x_max) {
          points.splice(points.length - 1, 0, {
            x: x_max,
            y: limits[1],
          });
        }
      }
    } else if  (params.a < 0) {
      if (points[1].y > limits[1]) {
        points.forEach(p => {
          p.y = limits[1];
        });
      } else if (points[0].y < limits[0]) {
        points.forEach(p => {
          p.y = limits[0];
        });
      } else {
        let x_min = null;
        let x_max = null;
        if (points[0].y > limits[1]) {
          x_min = (limits[1] - params.b) / params.a;
          points[0].y = limits[1];
        }
        if (points[1].y < limits[0]) {
          x_max = (limits[0] - params.b) / params.a;
          points[1].y = limits[0];
        }
        if (x_min) {
          points.splice(1, 0, {
            x: x_min,
            y: limits[1],
          });
        }
        if (x_max) {
          points.splice(points.length - 1, 0, {
            x: x_max,
            y: limits[0],
          });
        }
      }
    }
    return points.map(p => {
      p.x += time[0];
      return p;
    });
  }

  _fn(x) {
    const { _params: params } = this;
    return params.a * x + params.b;
  }
}
