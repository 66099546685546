<template>
  <v-dialog
    :value="true"
    persistent
    max-width="480"
  >
    <v-card>
      <v-card-title class="white--text t-background darken-4">Export patterns</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="9" class="pb-0">
            <v-text-field v-model="filename" label="Filename" v-if="signalsStates && signalsStates.length">
              <template v-slot:prepend><span class="scolor text-no-wrap theme--light">EXPORT TO:</span></template>
              <!-- <template v-slot:append>.json</template> -->
            </v-text-field>
          </v-col>
          <v-col cols="3" class="pb-0">
            <v-select
              v-model="fileFormat"
              :items="exportFormats"
              label="Type"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pt-0">
            <a href="/docs" @click="goToReadme">How to use exported file?</a>
          </v-col>
        </v-row>

        <div v-if="signalsStates === null" class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <ExportableSignalsList
          v-else-if="signalsStates.length"
          v-model="signalsStates"
        />
        <div v-else class="no-signal-box text-center">
          <span>There are no patterns yet!</span>
        </div>
      </v-card-text>

      <v-card-actions dark>
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          class="white--text"
          :disabled="loading || ! canExport()"
          @click="exportPatterns"
        >Export</v-btn>
        <v-btn
          color="red"
          class="white--text"
          :disabled="loading"
          @click="closeExport"
        >Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ExportableSignalsList from './ExportableSignalsList';
import api from '../../../util/api';
import { downloadFile, exportedJSFileContent } from '../../../util/files';

export default {
  name: 'ExportPatternsForm',

  props: {
    signals: {
      type: Array,
      required: true,
    },
  },

  components: {
    ExportableSignalsList,
  },

  data() {
    const data = {
      loading: false,
      signalsStates: this.setSignalsStates(this.signals),
      filename: 'pattern',
      fileFormat: '.js',
      exportFormats: ['.js', '.json'],
    };
    return data;
  },

  methods: {
    setSignalsStates(signals) {
      const map = new Map();
      if (this.signalsStates) {
        for (let i = 0; i < this.signalsStates.length; i++) {
          const s = this.signalsStates[i];
          map.set(s.hash, {
            enabled: s.enabled,
            id: s.id
          });
        }
      }

      return signals.map(s => {
        const so = map.has(s.hash) ? map.get(s.hash) : {id: '', enabled: false};
        so.title = s.title;
        so.hash = s.hash;
        return so;
      });
    },

    checkEnter(event) {
      if (event.code == 'Enter') {
        this.addCollection();
      }
    },

    canExport() {
      if (!this.filename) {
        return false;
      }
      for (let i = 0; i < this.signalsStates.length; i++) {
        if (this.signalsStates[i].enabled) {
          return true;
        }
      }
      return false;
    },

    exportPatterns() {
      this.loading = true;
      const data = {};
      this.signalsStates.forEach(s => {
        if (s.enabled) {
          const id = (s.id || s.title).trim();
          if (id) {
            data[s.hash] = id;
          }
        }
      });

      if (Object.keys(data).length) {
        api.getSignalsForExport(data)
        .then((signals) => {
          let content = '';
          if (this.fileFormat == '.json') {
            content = JSON.stringify(signals, null, 2);
          } else if (this.fileFormat == '.js') {
            content = exportedJSFileContent.replace('%patterns%', JSON.stringify(signals));
          }
          downloadFile(
            this.filename + this.fileFormat, content
          );
          this.loading = false;
        })
        .catch(err => {
          this.$emit('error', err);
          this.loading = false;
        })
        ;
      } else {
        // @TODO: show nice notifications
        console.log('Nothing to export');
        this.loading = false;
      }
    },

    closeExport() {
      this.$emit('close');
    },

    goToReadme() {
      if (this.$route.params.hash != 'docs') {
        this.$router.push('docs');
      }
      this.closeExport();
    },
  },
};
</script>

<style>
/* @TODO: .t-background should be a global class */
.t-background {
  background-color: #3c4b64;
}
.scolor {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}
</style>
