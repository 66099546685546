<template>

<div class="mt-6" v-if="modelType == 'exponential'">
  <v-row>
    <v-col cols="12" class="d-flex purple lighten-5">
      <ColorPicker
        v-model="c_color"
        :disabled="true"
        title="Color"
      />
      <pre class="flex-grow-1">A(t) = A<sub>max</sub>e<sup>-τt + D</sup> + c</pre>
      <v-btn
        icon
        @click="$emit('swichMinimize')"
      >
        <v-icon v-if="minimized">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-window-minimize</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>
  </v-row>

  <v-row :class="{'gen-pat-inputs': true, 'd-none': minimized}">
    <v-col cols="2" class="py-0 d-flex align-center justify-end">A<sub>max</sub></v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.amplitude.min" :min="patternParamsConfig.amplitude.min" :max="patternParamsConfig.amplitude.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.amplitude.max" :min="patternParamsConfig.amplitude.min" :max="patternParamsConfig.amplitude.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>

    <v-col cols="2" class="py-0 d-flex align-center justify-end">τ</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.tau.min" :min="patternParamsConfig.tau.min" :max="patternParamsConfig.tau.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.tau.max" :min="patternParamsConfig.tau.min" :max="patternParamsConfig.tau.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>

    <v-col cols="2" class="py-0 d-flex align-center justify-end">D</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.delta.min" :min="patternParamsConfig.delta.min" :max="patternParamsConfig.delta.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.delta.max" :min="patternParamsConfig.delta.min" :max="patternParamsConfig.delta.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>

    <v-col cols="2" class="py-0 d-flex align-center justify-end">c</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.c.min" :min="patternParamsConfig.c.min" :max="patternParamsConfig.c.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.c.max" :min="patternParamsConfig.c.min" :max="patternParamsConfig.c.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>
  </v-row>
</div>

<div class="mt-6" v-else-if="modelType == 'linear'">
  <v-row>
    <v-col cols="12" class="d-flex purple lighten-5">
      <ColorPicker
        v-model="c_color"
        :disabled="true"
        title="Color"
      />
      <pre class="flex-grow-1">A(t) = at + b</pre>
      <v-btn
        icon
        @click="$emit('swichMinimize')"
      >
        <v-icon v-if="minimized">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-window-minimize</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>
  </v-row>

  <v-row :class="{'gen-pat-inputs': true, 'd-none': minimized}">
    <v-col cols="2" class="py-0 d-flex align-center justify-end">a</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.a.min" :min="patternParamsConfig.a.min" :max="patternParamsConfig.a.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.a.max" :min="patternParamsConfig.a.min" :max="patternParamsConfig.a.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>

    <v-col cols="2" class="py-0 d-flex align-center justify-end">b</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.b.min" :min="patternParamsConfig.b.min" :max="patternParamsConfig.b.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.b.max" :min="patternParamsConfig.b.min" :max="patternParamsConfig.b.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>
  </v-row>
</div>

<div class="mt-6" v-else-if="modelType == 'parabolic'">
  <v-row>
    <v-col cols="12" class="d-flex purple lighten-5">
      <ColorPicker
        v-model="c_color"
        :disabled="true"
        title="Color"
      />
      <pre class="flex-grow-1">A(t) = at<sup>2</sup> + bt + c</pre>
      <v-btn
        icon
        @click="$emit('swichMinimize')"
      >
        <v-icon v-if="minimized">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-window-minimize</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>
  </v-row>

  <v-row :class="{'gen-pat-inputs': true, 'd-none': minimized}">
    <v-col cols="2" class="py-0 d-flex align-center justify-end">a</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.a.min" :min="patternParamsConfig.a.min" :max="patternParamsConfig.a.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.a.max" :min="patternParamsConfig.a.min" :max="patternParamsConfig.a.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>

    <v-col cols="2" class="py-0 d-flex align-center justify-end">b</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.b.min" :min="patternParamsConfig.b.min" :max="patternParamsConfig.b.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.b.max" :min="patternParamsConfig.b.min" :max="patternParamsConfig.b.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>

    <v-col cols="2" class="py-0 d-flex align-center justify-end">c</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.c.min" :min="patternParamsConfig.c.min" :max="patternParamsConfig.c.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.c.max" :min="patternParamsConfig.c.min" :max="patternParamsConfig.c.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>
  </v-row>
</div>

<div class="mt-6" v-else-if="modelType == 'armonicWave'">
  <v-row>
    <v-col cols="12" class="d-flex purple lighten-5">
      <ColorPicker
        v-model="c_color"
        :disabled="true"
        title="Color"
      />
      <pre class="flex-grow-1">A(t) = A<sub>max</sub>e<sup>-τt</sup> sin(2πωt) + c</pre>
      <v-btn
        icon
        @click="$emit('swichMinimize')"
      >
        <v-icon v-if="minimized">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-window-minimize</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>

  </v-row>
  <v-row :class="{'gen-pat-inputs': true, 'd-none': minimized}">
    <v-col cols="2" class="py-0 d-flex align-center justify-end">A<sub>max</sub></v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.amplitude.min" :min="patternParamsConfig.amplitude.min" :max="patternParamsConfig.amplitude.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.amplitude.max" :min="patternParamsConfig.amplitude.min" :max="patternParamsConfig.amplitude.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>

    <v-col cols="2" class="py-0 d-flex align-center justify-end">τ</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.tau.min" :min="patternParamsConfig.tau.min" :max="patternParamsConfig.tau.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.tau.max" :min="patternParamsConfig.tau.min" :max="patternParamsConfig.tau.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>

    <v-col cols="2" class="py-0 d-flex align-center justify-end">ω</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.omega.min" :min="patternParamsConfig.omega.min" :max="patternParamsConfig.omega.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.omega.max" :min="patternParamsConfig.omega.min" :max="patternParamsConfig.omega.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>

    <v-col cols="2" class="py-0 d-flex align-center justify-end">c</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.c.min" :min="patternParamsConfig.c.min" :max="patternParamsConfig.c.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.c.max" :min="patternParamsConfig.c.min" :max="patternParamsConfig.c.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>
  </v-row>
</div>

<div class="mt-6" v-else-if="modelType == 'ln'">
  <v-row>
    <v-col cols="12" class="d-flex purple lighten-5">
      <ColorPicker
        v-model="c_color"
        :disabled="true"
        title="Color"
      />
      <pre class="flex-grow-1">A(t) = A<sub>max</sub>ln(at) + c</pre>
      <v-btn
        icon
        @click="$emit('swichMinimize')"
      >
        <v-icon v-if="minimized">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-window-minimize</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>
  </v-row>

  <v-row :class="{'gen-pat-inputs': true, 'd-none': minimized}">
    <v-col cols="2" class="py-0 d-flex align-center justify-end">A<sub>max</sub></v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.amplitude.min" :min="patternParamsConfig.amplitude.min" :max="patternParamsConfig.amplitude.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.amplitude.max" :min="patternParamsConfig.amplitude.min" :max="patternParamsConfig.amplitude.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>

    <v-col cols="2" class="py-0 d-flex align-center justify-end">a</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.a.min" :min="patternParamsConfig.a.min" :max="patternParamsConfig.a.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.a.max" :min="patternParamsConfig.a.min" :max="patternParamsConfig.a.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>

    <v-col cols="2" class="py-0 d-flex align-center justify-end">c</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.c.min" :min="patternParamsConfig.c.min" :max="patternParamsConfig.c.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.c.max" :min="patternParamsConfig.c.min" :max="patternParamsConfig.c.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>
  </v-row>
</div>

<div class="mt-6" v-else-if="modelType == 'log'">
  <v-row>
    <v-col cols="12" class="d-flex purple lighten-5">
      <ColorPicker
        v-model="c_color"
        :disabled="true"
        title="Color"
      />
      <pre class="flex-grow-1">A(t) = A<sub>max</sub>log<sub>b</sub>(at) + c</pre>
      <v-btn
        icon
        @click="$emit('swichMinimize')"
      >
        <v-icon v-if="minimized">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-window-minimize</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>
  </v-row>

  <v-row :class="{'gen-pat-inputs': true, 'd-none': minimized}">
    <v-col cols="2" class="py-0 d-flex align-center justify-end">A<sub>max</sub></v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.amplitude.min" :min="patternParamsConfig.amplitude.min" :max="patternParamsConfig.amplitude.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.amplitude.max" :min="patternParamsConfig.amplitude.min" :max="patternParamsConfig.amplitude.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>

    <v-col cols="2" class="py-0 d-flex align-center justify-end">a</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.a.min" :min="patternParamsConfig.a.min" :max="patternParamsConfig.a.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.a.max" :min="patternParamsConfig.a.min" :max="patternParamsConfig.a.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>

    <v-col cols="2" class="py-0 d-flex align-center justify-end">b</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.b.min" :min="patternParamsConfig.b.min" :max="patternParamsConfig.b.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.b.max" :min="patternParamsConfig.b.min" :max="patternParamsConfig.b.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>

    <v-col cols="2" class="py-0 d-flex align-center justify-end">c</v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="min" v-model="paramLimits.c.min" :min="patternParamsConfig.c.min" :max="patternParamsConfig.c.max" />
    </v-col>
    <v-col cols="5" class="pt-1 pb-0">
      <NumberInput message="max" v-model="paramLimits.c.max" :min="patternParamsConfig.c.min" :max="patternParamsConfig.c.max" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>
  </v-row>
</div>

<div class="mt-6" v-else-if="modelType == 'freeDraw'">
  <v-row>
    <v-col cols="12" class="d-flex purple lighten-5">
      <ColorPicker
        v-model="c_color"
        :disabled="true"
        title="Color"
      />
      <pre class="flex-grow-1">Free draw</pre>
      <v-btn
        icon
        @click="$emit('swichMinimize')"
      >
        <v-icon v-if="minimized">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-window-minimize</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>
  </v-row>

  <v-row :class="{'gen-pat-inputs': true, 'd-none': minimized}">
    <v-col cols="2" class="py-0 d-flex align-center justify-end">Delta</v-col>
    <v-col cols="8" class="pt-5 pb-0">
      <NumberSlider v-model="paramLimits.delta" :step="1" :min="1" :max="20" />
    </v-col>
    <v-col cols="2">
      <NumberInput v-model="paramLimits.delta" :min="1" :max="20" :step="1" />
    </v-col>
    <v-col cols="12" class="pt-1 pb-0"><v-divider></v-divider></v-col>
  </v-row>
</div>
</template>

<script>
import { clone } from '../../../util/util';

import ColorPicker from '../../base/ColorPicker.vue';
import NumberInput from '../../base/NumberInput.vue';
import NumberSlider from '../../base/NumberSlider.vue';

export default {
  name: 'GenConfig',

  components: {
    NumberInput,
    NumberSlider,
    ColorPicker,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
    modelType: {
      type: String,
      requried: true,
    },
    minimized: {
      type: Boolean,
      required: true,
    },
    patternParamsConfig: {
      requried: true,
    },
    color: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      changing: false,
      c_color: this.color,
      paramLimits: clone(this.value),
      test: 10,
    };
  },

  watch: {
    value: {
      deep: true,
      handler(n) {
        this.changing = true;
        this.paramLimits = clone(this.value);
      }
    },

    color: {
      handler() {
        if (!this.changing) {
          this.c_color = this.color;
        }
        this.changing = false;
      }
    },

    c_color: {
      handler() {
        this.changing = true;
        this.$emit('changeColor', this.c_color);
      },
    },

    paramLimits: {
      deep: true,
      handler(n) {
        if (!this.changing) {
          this.$emit('input', n);
        }
        this.changing = false;
      },
    },
  },
};

</script>

<style scoped>
pre {
  line-height: 32px;
  margin-left: 20px;
}
</style>
