<template>
  <v-card
    class="mx-auto mb-5"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">{{ app.dev }}</div>
        <v-list-item-title class="text-h5 mb-1">{{ app.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ app.description  }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="80"
        color="grey"
        class="rounded-xl"
      >
      <v-img
        lazy-src="https://picsum.photos/id/11/10/6"
        max-height="80"
        max-width="80"
        :src="app.img"
      ></v-img>
    </v-list-item-avatar>

    </v-list-item>
    <v-card-text class="py-1">
      <span class="font-weight-bold">How happy are you using this app?</span>
    </v-card-text>
    <v-card-actions>
      <AppEvalBtn
        :active="app.mark < 0"
        @click="onRate(-1)"
      >mdi-emoticon-sad-outline</AppEvalBtn>
      <AppEvalBtn
        :active="app.mark === 0"
        @click="onRate(0)"
      >mdi-emoticon-neutral-outline</AppEvalBtn>
      <AppEvalBtn
        :active="app.mark > 0"
        @click="onRate(1)"
      >mdi-emoticon-happy-outline</AppEvalBtn>
    </v-card-actions>
  </v-card>
</template>


<script>
import AppEvalBtn from './AppEvalBtn';

export default {
  name: 'AppCard',

  components: {
    AppEvalBtn,
  },

  props: {
    app: {
      type: Object,
      required: true,
    }
  },

  methods: {
    onRate(mark) {
      this.$emit('rate', mark);
    }
  },
};
</script>
