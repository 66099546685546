<template>
<div>
  <v-text-field
    v-model="email"
    label="Email"
    hide-details="auto"
    :loading="loading"
    @keypress="checkEnter"
  ></v-text-field>
  <div class="bbox">
    <v-btn
      block
      color="success"
      :disabled="isDisabled()"
      @click="recover"
    >Recover</v-btn>
  </div>
  <!-- <div class="d-flex flex-column bbox">
    <router-link to="/register" class="small">Register new account</router-link>
    <router-link to="/" class="small">Already have an account?</router-link>
  </div> -->
</div>
</template>


<script>

export default {
  name: 'RecoverForm',

  props: {
    loading: Boolean,
  },

  data() {
    return {
      recovered: false,
      email: '',
    };
  },

  methods: {
    isDisabled() {
      return !this.email.trim();
    },

    recover() {
      if (!this.isDisabled()) {
        if (this.password == this.password2) {
          this.$emit('recover', this.email);
        } else {
          alert('Passwords should be the same');
        }
      }
    },

    checkEnter(event) {
      if (event.code == 'Enter') {
        this.recover();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bbox {
  padding-top: 1.5rem;
}

.bbox a {
  text-decoration: none;
}
</style>
