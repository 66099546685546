<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="success"
        dark
        v-bind="attrs"
        v-on="on"
        title="Add a model to the pattern"
      >
        Add component
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, i) in patterns"
        :key="i"
        link
      >
        <v-list-item-title @click="addSubsignal(item.value)">{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'AddSubsignalButton',
  props: {
    patterns: {
      type: Array,
      required: true
    }
  },
  methods: {
    addSubsignal(type) {
      this.$emit('onaddsubsignal', type);
    }
  }
}
</script>
