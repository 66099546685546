<template>
  <v-dialog
    :value="true"
    persistent
    max-width="480"
  >
    <v-card>
      <v-card-title class="white--text t-background darken-4">Edit collection</v-card-title>


      <v-card-text class="pt-4 pb-0">

        <ErrorAlert v-model="error" />

        <v-row no-gutters>
          <v-col cols="5" class="d-flex align-center">
            <v-text-field
              v-model="collection.title"
              label="Title"
              hide-details="auto"
              :loading="loading"
              @keypress="checkEnter"
            ></v-text-field>
          </v-col>
          <v-col cols="2"></v-col>
          <v-col cols="5" class="d-flex align-center">
            <AccessCodeInput
              v-model="collectionCode"
              :loading="loading"
              @error="showError"
            />
          </v-col>
          <v-col cols="12" class="d-flex align-center">
            <span>Enabled signals could be imported by other users using access code</span>
            <v-switch
              v-model="collection.exportable"
              class="align-baseline"
              :loading="loading"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>


      <v-card-text>
        <div v-if="signalStates === null" class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <CollectionSignals
          v-else-if="signals.length"
          v-model="signalStates"
        />
        <div v-else class="no-signal-box text-center">
          <span>There are no patterns yet!</span>
        </div>
      </v-card-text>

      <v-card-actions dark>
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          class="white--text"
          :disabled="loading || !collection.title.trim()"
          @click="saveCollection"
        >Save</v-btn>
        <v-btn
          color="red"
          class="white--text"
          @click="cancelCollection"
        >Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorAlert from '../ErrorAlert';
import AccessCodeInput from './AccessCodeInput';
import CollectionSignals from './CollectionSignals';
import api from '../../util/api';

export default {
  name: 'EditCollectionForm',

  props: {
    value: {
      type: Object,
      required: true,
    },
    signals: {
      type: Array,
      required: true,
    }
  },

  components: {
    ErrorAlert,
    AccessCodeInput,
    CollectionSignals,
  },

  data() {
    return {
      error: false,
      loading: false,
      collection: { ...this.value },
      signalStates: null,
      allowImport: false,
      collectionCode: this.value.code,
    };
  },

  methods: {
    checkEnter(event) {
      if (event.code == 'Enter') {
        this.addCollection();
      }
    },

    saveCollection() {
      if (this.collection.title.trim()) {
        this.loading = true;
        this.showError(false);

        const signals = {};
        this.signalStates.forEach(s => {
          signals[s.hash] = s.enabled;
        });
        const collectionData = {
          title: this.collection.title,
          exportable: this.collection.exportable,
          code: this.collectionCode,
          signals,
        };
        api.updateCollection(this.collection.code, collectionData)
        .then(({ collection }) => {
          this.$emit('input', collection);
          this.loading = false;
        })
        .catch(err => {
          this.showError(err);
          this.loading = false;
        });
      } else {
        this.showError( new Error('Please add a title!') );
      }
    },

    cancelCollection() {
      this.$emit('cancel');
    },

    showError(err) {
      this.error = err;
    },
  },

  mounted() {
    api
    .getCollectionSignals(this.collection.code)
    .then(({ signals }) => {
      const enabled = new Set();
      signals.forEach(s => enabled.add(s.hash));
      this.signalStates = this.signals.map(s => {
        return {
          title: s.title,
          hash: s.hash,
          enabled: enabled.has(s.hash),
        };
      });
    })
    .catch(err => {
      this.showError(err);
    })
    ;
  },
};
</script>

<style>

/* @TODO: .t-background should be a global class */
.t-background {
  background-color: #3c4b64;
}

</style>
