import DEFAULT_SIGNALS from './default-signal';

const VIREO_GUEST_KEY = 'vireo_guest';
const VIREO_SIGNAL = 'vireo_signals';
const MAX_LENGTH_SIG = 6;

export async function getGuestUser() {
  return !!localStorage.getItem(VIREO_GUEST_KEY);
}

export async function loginAsGuest() {
  localStorage.setItem(VIREO_GUEST_KEY, 1);
  return true;
}

export async function guestLogout() {
  localStorage.removeItem(VIREO_GUEST_KEY);
  return true;
}

export async function guestSaveSignal(signal) {
  const signals = await guestGetSignals();
  let index = -1;
  if (signal.hash) {
    for (let i = 0; i < signals.length; i++) {
      if (signals[i].hash  == signal.hash) {
        index = i;
        break;
      }
    }
    if (index == -1) {
      throw new Error(`Signal not found`);
    }
  } else if (signals.length >= MAX_LENGTH_SIG) {
    throw new Error(`Guest cannot save more than ${MAX_LENGTH_SIG} patterns`);
  }
  if (index == -1) {
    signal.hash = `pattern-${signals.length + 1}`;
    signals.push(signal);
  } else {
    signals[index] = signal;
  }
  await saveSignals(signals);
  return { signal };
}

export async function guestDeleteSignal(hash) {
  const data = (await guestGetSignals())
  .filter((signal) => signal.hash !== hash)
  ;
  saveSignals(data);
  return true;
}

export async function guestGetSignalsShort() {
  const signals = (await guestGetSignals())
  .map(({title, hash}) => {
    return {
      hash, title,
    };
  });
  return { signals };
}

export async function guestGetSignal(hash) {
  const signals = await guestGetSignals();
  for (let i = 0; i < signals.length; i++) {
    if (signals[i].hash == hash) {
      return {
        signal: signals[i]
      };
    }
  }
  throw new Error(`Signal not found`);
}

/* -------------------------------------------------------------------------- */

async function saveSignals(data) {
  localStorage.setItem(VIREO_SIGNAL, JSON.stringify(data));
  return true;
}

async function guestGetSignals() {
  const data = localStorage.getItem(VIREO_SIGNAL);
  if (!data) {
    saveSignals(DEFAULT_SIGNALS);
    return DEFAULT_SIGNALS;
  }
  try {
    return JSON.parse(data);
  } catch {
    return [];
  }
}

