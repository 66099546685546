<template>
<div class="audio-track">
  <v-file-input
    label="Load audio track"
    filled
    :prepend-icon="audio ? 'mdi-folder-refresh-outline' : 'mdi-volume-high'"
    :loading="loading"
    :disabled="loading"
    v-model="inputfilevalue"
    @change="loadingAudio"
  ></v-file-input>
  <div :class="{wave: true, 'd-none': !audio}">
    <v-btn icon @click="togglePlaying">
      <v-icon>{{ playingAudio ? 'mdi-pause' : 'mdi-play'}}</v-icon>
    </v-btn>
    <div class="waveform" ref="waveform"></div>
    <v-btn icon @click="removeAudio">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div class="region-control" :style="regionControlStyle()" ref="regionControl">
      <span>{{ regionData.start.toFixed(3) }}s</span>
      <v-btn icon @click="togglePlayingRegion">
        <v-icon>{{ playingAudioRegion ? 'mdi-pause' : 'mdi-play'}}</v-icon>
      </v-btn>
      <span>{{ regionData.end.toFixed(3) }}s</span>
    </div>
  </div>
</div>
</template>


<script>
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/src/plugin/regions/index.js';

import {
  emptyAudioTrack,
} from '../../util/util';

import {
  readFile,
  validateAudioTrack,
} from '../../util/filereader';

import {
  SURF_REG_ID,
  AUDIO_MIN_LEN,
  AUDIO_MAX_LEN,
  COLOR_SELECTED_REGION,
  COLOR_WAVE,
  COLOR_PROGRESS,
} from '../../util/config';

function pxNum(dim) {
  if (typeof dim != 'string') {
    return 0;
  }
  dim = dim.match(/(-?\d+)/i);
  return dim ? Number(dim[0]) : 0;
}

export default {
  name: 'AudioTrack',

  props: {
    value: {
      type: Object,
      required: true
    },
  },

  data() {
    const defaults = {
      loading: false,
      audio: '',
      playingAudio: false,
      playingAudioRegion: false,
      inputfilevalue: null,
      regionData: {
        id: SURF_REG_ID,
        start: 0 ,
        end: AUDIO_MAX_LEN,
        minLength: AUDIO_MIN_LEN,
        maxLength: AUDIO_MAX_LEN,
        color: COLOR_SELECTED_REGION,
      },
    };
    this.valueToData(defaults);
    return defaults;
  },

  watch: {
    value() {
      this.valueToData(this);
    },

    audio() {
      this.drawWave();
    },

    playingAudio(n) {
      if (this.wavesurfer && this.audio) {
        if (n) {
          this.wavesurfer.play();
        } else {
          this.wavesurfer.pause();
        }
      }
    },

    playingAudioRegion(n) {
      if (this.wavesurfer && this.audio) {
        if (n) {
          this.region().play();
        } else {
          this.wavesurfer.pause();
        }
      }
    },

    duration(d) {
      this.regionData.maxLength = d;
    },

    regionData: {
      deep: true,
      handler(n) {
        if (!this._regionDataAvoidUpdate && this.wavesurfer && this.wavesurfer.regions) {
          this.region().update(n);
        }
      }
    },
  },

  methods: {
    regionControlStyle() {
      let left = 0;
      if (this.audio && this.region()) {
        const reg = this.region().element;
        const playWholeAudioBtnWidth = 36;
        const regionControlWidth = 160;
        // @TODO: if I don't + and - something from regionData, it doesn't work corectly
        // used it in next expression (end);
        // maybe it is possible to fix it somehow ???
        left = pxNum(reg.style.left) + playWholeAudioBtnWidth + (pxNum(reg.style.width) - regionControlWidth) / 2 + this.regionData.end -  this.regionData.end;
      }
      return {
        left: left + 'px'
      };
    },

    setRegionControlStyle() {
      const { left } = this.regionControlStyle();
      this.$refs.regionControl.style.left = left;
    },

    region() {
      if (this.wavesurfer) {
        return this.wavesurfer.regions.list[SURF_REG_ID];
      }
      return null;
    },

    valueToData(data) {
      const { value } = this;
      data.audio = value.content || '';
      data.regionData.start = value.region.start;
      data.regionData.end = value.region.end;
    },

    emitInput() {
      let data = null;
      if (this.audio) {
        const { audio, regionData, wavesurfer } = this;
        data = {
          content: audio,
          duration: wavesurfer.getDuration(),
          region: {
            start: regionData.start,
            end: regionData.end,
          },
        };
      }
      this.$emit('input', data);
    },

    loadingAudio(file) {
      const self = this;
      self.loading = true;
      (file ? readFile(file) : Promise.resolve(''))
      .then((data) => {
        const [_, errmsg] = validateAudioTrack(data);
        if (errmsg) {
          this.$emit('error', new Error(errmsg));
        } else {
          this.audio = data.result;
        }
        self.loading = false;
      })
      .catch(err => {
        this.$emit('error', err);
        this.loading = false;
      });
    },

    drawWave() {
      if (this.wavesurfer && this.audio) {
        this.wavesurfer.load(this.audio);
      }
    },

    togglePlaying() {
      this.playingAudioRegion = false;
      this.playingAudio = !this.playingAudio;
    },

    togglePlayingRegion() {
      this.playingAudio = false;
      this.playingAudioRegion = !this.playingAudioRegion;
    },

    removeAudio() {
      this.inputfilevalue = null;
      this.$emit('input', emptyAudioTrack());
    },
  },

  mounted() {
    const self = this;
    self.wavesurfer = WaveSurfer.create({
      container: self.$refs.waveform,
      waveColor: COLOR_WAVE,
      progressColor: COLOR_PROGRESS,
      responsive: true,
      plugins: [
        RegionsPlugin.create({
          regionsMinLength: AUDIO_MIN_LEN,
          regions: [ this.regionData ]
        })
      ],
    });
    this.wavesurfer.on('ready', () => {
      // set region control position when the audio is loaded
      this.setRegionControlStyle();

      const audioDuration = this.wavesurfer.getDuration();
      const regionDuration = this.regionData.end - this.regionData.start;

      this.regionData.maxLength = audioDuration;
      if (this.regionData.maxLength > audioDuration) {
        this.regionData.maxLength = audioDuration;
      }

      // Adapt region position and dimension acording to new audio params
      if (regionDuration < AUDIO_MIN_LEN) {
        this.regionData.start = 0;
        this.regionData.end = audioDuration;
      } else {
        if (regionDuration > audioDuration) {
          this.regionData.end = this.regionData.start + audioDuration;
        }
        if (this.regionData.end > audioDuration || this.regionData.start > audioDuration) {
          this.regionData.start -= this.regionData.end - audioDuration;
          this.regionData.end = audioDuration;
        }
      }

      this.emitInput();
    });
    this.wavesurfer.on('finish', () => {
      this.playingAudio = false;
      this.playingAudioRegion = false;
    });
    this.region().on('out', (event) => {
      if (this.playingAudioRegion) {
        // It seems that out, isn't executed exactly when the mark reached the
        // the region, because of it we change buttons states with a delay
        // 132ms is more than enougth
        setTimeout(() => {
          this.playingAudio = false;
          this.playingAudioRegion = false;
        }, 132);
      }
    });
    this.region().on('update-end', (event) => {
      const region = this.region();
      const data = {};
      Object.keys(this.regionData).forEach((key) => {
        data[key] = region[key];
      });
      this._regionDataAvoidUpdate = true;
      this.regionData = data;
      this._regionDataAvoidUpdate = false;

      this.emitInput();
    });
    this.drawWave();
  },
}
</script>


<style>
.audio-track {
  position: relative;
}

.wave {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 28px;
  left: 32px;
  right: 0;
  background-color: #f0f0f0;
  align-items: center;
}

.waveform {
  flex-grow: 1;
  height: 100%;
}

.waveform > wave {
  height: 100% !important;
}

.region-control {
  display:  flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100%;

  /*
  If the width is modified, you have to change it also in code
  component.regionControlStyle method
  */
  width: 160px;

  transition: left 0.2s;
  z-index: 100;
}
</style>