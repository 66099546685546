<template>
  <Dashboard
    :config="config.app"
    :user="user"
    @error="showError"
  >
    <template v-slot:sidebar>
      <v-card>
        <v-tabs
          v-model="sidebar.tab"
          background-color="#3c4b64"
          fixed-tabs
          dark
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#signals">Patterns</v-tab>
          <v-tab href="#collectons" v-if="realUser">Collections</v-tab>
        </v-tabs>
        <v-tabs-items v-model="sidebar.tab" class="sidebar-bg" dark>
          <v-tab-item value="signals" class="sidebar-bg">
            <div class="scrollable">
              <ul class="tmp-menu">
                <li
                  v-for="(signal, i) in signalsList"
                  :key="i"
                  :class="{'sb-active-item': signal.active, 'pa-2': true, 'untitled': !signal.title.trim() }"
                  @click="goTo(signal.hash)"
                >{{ signal.title || "Untitled"}}</li>
              </ul>
            </div>
            <div class="d-flex justify-content-center pb-2 pt-2">
              <div>
                <v-btn
                  icon
                  :disabled="$route.params.hash && $route.params.hash.toLowerCase() == 'new' || loading"
                  color="grey"
                  title="New pattern"
                  @click="goTo('new')"
                ><v-icon>mdi-plus</v-icon></v-btn>
              </div>
              <ImportPatterns
                @onimport="onImportPatterns"
                @error="showError"
              />
              <ExportPatterns
                v-if="signalsList"
                :signals="signalsList"
                @error="showError"
              />
            </div>
          </v-tab-item>
          <v-tab-item value="collectons" class="sidebar-bg" v-if="realUser">
            <div class="scrollable">
              <CollectionsList
                v-model="collectionsList"
                :signals="signalsList"
                @error="showError"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>

    <template v-slot:main>
      <div
        class="d-flex justify-center"
        v-if="loading === undefined"
      >
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <ExportedFileUsage
        v-else-if="$route.params.hash && $route.params.hash.toLowerCase() == 'docs'"
      />
      <MultipleSignalApp
        v-else-if="signal"
        :config="config.signalApp"
        :loading="loading"
        :signalData="signal"
        :userActionsIndex="userActions.index"
        @onUserAction="(c) => { userActions.index = 0; userActions.count = c; }"
        @onSave="onSave"
        @onDelete="onDelete"
        @addSignal="addSignalToList"
        @error="showError"
      />
      <div class="d-flex align-center justify-center" v-else>
        <v-btn
          :disabled="$route.params.hash == 'new' || loading"
          color="success"
          @click="goTo('new')"
        >New pattern</v-btn>
      </div>
      <ErrorNotification v-model="error" />
    </template>
  </Dashboard>
</template>

<script>

import Dashboard from '../components/dashboard/Dashboard.vue';
import ExportedFileUsage from '../components/dashboard/ExportedFileUsage.vue';
import ErrorNotification from '../components/ErrorNotification.vue';
import ImportPatterns from '../components/dashboard/ImportPatterns.vue';
import ExportPatterns from '../components/signal/exports/ExportPatterns.vue';
import MultipleSignalApp from '../components/signal/MultipleSignalApp.vue';
import CollectionsList from '../components/collection/CollectionsList.vue';
import api from '../util/api';

const pagesHash = new Set(['docs']);

export default {
  name: 'DashboardView',

  props: {
    user: {
      type: Object,
      required: true,
    }
  },

  components: {
    Dashboard,
    ExportedFileUsage,
    ErrorNotification,
    ImportPatterns,
    ExportPatterns,
    MultipleSignalApp,
    CollectionsList,
  },

  data() {
    return {
      error: false,
      loading: undefined,
      sidebar: {
        tab: null,
      },
      config: {
        app: {
          title: 'VIREO',
        },
        signalApp: {
          signalStep: {
            min: 0.01,
            max: 0.5,
            step: 0.01,
            defaultValue: 0.1,
          },
          signalDuration: {
            min: 0.005,
            max: 5,
            step: 0.005,
            defaultValue: 1,
          },
          signalTime: {
            min: 0,
            max: 30,
            step: 0.005,
            defaultValue: 1,
          },
          stepsView: {
            min: 0.005,
            max: 1,
            step: 0.005,
            defaultValue: 0.1,
          },
          audioTrack: {
            min: 0,
            max: 5,
            step: 0.005,
            defaultValue: 0,
          },
          displayYLimits: [0, 100],
          stepMinDuration: 0.1,
        }
      },
      signalsList:[],
      collectionsList: [],
      signal: undefined,
      userActions: {
        index: 0,
        count: 1,
      },
    };
  },

  watch: {
    '$route'() {
      this.loadLocationSignal();
      this.updateActiveSignal();
    },

    signal() {
      this.updateActiveSignal();
    },
  },

  computed: {
    realUser() {
      return this.user.id > 0;
    },
  },

  methods: {
    goTo(hash) {
      if (this.$route.params.hash != hash) {
        this.$router.push(hash);
      }
    },

    updateTitle(hash, signalTitle) {
      this.signalsList.forEach((signal) => {
        if (signal.hash == hash) {
          signal.title = signalTitle;
        }
      });
    },

    filterSignals(hash) {
      this.signalsList = this.signalsList.filter(signal => signal.hash != hash);
    },

    updateActiveSignal() {
      const hash = this.$route.params.hash;
      this.signalsList.forEach(signal => {
        signal.active = signal.hash == hash;
      });
    },

    newSignal() {
      this.$router.push('new');
    },

    loadLocationSignal() {
      let hash = this.$route.params.hash;
      if (hash && !pagesHash.has(hash)) {
        if (this.signal) {
          this.loading = true;
        } else {
          this.loading = undefined;
        }
        api.getSignal(hash)
        .then((response) => {
          this.signal = response.signal;
        })
        .catch(err => {
          this.showError(err);
        })
        .then(() => {
          this.loading = false;
        })
        ;
      } else {
        this.signal = false;
        this.loading = false;
      }
    },

    addSignalToList(signal) {
      this.signalsList = [ ...this.signalsList, {
        title: signal.title,
        hash: signal.hash,
        active: false,
      }];
    },

    onSave(signal) {
      const self = this;
      const hash = signal.hash;
      self.loading = true;
      api.saveSignal(signal)
      .then(({ signal }) => {
        if (hash) {
          self.updateTitle(hash, signal.title);
        } else {
          this.addSignalToList(signal)
          self.goTo(signal.hash);
        }
      })
      .catch(err => {
        this.showError(err);
      })
      .then(() => {
        self.loading = false;
      })
      ;
    },

    onDelete(hash) {
      if (!confirm(`Are you sure you want to delete this signal`)) {
        return;
      }
      const self = this;
      self.loading = true;
      api.deleteSignal(hash)
      .then(() => {
        self.filterSignals(hash);
        self.goTo('/');
      })
      .catch(err => {
        this.showError(err);
      })
      .then(() => {
        self.loading = false;
      })
      ;
    },

    onImportPatterns(signals) {
      this.signalsList = [ ...this.signalsList, ...signals ];
    },

    showError(err) {
      this.error = err;
    },
  },

  mounted() {
    api.getSignalsShort()
    .then((response) => {
      this.signalsList = response.signals;
    })
    .catch(err => {
      this.showerror(err);
    })
    ;

    this.loadLocationSignal();
    this.updateActiveSignal();

    api.getCollections()
    .then(({ collections }) => {
      this.collectionsList = collections;
    })
    .catch(err => {
      this.showError(err);
    })
    ;
  },
}
</script>

<style lang="scss" scoped>

.tmp-menu {
  margin: 0;
  padding: 0;

  li {
    cursor: pointer;
    transition: background-color 0.25s;

    &:hover {
      background-color: #46546c;
    }
  }
}
.sidebar-bg {
  background-color: #3c4b64;
  color: #ffffff;
}

.sb-active-item {
  background-color: #46546c;
}

.scrollable {
  max-height: calc(100vh - 160px);
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  /* @TODO: beautiful scroll */
  overflow-y: auto;
}

.untitled {
  color: #a5a5a5;
}
</style>
