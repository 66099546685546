<template>
  <div>
    <v-text-field
      :value="c_value"
      label="Access code"
      hide-details="auto"
      readonly
      :loading="loading"
      class="text-upper"
    >
      <template v-slot:append v-if="canCopy()">
        <v-btn
          icon
          :disabled="loading"
          @click="copyAccessCode"
        >
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>

        <v-btn
          icon
          :disabled="loading"
          @click="refreshAccssCode"
        >
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <v-snackbar
      v-model="snake.visible"
      :timeout="snake.timeout"
    >
      <span>Copied</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snake.visible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import api from '../../util/api';

export default {
  name: 'AccessCodeInput',

  props: {
    value: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      c_value: this.value,
      c_loading: this.loading,
      snake: {
        timeout: 600,
        visible: false,
      }
    };
  },

  methods: {

    canCopy() {
      return navigator.clipboard;
    },

    copyAccessCode() {
      if (this.canCopy()) {
        navigator.clipboard
        .writeText(this.value.toLowerCase())
        .then((data) => {
          this.snake.visible = true;
        })
        .catch(err => {
          this.$emit('error', err);
        })
        ;
      }
    },

    refreshAccssCode() {
      api.gerFreshAccessCode()
      .then(({ code }) => {
        this.c_value = code;
        this.$emit('input', code);
        this.c_loading = false;
      })
      .catch(err => {
        this.$emit('error', err);
        this.c_loading = false;
      });
    },
  },
}
</script>
